import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  FormControl, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, DialogTitle,
  LinearProgress, Button, Container, Paper, List, ListItemText, ListItem, CardMedia, Divider,
  Card, CardContent, CircularProgress, Typography, Grid, Slide, Link, FormControlLabel, TextField,
  SvgIcon, Icon, MenuItem, Select, InputLabel, Toolbar, AppBar, Input, ListSubheader, Tooltip,
  Checkbox, Switch, Box, Radio, RadioGroup, Menu, Snackbar,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core/';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Theme, createStyles, makeStyles, withStyles, fade } from '@material-ui/core/styles';

import GlobalStyles from '../../../../styles/GlobalStyles.web';

import { PaginatedResponse, ToastType } from '../../../shared/models';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import uniq from 'lodash/uniq';


import { getTempLinkDetail, validateTempLinks, removeBussProdLinks, deleteTempRecs, approveTempRecords, approveTemporaryLinks } from '../../api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError, ToastInfo } from '../../../shared/components/Notifier';
import _isEqual from 'lodash/isEqual';
import { MasterSupplierDataImportStatusCode } from '../../models';
import _filter from 'lodash/filter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, API_RESPONSE, NO_PERMISSIONS_ERROR_MSG, ERROR_404, ERROR_409 } from '../../../shared/lib/Localization';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import differenceBy from 'lodash/differenceBy';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { LinkProduct } from '../../components';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';



export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    card: {
      padding: theme.spacing(1),
    },
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
    button: {
      margin: theme.spacing(1),
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    textFieldCardRow: {
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      alignSelf: 'center'
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 16px'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '12px'

    },
    panelHeaderText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
    },
    panelRowText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
    },
    rowActionBtn: {
      padding: 8
    },
    noDataRow: {
      width: '100%',
      padding: 12
    },
    statusText: {
      fontSize: 16,
      textAlign: 'left',
      marginLeft: 10,
      alignSelf: 'center',
      padding: "6px 0",
      display: 'flex',
    },
    brandRow: {
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    formControl: {
      // margin: theme.spacing(1),
      // minWidth: 120,
      width: '100%',
      // maxWidth: '16rem'
    },
  }),
);


const linkedTempRecs = 'linkedTempRecs';



const ApproveTempLinkDetailScreen = (props: any) => {
  const classes = useStyles({});
  const [pageMode, setPageMode] = useState({ type: 'view_mode' });

  const [isLoading, setLoading] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [tempProdData, setTempProdData] = useState(null)
  const [invoiceItemDescription, setInvoiceItemDescription] = useState('')
  const [originaltempProdData, setOrginalTempProdData] = useState(null)



  const [renderErrorMsg, setRenderErrorMsg] = useState(false)
  const [expanded, setExpanded] = useState({ linkedTempRecs: true } as any);
  const handleChange = (panel: 'linkedTempRecs') => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    const xpanel = { ...expanded };
    xpanel[panel] = isExpanded;
    setExpanded(xpanel);
  }
  const [mpValidationSuccess, setMpValidationSuccess] = useState(false)
  const [masterBrands, setMasterBrands] = useState([])
  const [masterCompanies, setMasterCompanies] = useState([])

  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [newMasterProdName, setNewMasterProdName] = useState(null)
  const [newCaseQuantity, setNewCaseQuantity] = useState(null)
  const [newMasterUOM, setNewMasterUOM] = useState(null)
  const [newMasterUOMQty, setNewMasterUOMQty] = useState(null)
  const [newReorderCode, setNewReorderCode] = useState(null)
  const [newRetailBarcode, setNewRetailBarcode] = useState(null)
  const [disableImport, setDisableImport] = useState(true);
  const [disableValidate, setDisableValidate] = useState(true);
  const [firstValidationSuccess, setFirstValidationSuccess] = useState(false)
  const [deleteLinkDialogOpen, setDeleteLinkDialogOpen] = useState(false)
  const [deleteTempRecsDialogOpen, setDeleteTempRecsDialogOpen] = useState(false)
  const [approveLinkDialogOpen, setApproveLinkDialogOpen] = useState(false)

  const [importSuccess, setImportSuccess] = useState(false)
  const [resetDialogOpen, setResetDialogOpen] = useState(false);


  const [masterData, setMasterData] = useState(null)


  useEffect(() => {
    console.log("MasterData", masterData)
  }, [masterData])

  useEffect(() => {
    console.log("params", props)
  }, [props])

  useEffect(() => {
    console.log("mpValidationSuccess", mpValidationSuccess)
  }, [mpValidationSuccess])

  useEffect(() => {
    if (!isModalLoading) {
      const parts = window.location.href.split("/")
      let id = parts[parts.length - 1]
      id = decodeURIComponent(id)
      let payload = {

        description: id
      }
      getDetailsForTempLinks(payload)
    }
  }, [isModalLoading]);



  useEffect(() => {
    if (mpValidationSuccess === true) {
      let callGetAttribute = "";
      if (masterData) {
        if (masterData['status_code'] != MasterSupplierDataImportStatusCode.READY_TO_IMPORT) {
          callGetAttribute = "true";
        }
      }
      if (callGetAttribute === "true") {
        getAttributes();
      }
      getQtyAndName(masterData);


    }
  }, [mpValidationSuccess]);



  //  useEffect(()=>{
  //   if(importSuccess){
  //   }
  //  },[importSuccess])


  useEffect(() => {
    console.log("TempLinks", tempProdData)
  }, [tempProdData])


  const getDetailsForTempLinks = (payload: any) => {
    setLoading(true);
    getTempLinkDetail(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(data => {
        setLoading(false);
        initializeData(data)
      })
      .catch((error: any) => {
        setLoading(false);
        props.showToast({
          message: 'Failed to fetch Details',
          options: ToastError
        });
      });
  }


  const initializeData = (data: any) => {
    if (data.master_brands) {
      setMasterBrands(data.master_brands)
    }
    if (data.master_companies) {
      setMasterCompanies(data.master_companies)
    }
    setInvoiceItemDescription(data.description)
    let updatedTempRecs = cloneDeep(data.temp_recs)
    updatedTempRecs = updatedTempRecs.filter((i: any) => {
      i['is_open'] = false
      i['is_deleted'] = false
      return i
    })
    setTempProdData(cloneDeep(updatedTempRecs))
    setOrginalTempProdData(cloneDeep(data.temp_recs))
  }



  const getAttributes = () => {

    let validatedRecord = cloneDeep(masterData)
    let linkBusinessExists = false;

    let exisitingMpexists = validatedRecord['existing_mp'] ? true : false;

    if (exisitingMpexists) {
      let existing_mp = validatedRecord['existing_mp']
      linkBusinessExists = existing_mp.business_products.values(0) ? true : false;
    }
    if (linkBusinessExists) {
      validatedRecord['status_code'] === MasterSupplierDataImportStatusCode.EXISTING_LINK_RECORD_ERROR;
    }
    if (!linkBusinessExists) {
      validatedRecord['status_code'] === MasterSupplierDataImportStatusCode.READY_TO_IMPORT;
    }

    setMasterData(validatedRecord)
  };

  const disableImportButton = (updatedRecord: any) => {

    let anyValidRecord = updatedRecord['status_code'] === MasterSupplierDataImportStatusCode.READY_TO_IMPORT ? true : false
    console.log("VALIDRECORD", anyValidRecord)
    if (!anyValidRecord) {
      setDisableValidate(false);
      setDisableImport(true);
    } else {
      setDisableValidate(true);
      setDisableImport(false);
    }
  };

  const getQtyAndName = (validatedRecord: any) => {

    let validatedRec = cloneDeep(validatedRecord)

    const exists = validatedRec['existing_mp'] ? true : false;

    if (exists) {
      let existing_mp = validatedRec['existing_mp']
      let updatedRecord = validatedRec
      // updatedRecord["unitqty_valid"] = true;
      if (existing_mp.attributes && existing_mp.attributes.length > 0) {
        let unit_qty_found = existing_mp.attributes.find(a => a.attribute.name === 'Quantity')
        let unit_name_found = existing_mp.attributes.find(a => a.attribute.name === 'Unit of Measure')
        if (unit_qty_found !== null) {
          updatedRecord["unitqty_mapped"] = unit_qty_found['value'];
        }
        if (unit_name_found !== null) {
          updatedRecord["unitname_mapped"] = unit_name_found['value'];
        }
        if (existing_mp.brand && existing_mp.brand.name !== null) {
          updatedRecord["mpbrand_mapped"] = existing_mp.brand.name;
        }
        validatedRec = updatedRecord
      }
    };
    setMasterData(validatedRec)
    disableImportButton(validatedRec);
  }




  const handleBrandSelectionChange = (event: React.ChangeEvent<{ value: string; }>, value: any) => {
    console.log("BRAND-VALUE", value)
    setSelectedBrand(value);
  };


  const handleCompanySelectionChange = (event: React.ChangeEvent<{ value: string; }>, value: any) => {
    // console.log("company",value)
    setSelectedCompany(value);
  };

  const showBrand = () => {
    return (
      <Grid container className={classes.brandRow}>
        <FormControl className={classes.formControl} style={{ marginRight: 8 }} >
          <Autocomplete
            id='brand-list'
            options={masterBrands}
            getOptionLabel={(s) => s.name != null ? s.name : ''}
            size={'small'}
            getOptionSelected={(option: any, value: string | any) => {
              if (typeof value !== 'string') {
                return value && option ? option.name === value.name : false;
              }
              return false;
            }}
            value={selectedBrand ? selectedBrand : ''}
            onChange={handleBrandSelectionChange}
            renderInput={(params) => (
              <TextField {...params} label="Brand" variant="outlined" margin="normal" />
            )}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.name, inputValue);
              const parts = parse(option.name, matches);
              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
            disabled={!isEditMode()}
          />
        </FormControl>
      </Grid>
    )
  }


  const showMasterCompany = () => {
    return (
      <Grid container className={classes.brandRow}>
        <FormControl className={classes.formControl} >
          <Autocomplete
            id='company-list'
            options={masterCompanies}
            getOptionLabel={(s) => s.name != null ? s.name : ''}
            size={'small'}
            getOptionSelected={(option: any, value: string | any) => {
              if (typeof value !== 'string') {
                return value && option ? option.name === value.name : false;
              }
              return false;
            }}
            value={selectedCompany ? selectedCompany : ''}
            onChange={handleCompanySelectionChange}
            renderInput={(params) => (
              <TextField {...params} label="Manufacturing Company" variant="outlined" margin="normal" />
            )}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.name, inputValue);
              const parts = parse(option.name, matches);
              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
            disabled={!isEditMode()}
          />
        </FormControl>
      </Grid>
    )
  }




  const isDataEdited = () => {
    return !_isEqual(originaltempProdData, tempProdData);
  };

  const isEditMode = () => {
    return pageMode && pageMode.type === 'edit_mode';
  };

  const togglePageMode = () => {
    if (pageMode.type === 'view_mode') {
      setPageMode({ type: 'edit_mode' });
    } else {
      setPageMode({ type: 'view_mode' });
    }
  };

  const handleReset = () => {
    setResetDialogOpen(true);

  }


  const handleResetDialogClose = () => {
    setResetDialogOpen(false);
  };


  const resetDialog = () => {
    return (
      <div>
        <Dialog
          open={resetDialogOpen}
          onClose={handleResetDialogClose}
        >
          <DialogTitle>{"Reset"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to reset?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResetDialogClose} color="primary" >
              No
            </Button>
            <Button onClick={confirmReset} style={{ color: GlobalStyles.red }} autoFocus >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }



  const confirmReset = () => {
    setMpValidationSuccess(false)
    setSelectedBrand(null)
    setSelectedCompany(null)
    setNewMasterProdName(null)
    setNewCaseQuantity(null)
    setNewMasterUOM(null)
    setNewMasterUOMQty(null)
    setNewReorderCode(null)
    setNewRetailBarcode(null)
    setDisableImport(true)
    setDisableValidate(true)
    setFirstValidationSuccess(false)
    setImportSuccess(false)
    let payload = { description: invoiceItemDescription }
    getDetailsForTempLinks(payload)
    setResetDialogOpen(false);
    setMasterData(null)
  }





  const handleMinValidate = () => {

    let mp_prod = cloneDeep(masterData)
    let mp_data = getDataToBeValidated(mp_prod)

    if (mp_data != null) {

      let emptyRecs = mp_data['is_empty']
      let unitqtyValidRecs = mp_data['unitqty_valid']
      let unitNameValidRecs = mp_data['unitname_valid']
      let attrQtyValidRecs = mp_data['attr_qty_valid']

      console.log("emptyRecs", emptyRecs)

      if ((emptyRecs || !unitqtyValidRecs || !unitNameValidRecs || !attrQtyValidRecs)) {
        setDisableValidate(true)
      }
      else {
        setDisableValidate(false)
      }
      props.showToast({
        message: 'Validated Successfully!',
        options: ToastSuccess
      });

      setFirstValidationSuccess(true)

    }

  }


  const renderPageActions = () => {
    return (
      <>
        {
          isEditMode() &&
          isDataEdited() &&
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            // startIcon={<SaveIcon />}
            onClick={() => handleReset()}
          >
            Reset
          </Button>
        }
        {!mpValidationSuccess ?
          isEditMode() &&
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              style={{ marginRight: 0 }}
              onClick={() => handleMinValidate()}
            >Validate</Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              style={{ marginRight: 0 }}
              disabled={disableValidate}
              onClick={() => handleValidate()}
            >
              Validate MP
            </Button>
          </>
          :
          isEditMode() &&
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            style={{ marginRight: 0 }}
            startIcon={<SaveIcon />}
            disabled={disableImport}
            onClick={() => handleSave()}
          >
            Approve
          </Button>
        }
      </>
    )
  }


  const showMetaData = () => {
    console.log("invoiceItemDescriptionMetaData", invoiceItemDescription)
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ alignItems: 'center' }}>
            <Typography>
              Description : {invoiceItemDescription}
            </Typography>
          </div>
          <div style={{ marginLeft: 20 }}>
            <Typography>
              Total Count : {tempProdData.length}
            </Typography>
          </div>
        </div>
      </>
    )
  }

  const renderMasterProductInfo = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: 8 }}>
          <TextField
            className={classes.textFieldCardRow}
            label="Master Product Name"
            margin="dense"
            variant="outlined"
            value={newMasterProdName ? newMasterProdName : ''}
            onChange={(event) => setNewMasterProdName(event.target.value)}
            InputProps={{
              readOnly: !isEditMode(),
              disabled: !isEditMode()
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <TextField
            className={classes.textFieldCardRow}
            label="Reorder Code"
            margin="dense"
            variant="outlined"
            value={newReorderCode ? newReorderCode : ''}
            style={{ width: '50%' }}
            onChange={(event) => setNewReorderCode(event.target.value)}
            InputProps={{
              readOnly: !isEditMode(),
              disabled: !isEditMode()
            }}
          />
          <TextField
            className={classes.textFieldCardRow}
            label="Case Quantity"
            margin="dense"
            variant="outlined"
            style={{ width: '50%' }}
            value={newCaseQuantity ? newCaseQuantity : ''}
            onChange={(event) => setNewCaseQuantity(event.target.value)}
            InputProps={{
              readOnly: !isEditMode(),
              disabled: !isEditMode()
            }}
          />

        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <TextField
            className={classes.textFieldCardRow}
            label="Retail Barcode"
            margin="dense"
            variant="outlined"
            value={newRetailBarcode ? newRetailBarcode : ''}
            style={{ width: '50%' }}
            onChange={(event) => setNewRetailBarcode(event.target.value)}
            InputProps={{
              readOnly: !isEditMode(),
              disabled: !isEditMode()
            }}
          />
        </div>
      </>
    )
  }


  const renderSecondGridItems = () => {
    return (
      <div style={{ marginTop: 16 }}>
        {masterBrands && showBrand()}
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

          <TextField
            className={classes.textFieldCardRow}
            label="Unit Qty"
            margin="dense"
            variant="outlined"
            style={{ width: '50%' }}
            value={newMasterUOMQty ? newMasterUOMQty : ''}
            onChange={(event) => setNewMasterUOMQty(event.target.value)}
            InputProps={{
              readOnly: !isEditMode(),
              disabled: !isEditMode()
            }}
          />
          <TextField
            className={classes.textFieldCardRow}
            label="Unit of Measure"
            margin="dense"
            variant="outlined"
            value={newMasterUOM ? newMasterUOM : ''}
            style={{ width: '50%' }}
            onChange={(event) => setNewMasterUOM(event.target.value)}
            InputProps={{
              readOnly: !isEditMode(),
              disabled: !isEditMode()
            }}
          />
        </div>

      </div>
    )
  }





  const renderStatus = (tempItem: any) => {
    if (firstValidationSuccess && !mpValidationSuccess) {
      const { status_code, status, disabled, warning_msg, is_empty, unitqty_valid, unitname_valid, roc_exists, invoice_item_desc_exists, attr_qty_valid } = tempItem;
      if (status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT && warning_msg === '') {
        if (status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT && warning_msg === '') {
          return <CheckCircleIcon style={{ color: GlobalStyles.green }} />;
        }
        return <CheckCircleIcon style={{ color: GlobalStyles.green }} />;
      }

      else if (
        (status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT && warning_msg != '') ||
        (!invoice_item_desc_exists) ||
        (!roc_exists)
      ) {
        return <ErrorIcon style={{ color: GlobalStyles.warningText }} />;
      }
      else if (status_code && (
        status_code === MasterSupplierDataImportStatusCode.EXISTING_IDENTIFIER_ERROR ||
        status_code === MasterSupplierDataImportStatusCode.EXISTING_LINK_RECORD_ERROR ||
        status_code === MasterSupplierDataImportStatusCode.SUPPLIER_PRODUCT_NOT_FOUND_ERROR ||
        status_code === MasterSupplierDataImportStatusCode.SAME_BRAND_DIFF_COMPANY ||
        status_code === MasterSupplierDataImportStatusCode.EXISTING_INVOICE_ITEM_NAME_ERROR ||
        status_code === MasterSupplierDataImportStatusCode.EXISTING_REORDER_ERROR
      )) {
        return <ErrorIcon style={{ color: GlobalStyles.red }} />;
      }
      else if (disabled === true) {
        if (is_empty === true) {
          if (unitqty_valid === false) {
            if (unitname_valid === false) {
              return <ErrorIcon style={{ color: GlobalStyles.red }} />;
            }
            return <ErrorIcon style={{ color: GlobalStyles.red }} />;
          }
          return <ErrorIcon style={{ color: GlobalStyles.red }} />;
        }
        return <ErrorIcon style={{ color: GlobalStyles.red }} />;
      }
      else if (is_empty === true) {
        return <ErrorIcon style={{ color: GlobalStyles.red }} />;
      }

      else if (unitqty_valid === false) {
        return <ErrorIcon style={{ color: GlobalStyles.red }} />;
      }

      else if (unitname_valid === false) {
        return <ErrorIcon style={{ color: GlobalStyles.red }} />;
      }
      else if (!attr_qty_valid) {
        return <ErrorIcon style={{ color: GlobalStyles.red }} />;
      }

      return '';
    }
  }

  const renderTempRecDetail = (rowData: any) => {
    if (firstValidationSuccess) {
      const { status_code, status, disabled, warning_msg, is_empty, unitqty_valid, unitname_valid, companies, master_product_name, attr_qty_valid } = rowData;
      const MP_NAME_DUPLICATE_ERROR = ` This MP ${master_product_name} is duplicated from imported data.`;
      const MISSING_FIELD_ERROR = 'All fields are required.';
      const UNIT_QTY_ERROR = 'Unit Qty must be greater than 0.';
      const UNIT_NAME_ERROR = 'Unit Name must be a valid Weight.';
      const ATTR_QTY_ERROR = 'Quantity must be greater than 0.'
      if (status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT && !is_empty && !disabled && !companies && warning_msg === '') {
        return (
          <Typography className={classes.statusText}
            style={{ color: '#43a047' }} >
            <span style={{ paddingRight: 8 }}>
              <CheckCircleIcon style={{ color: GlobalStyles.green }} />
            </span>
            {status}
          </Typography>
        );
      }

      else if (mpValidationSuccess && !disabled && !is_empty) {
        if ((warning_msg === '' || !warning_msg) && !companies && status_code !== MasterSupplierDataImportStatusCode.READY_TO_IMPORT) {
          return (
            <Typography
              className={classes.statusText}
              style={{ color: GlobalStyles.red }} >
              {`Error: ${status}`}
            </Typography>
          );
        }
        else if ((warning_msg === '' || !warning_msg) && companies && status_code !== MasterSupplierDataImportStatusCode.READY_TO_IMPORT) {
          return (
            <Typography
              className={classes.statusText}
              style={{ color: GlobalStyles.red }} >
              {`Error: ${status} ${uniq(companies).join(", ")}. Please add one of these names to the company field and reupload`}
            </Typography>
          );
        }
        else if (warning_msg != '') {
          return (
            <div >
              <div style={{ fontSize: 16 }}>
                {
                  status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT &&
                  <Typography
                    className={classes.statusText}
                    style={{ color: '#43a047' }} >
                    <span style={{ paddingRight: 8 }}>
                      <CheckCircleIcon style={{ color: GlobalStyles.green }} />
                    </span>
                    {status}
                  </Typography>
                }
                <Typography className={classes.statusText} >
                  <span style={{ paddingRight: 8 }}>
                    <ErrorIcon style={{ color: GlobalStyles.warningText }} />
                  </span>
                  {`This Master Product is already exists with Unit Qty: ${rowData.unitqty_mapped ? rowData.unitqty_mapped : ''}, Unit Name: ${rowData.unitname_mapped ? rowData.unitname_mapped : ''}, Brand: ${rowData.mpbrand_mapped ? rowData.mpbrand_mapped : ''}.`}
                </Typography>
              </div>

              {!(rowData.mp_unit_qty === rowData.unitqty_mapped && rowData.mp_unit_name === rowData.unitname_mapped && rowData.brand_name === rowData.mpbrand_mapped) &&
                <div className={classes.statusText}>
                  <span style={{ paddingRight: 8 }}>
                    <ErrorIcon style={{ color: GlobalStyles.warningText }} />
                  </span>
                  {rowData.warning_msg}
                </div>
              }
              {
                (status_code === MasterSupplierDataImportStatusCode.EXISTING_IDENTIFIER_ERROR ||
                  status_code === MasterSupplierDataImportStatusCode.EXISTING_LINK_RECORD_ERROR ||
                  status_code === MasterSupplierDataImportStatusCode.SAME_BRAND_DIFF_COMPANY ||
                  status_code === MasterSupplierDataImportStatusCode.EXISTING_INVOICE_ITEM_NAME_ERROR ||
                  status_code === MasterSupplierDataImportStatusCode.EXISTING_REORDER_ERROR) &&
                (
                  <Typography className={classes.statusText} >
                    <span style={{ paddingRight: 8 }}>
                      <ErrorIcon style={{ color: GlobalStyles.red }} />
                    </span>
                    {`Error: ${rowData.status}`}
                  </Typography>
                )
              }
            </div>
          );
        }
      }

      else if (disabled) {
        if (is_empty) {
          if (!unitqty_valid) {
            if (!unitname_valid) {
              if (!attr_qty_valid) {
                return (
                  <div>
                    <Typography className={classes.statusText}
                      style={{ color: GlobalStyles.red }} >
                      {MP_NAME_DUPLICATE_ERROR}
                    </Typography>
                    <Typography className={classes.statusText}
                      style={{ color: GlobalStyles.red }} >
                      {MISSING_FIELD_ERROR}
                    </Typography>
                    <Typography className={classes.statusText}
                      style={{ color: GlobalStyles.red }} >
                      {UNIT_QTY_ERROR}
                    </Typography>
                    <Typography className={classes.statusText}
                      style={{ color: GlobalStyles.red }} >
                      {UNIT_NAME_ERROR}
                    </Typography>
                    <Typography className={classes.statusText}
                      style={{ color: GlobalStyles.red }} >
                      {ATTR_QTY_ERROR}
                    </Typography>
                  </div>
                );
              }
              return (
                <div>
                  <Typography className={classes.statusText}
                    style={{ color: GlobalStyles.red }} >
                    {MP_NAME_DUPLICATE_ERROR}
                  </Typography>
                  <Typography className={classes.statusText}
                    style={{ color: GlobalStyles.red }} >
                    {MISSING_FIELD_ERROR}
                  </Typography>
                  <Typography className={classes.statusText}
                    style={{ color: GlobalStyles.red }} >
                    {UNIT_QTY_ERROR}
                  </Typography>
                  <Typography className={classes.statusText}
                    style={{ color: GlobalStyles.red }} >
                    {UNIT_NAME_ERROR}
                  </Typography>
                </div>
              );
            }
            return (
              <div>
                <Typography className={classes.statusText}
                  style={{ color: GlobalStyles.red }} >
                  {MP_NAME_DUPLICATE_ERROR}
                </Typography>
                <Typography className={classes.statusText}
                  style={{ color: GlobalStyles.red }} >
                  {MISSING_FIELD_ERROR}
                </Typography>
                <Typography className={classes.statusText}
                  style={{ color: GlobalStyles.red }} >
                  {UNIT_QTY_ERROR}
                </Typography>
              </div>
            );
          }
          return (
            <div>
              <Typography className={classes.statusText}
                style={{ color: GlobalStyles.red }} >
                {MP_NAME_DUPLICATE_ERROR}
              </Typography>
              <Typography className={classes.statusText}
                style={{ color: GlobalStyles.red }} >
                {MISSING_FIELD_ERROR}
              </Typography>
            </div>
          );
        }
        return (
          <Typography className={classes.statusText}
            style={{ color: GlobalStyles.red }} >
            {MP_NAME_DUPLICATE_ERROR}
          </Typography>
        );
      }

      else if (is_empty === true) {
        return (
          <Typography className={classes.statusText}
            style={{ color: GlobalStyles.red }} >
            {MISSING_FIELD_ERROR}
          </Typography>
        );
      }

      else if (!unitqty_valid) {
        return (
          <Typography className={classes.statusText}
            style={{ color: GlobalStyles.red }} >
            {UNIT_QTY_ERROR}
          </Typography>
        );
      }

      else if (!unitname_valid) {
        return (
          <Typography className={classes.statusText}
            style={{ color: GlobalStyles.red }} >
            {UNIT_NAME_ERROR}
          </Typography>
        );
      }

      else if (!attr_qty_valid) {
        return (
          <Typography className={classes.statusText}
            style={{ color: GlobalStyles.red }} >
            {ATTR_QTY_ERROR}
          </Typography>
        );
      }
      return '';
    }
  }




  const handleSelectTempLink = (event: any, item: any) => {

    let selectedItem = cloneDeep(item)

    let updatedProds = cloneDeep(tempProdData)
    selectedItem.is_selected = event.target.checked
    console.log("updated", selectedItem)
    let foundIndex = updatedProds.findIndex(item => item.id === selectedItem.id)
    if (foundIndex !== null) {
      console.log("foundIndex", foundIndex)
      updatedProds[foundIndex] = selectedItem
      setTempProdData(updatedProds)
    }
  }

  const handleExpandRecPanel = (tempItem: any) => {
    console.log("tempItem-Clicked", tempItem)
    let updatedRecs = cloneDeep(tempProdData)
    let itemIndex = updatedRecs.findIndex(rec => rec.id === tempItem.id)
    console.log("ItemIndex", itemIndex)
    if (itemIndex != -1) {
      console.log(" updatedRecs[itemIndex]['is_open']", updatedRecs[itemIndex]['is_open'])
      updatedRecs[itemIndex]['is_open'] = !updatedRecs[itemIndex]['is_open']
      setTempProdData(updatedRecs)
      console.log("updatedRecs", updatedRecs)
    }
  }



  const renderLinksPanel = (mode: any) => {
    let itemRecords = tempProdData && tempProdData.length > 0 ? tempProdData : null


    console.log("itemRecords", itemRecords)
    return (
      <ExpansionPanel
        style={{ marginTop: 5, backgroundColor: '#f5f5f5' }}
        expanded={true}
      // onChange={handleChange(linkedTempRecs)}
      >

        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          id="linkedTempRecs"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ alignSelf: 'center' }} >
              <Typography className={classes.heading}>Temp Links to Approve</Typography>
            </div>
            <div style={{ justifyContent: 'flex-end' }} >
              {isEditMode() &&
                <>
                  {masterData && masterData.status_code === MasterSupplierDataImportStatusCode.EXISTING_INVOICE_ITEM_NAME_ERROR &&
                    <Tooltip title={'Approval'}>

                      <IconButton
                        color="primary"
                        onClick={() => setApproveLinkDialogOpen(true)}
                        disabled={(itemRecords.filter(i => i.is_selected)).length === 0 ? true : false}
                      >
                        <ThumbUpIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  <Tooltip title={'UnLink'}>

                    <IconButton
                      color="primary"
                      onClick={() => setDeleteLinkDialogOpen(true)}
                      disabled={(itemRecords.filter(i => i.is_selected)).length === 0 ? true : false}
                    >
                      <LinkOffIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={'Delete'}>

                    <IconButton
                      onFocus={(event) => event.stopPropagation()}
                      className={classes.rowActionBtn}
                      // disabled={(itemRecords.filter(i => i.is_selected)).length === 0 ? true : false}
                      disabled={(itemRecords.filter(i => i.is_selected)).length === 0 ? true : false}
                      onClick={() => setDeleteTempRecsDialogOpen(true)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.panelCol}>
            <div className={classes.panelRowHeader}>
              {
                isEditMode() &&
                <Typography
                  className={classes.panelHeaderText}
                  style={{ flex: 1.5, textAlign: 'center', minWidth: '80px' }}
                >
                  Actions
                </Typography>
              }
              <Typography className={classes.panelHeaderText} style={{ flex: 3 }}>
                Supplier Company
              </Typography>
              <Typography className={classes.panelHeaderText} style={{ flex: 3 }}>
                Business
              </Typography>
              <Typography className={classes.panelHeaderText} style={{ flex: 3 }}>
                Business Product Name
              </Typography>
              <Typography className={classes.panelHeaderText} style={{ flex: 3 }}>
                ReorderCode
              </Typography>
              <Typography className={classes.panelHeaderText} style={{ flex: 2 }}>
                Quantity
              </Typography>
              <Typography className={classes.panelHeaderText} style={{ flex: 2 }}>
                CQ
              </Typography>
              <Typography className={classes.panelHeaderText} style={{ flex: 2 }}>
                Cost
              </Typography>
              <Typography className={classes.panelHeaderText} style={{ flex: 2 }}>
                Created By
              </Typography>
              {/* <Typography className={classes.panelHeaderText} style={{ flex: 2 }}>
                Status
              </Typography> */}
            </div>

            <div className={classes.panelCol}>
              {
                itemRecords &&
                itemRecords.length > 0 &&
                sortBy(itemRecords, 'created_time').map((tempItem: any, index: any) =>
                  <div key={`${String(tempItem)}-${String(index)}`}>
                    <Divider />
                    <div style={{ width: '100%' }}>
                      <Accordion
                        expanded={false}
                        onChange={() => handleExpandRecPanel(tempItem)}
                        style={{ backgroundColor: !isEditMode() ? '#ebebeb' : null }}
                        disabled={!isEditMode()}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          style={{ userSelect: 'text' }}
                        >
                          {
                            isEditMode() &&
                            <div style={{ display: 'flex', flex: 1.5 }}>
                              <Tooltip title="Select">
                                <Checkbox
                                  checked={tempItem.is_selected ? tempItem.is_selected : false}
                                  onChange={(e: any) => handleSelectTempLink(e, tempItem)}
                                  color="primary"
                                  disabled={!isEditMode() ? true : false}
                                  onFocus={(event) => event.stopPropagation()}
                                />
                              </Tooltip>

                            </div>
                          }
                          <Typography className={classes.panelRowText}
                            style={{ flex: 3 }}>
                            {tempItem && tempItem.master_company && tempItem.master_company.name}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            style={{ flex: 3 }}>
                            {tempItem && tempItem.business && tempItem.business.name}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            style={{ flex: 3 }}>
                            {tempItem && tempItem.business_product ? tempItem.business_product.name : ''}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            style={{ flex: 3 }}>
                            {tempItem && tempItem.reorder_code ? tempItem.reorder_code : ''}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            style={{ flex: 2 }}>
                            {tempItem && tempItem.quantity ? tempItem.quantity : ''}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            style={{ flex: 2 }}>
                            {tempItem && tempItem.case_qty ? tempItem.case_qty : ''}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            style={{ flex: 2 }}>
                            {tempItem && tempItem.cost_per_case ? tempItem.cost_per_case : ''}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            style={{ flex: 2 }}>
                            {tempItem && tempItem.created_by ? `${tempItem.created_by.first_name} ${tempItem.created_by.last_name}` : ''}
                          </Typography>
                          {/* {renderStatus(tempItem)} */}
                        </AccordionSummary>
                        <Divider />
                        {/* <AccordionDetails>
                          {renderTempRecDetail(tempItem)}
                        </AccordionDetails> */}
                      </Accordion>
                    </div>
                  </div>
                )
              }
              {
                itemRecords &&
                itemRecords.length === 0 &&
                <div className={classes.noDataRow}>
                  <Typography align='center'>No Linked Temp Records</Typography>
                </div>
              }
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

    )
  }




  const checkUnitQtyValid = (mpData: any) => {

    let letters = /^[a-z]+$/;

    let mp_unit_qty = mpData['mp_unit_qty'];
    let mp_unit_name = mpData['mp_unit_name'];
    let invoice_item_desc = mpData['invoice_item_desc'];
    let reorder_code = mpData['reorder_code'];
    let attr_qty = mpData['attr_qty'];

    let updatedRecord = mpData
    const unitQtyNotValid = isNaN(mp_unit_qty) ? true : false;
    const attrQtyNotValid = ((invoice_item_desc != null && invoice_item_desc != '') || (reorder_code != null && reorder_code != '')) ? isNaN(attr_qty) ? true : false : true;

    if (mp_unit_name.match(letters)) {
      updatedRecord['unitname_valid'] = true;
      mpData = updatedRecord

    }

    if (!unitQtyNotValid) {
      if (mp_unit_qty != 0 && mp_unit_qty > 0) {
        updatedRecord['unitqty_valid'] = true;
        mpData = updatedRecord
      }
    }

    if (!attrQtyNotValid) {
      if (attr_qty != 0 && attr_qty > 0) {
        updatedRecord['attr_qty_valid'] = true;
        mpData = updatedRecord
      }
    }
    return mpData
  };


  const getUnique = (items: any) => {
    const uniqueArray = items.reduce((uniqueRows, currentRow) => {
      if (!uniqueRows.some(
        uniqueRow =>
          uniqueRow.mp_unit_qty === currentRow.mp_unit_qty &&
          uniqueRow.mp_unit_name === currentRow.mp_unit_name &&
          uniqueRow.brand_name === currentRow.brand_name &&
          uniqueRow.retail_barcode === currentRow.retail_barcode &&
          uniqueRow.invoice_item_desc === currentRow.invoice_item_desc &&
          uniqueRow.reorder_code === currentRow.reorder_code &&
          uniqueRow.attr_qty === currentRow.attr_qty
      )) {
        const dupIndex = uniqueRows.findIndex(uniqueRow => uniqueRow.master_product_name.split(' ').join('').toLowerCase() === currentRow.master_product_name.split(' ').join('').toLowerCase());
        uniqueRows.push({
          ...currentRow,
          disabled: dupIndex < 0 ? false : true,
          isMpNameDuplicate: dupIndex < 0 ? false : true,
        });

      }
      return uniqueRows;
    }, []);
    return uniqueArray;

  };


  const checkMissingAttrValues = (obj: any) => {

    let updatedObj = obj
    if ((updatedObj['invoice_item_desc'] !== null) || (updatedObj['invoice_item_desc'] !== '')) {
      updatedObj['invoice_item_desc_exists'] = true
      updatedObj['disabled'] = false
      updatedObj['isMpNameDuplicate'] = false
    }
    if ((updatedObj['reorder_code'] !== null) || (updatedObj['reorder_code'] !== '')) {
      updatedObj['roc_exists'] = true
    }
    updatedObj['disabled'] = false
    updatedObj['isMpNameDuplicate'] = false

    return updatedObj

    // let validatedRecs = cloneDeep(items)
    // validatedRecs.forEach((item: any) => {
    //   if ((item.invoice_item_desc !== null) || (item.invoice_item_desc !== '')) {
    //     item['invoice_item_desc_exists'] = true
    //     item['disabled'] = false
    //     item['isMpNameDuplicate'] = false
    //   }
    //   if ((item.reorder_code !== null) || (item.reorder_code !== '')) {
    //     item['roc_exists'] = true
    //   }
    //   item['disabled'] = false
    //   item['isMpNameDuplicate'] = false
    // })
    // console.log("Missing Attr vAlues", validatedRecs)
    // return validatedRecs
  }



  const getDataToBeValidated = (validationData: any) => {
    console.log("before ", invoiceItemDescription)

    if (invoiceItemDescription != '') {
      let obj = validationData
      if (!obj) {
        obj = {};
      }
      console.log("invoiceItemDescription", invoiceItemDescription)
      obj['invoice_item_desc'] = invoiceItemDescription != '' ? invoiceItemDescription.trim() : ''
      obj['brand_name'] = selectedBrand ? selectedBrand['name'] : ''
      obj['reorder_code'] = newReorderCode ? newReorderCode.trim() : ''
      obj['attr_qty'] = newCaseQuantity ? newCaseQuantity.trim() : ''
      obj['retail_barcode'] = newRetailBarcode ? newRetailBarcode.trim() : ''
      obj['master_product_name'] = newMasterProdName ? newMasterProdName.trim() : ''
      obj['mp_unit_qty'] = newMasterUOMQty ? newMasterUOMQty.trim() : ''
      obj['mp_unit_name'] = newMasterUOM ? newMasterUOM.trim() : ''
      obj['company_name'] = ''
      obj['warning_msg'] = '';
      obj['identifiers_mp_mapped'] = '';
      obj['disabled'] = false;
      obj['unitqty_valid'] = false;
      obj['unitname_valid'] = false;
      obj['attr_qty_valid'] = false
      obj['invoice_item_desc_exists'] = false;
      obj['roc_exists'] = false;
      obj['status'] = '';
      obj['master_company'] = tempProdData && tempProdData.length > 0 ? tempProdData[0]['master_company'] : null
      // mp_data.push(validationData[i])

      // }

      const requiredKeys = ['attr_qty', 'brand_name', 'master_product_name', 'mp_unit_qty', 'mp_unit_name', 'reorder_code'];
      obj['is_empty'] = false;
      for (let key of requiredKeys) {
        if (key in obj && obj[key] === "") {
          obj['is_empty'] = true;
          break;
        }
      }


      let validRecord = checkUnitQtyValid(obj);
      // const uniqueRecords = getUnique(validRecords);
      let resultant = checkMissingAttrValues(validRecord);
      setMasterData(resultant)

      return resultant
    } return null

  }


  const handleApproveLinkDialogClose = () => {
    setDeleteLinkDialogOpen(false);
  };

  const approveLinkDialog = () => {
    return (
      <div>
        <Dialog
          open={approveLinkDialogOpen}
          onClose={handleApproveLinkDialogClose}
        >
          <DialogTitle>{"Apoprove Temp Link"}</DialogTitle>
          <DialogContent>
            <DialogContentText >
              {`Are you sure want to approve this temporary record(s) ?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleApproveLinkDialogClose} color="primary" >
              No
            </Button>
            <Button onClick={() => handleApproveTemporaryLinks()} style={{ color: GlobalStyles.red }} autoFocus >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }



  const handleDeleteLinkDialogClose = () => {
    setDeleteLinkDialogOpen(false);
  };

  const deleteLinkDialog = () => {
    return (
      <div>
        <Dialog
          open={deleteLinkDialogOpen}
          onClose={handleDeleteLinkDialogClose}
        >
          <DialogTitle>{"Unlink"}</DialogTitle>
          <DialogContent>
            <DialogContentText >
              {`Are you sure want to unlink this supplier product linked to the selected temporary record(s) ?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteLinkDialogClose} color="primary" >
              No
            </Button>
            <Button onClick={() => handleRemoveBussProdLinks()} style={{ color: GlobalStyles.red }} autoFocus >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }


  const handleDeleteTempRecsDialogClose = () => {
    setDeleteTempRecsDialogOpen(false);
  };

  const deleteTempRecsDialog = () => {
    return (
      <div>
        <Dialog
          open={deleteTempRecsDialogOpen}
          onClose={handleDeleteTempRecsDialogClose}
        >
          <DialogTitle>{"Remove Linked Product"}</DialogTitle>
          <DialogContent>
            <DialogContentText >
              {`Are you sure want to delete the selected temporary record(s) ?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteTempRecsDialogClose} color="primary" >
              No
            </Button>
            <Button onClick={() => handleDeleteTempRecs()} style={{ color: GlobalStyles.red }} autoFocus >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }


  const handleApproveTemporaryLinks = () => {
    let selected_temp_prods = cloneDeep(tempProdData)
    selected_temp_prods = selected_temp_prods.filter(i => i.is_selected)
    if (selected_temp_prods.length === 0) {
      props.showToast({
        message: 'Please select at least one temp link to approve',
        options: ToastError
      });
    } else {
      setLoading(true)
      let payload = {
        auth_token: props.userData.userData.userData.auth_token,
        invoice_item_desc: invoiceItemDescription,
        temp_prods: selected_temp_prods
      }
      console.log("PAYLOADDD", payload)
      approveTemporaryLinks(payload)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          initializeData(jsonData)
          props.showToast({
            message: 'Temp record approved!',
            options: ToastSuccess
          });
          setLoading(false);
          // window.close();
        })
        .catch((error: any) => {
          setMpValidationSuccess(false)
          let msg = 'Failed to approve';
          if ((error.status === 403 || error.status === 401)
            && error.body.detail.length > 0) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          }
          props.showToast({ message: msg, options: ToastError });
          setLoading(false)
        });
    }
  }


  const handleRemoveBussProdLinks = () => {
    let selected_temp_prods = cloneDeep(tempProdData)
    selected_temp_prods = selected_temp_prods.filter(i => i.is_selected)
    if (selected_temp_prods.length === 0) {
      props.showToast({
        message: 'Please select at least one temp link to remove business product',
        options: ToastError
      });
    } else {
      setLoading(true)
      let payload = {
        auth_token: props.userData.userData.userData.auth_token,
        invoice_item_desc: invoiceItemDescription,
        temp_prods: selected_temp_prods
      }
      console.log("PAYLOADDD", payload)
      removeBussProdLinks(payload)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          initializeData(jsonData)
          props.showToast({
            message: 'Removed Linked Product!',
            options: ToastSuccess
          });
          setLoading(false);
          // window.close();
        })
        .catch((error: any) => {
          setMpValidationSuccess(false)
          let msg = 'Failed to create Master Data';
          if ((error.status === 403 || error.status === 401)
            && error.body.detail.length > 0) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          }
          props.showToast({ message: msg, options: ToastError });
          setLoading(false)
        });
    }
  }



  const handleDeleteTempRecs = () => {
    let selected_temp_prods = cloneDeep(tempProdData)
    selected_temp_prods = selected_temp_prods.filter(i => i.is_selected)
    if (selected_temp_prods.length === 0) {
      props.showToast({
        message: 'Please select at least one temp link to delete',
        options: ToastError
      });
    } else {
      setLoading(true)
      let payload = {
        auth_token: props.userData.userData.userData.auth_token,
        invoice_item_desc: invoiceItemDescription,
        temp_prods: selected_temp_prods
      }
      console.log("PAYLOADDD", payload)
      deleteTempRecs(payload)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          initializeData(jsonData)
          props.showToast({
            message: 'Removed Linked Product!',
            options: ToastSuccess
          });
          setLoading(false);
          // window.close();
        })
        .catch((error: any) => {
          setMpValidationSuccess(false)
          let msg = 'Failed to create Master Data';
          if ((error.status === 403 || error.status === 401)
            && error.body.detail.length > 0) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          }
          props.showToast({ message: msg, options: ToastError });
          setLoading(false)
        });
    }
  }



  const handleValidate = () => {
    if (masterData) {
      // let mp_details = getDataToBeValidated(selected_temp_prods)

      setLoading(true)
      let payload = {
        auth_token: props.userData.userData.userData.auth_token,
        master_product_details: masterData,
        invoice_item_desc: invoiceItemDescription,
      }
      console.log("PAYLOADDD", payload)
      validateTempLinks(payload)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          setMasterData(jsonData['records'][0])
          // let remaining_recs = differenceBy(tempProdData, validatedRecs, 'id')
          // let updatedRecs = [...validatedRecs, ...remaining_recs]

          // console.log("remaining_recs", remaining_recs)
          // console.log("upodatedRecs", updatedRecs)
          setMpValidationSuccess(true)
          props.showToast({
            message: 'Validated Successfully!',
            options: ToastSuccess
          });
          setLoading(false);
        })
        .catch((error: any) => {
          setMpValidationSuccess(false)
          let msg = 'Failed to validate';
          if ((error.status === 403 || error.status === 401)
            && error.body.detail.length > 0) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          }
          props.showToast({ message: msg, options: ToastError });
          setLoading(false)
        });
    }

  }



  const handleSave = () => {

    let selected_temp_prods = cloneDeep(tempProdData)
    selected_temp_prods = selected_temp_prods.filter((t: any) => t.is_selected);
    if (selected_temp_prods.length > 0) {
      let mp_data = null
      if (masterData.hasOwnProperty("status")) {
        if (masterData['status_code'] === MasterSupplierDataImportStatusCode.READY_TO_IMPORT) {
          mp_data = masterData
        }
      }

      if (mp_data) {
        let selectedCompanyId = mp_data['master_company']['id']
        setLoading(true)
        let payload = {
          auth_token: props.userData.userData.userData.auth_token,
          company_id: selectedCompanyId,
          records: mp_data,
          temp_records: selected_temp_prods,
          invoice_item_desc: invoiceItemDescription,
        }
        console.log("PAYLOADDD", payload)
        approveTempRecords(payload)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(jsonData => {
            setImportSuccess(true)
            setDisableValidate(true)
            setDisableImport(true)
            props.showToast({
              message: 'Company Data and Master Product Imported Successfully!',
              options: ToastSuccess
            });
            // confirmReset()
            setPageMode({ type: 'edit_mode' });
            setLoading(false);
            window.close()
          })
          .catch((error: any) => {
            setImportSuccess(false)
            setDisableImport(false)
            let msg = 'Failed to Import';
            if ((error.status === 403 || error.status === 401)
              && error.body.detail.length > 0) {
              msg = NO_PERMISSIONS_ERROR_MSG;
            }
            props.showToast({ message: msg, options: ToastError });
            setLoading(false)
          });
      } else {
        let msg = 'There are no records is ready to import';
        props.showToast({ message: msg, options: ToastError });
      }
    } else {
      let msg = 'Please select at least one temp link to approve';
      props.showToast({ message: msg, options: ToastError });
    }



  }



  const renderValidationResults = () => {
    // if (mpValidationSuccess) {
    return (
      <Paper elevation={3}>
        <div style={{}}>

        </div>
      </Paper>
    )
    // }
  }





  return (
    <div>
      {
        isLoading &&
        <div className={classes.rowCenter}>
          <CircularProgress />
        </div>
      }
      {
        !isLoading && (tempProdData != null) &&

        <div>
          <Card className={classes.card}>
            <CardContent>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '8px',
                borderBottom: '2px solid #adb5bd'
              }}>
                <div style={{ display: 'flex' }}>
                  {/* <IconButton aria-label="back"
                    onClick={confirmBackNavigation}>
                    <ArrowBackIcon />
                  </IconButton> */}
                  <Typography variant='h6'
                    className={classes.title}>Temp Linked Product Detail</Typography>
                </div>
                <div style={{ alignSelf: 'center' }}>
                  <Tooltip title="Toggle Page Mode">
                    <IconButton
                      style={{
                        color: isEditMode() ? '#ffb300' : GlobalStyles.primaryColor,
                        border: '2px solid currentColor',
                        borderRadius: '4px',
                        padding: '4px'
                      }}
                      onClick={() => togglePageMode()}>
                      {isEditMode() ? <EditIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              <div style={{ display: 'flex', paddingTop: '8px', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                  {masterData && renderStatus(masterData)}
                  {masterData && renderTempRecDetail(masterData)}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
                  {renderPageActions()}
                </div>
              </div>
              <Grid container spacing={2} style={{ paddingTop: 4 }}>
                <Grid item xs={6}>
                  {
                    <>
                      <Grid container className={classes.row}>
                        {
                          tempProdData ?
                            <>
                              {invoiceItemDescription != '' && showMetaData()}
                              {renderMasterProductInfo()}
                            </> : null
                        }
                      </Grid>
                    </>
                  }
                </Grid>
                <Grid item xs={6}>
                  {renderSecondGridItems()}
                </Grid>
              </Grid>
              {/* {renderValidationResults()} */}
              {tempProdData ? renderLinksPanel('temp') : null}
            </CardContent>
          </Card>
        </div>
      }
      {resetDialog()}
      {deleteLinkDialog()}
      {deleteTempRecsDialog()}
      {approveLinkDialog()}
    </div>
  )
}



const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveTempLinkDetailScreen);