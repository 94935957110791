import React, { Profiler } from 'react';
import {
  TextField, Checkbox, ListItem, ListItemIcon,
  FormControl, InputLabel, CircularProgress, Button, Paper, FormLabel, RadioGroup,
  Radio, FormGroup, FormControlLabel, IconButton, InputAdornment, FormHelperText, Switch
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItemText from '@material-ui/core/ListItemText';
import TransferListItem from './TransferListItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: '100%',
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

interface TransferCustomListProps {
    items: [{
        id: number| string;
        label: string;
    }]
    checked: any[];
    handleToggle: (e: any, item: any) => void
}

const profilerCallback = (id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions // the Set of interactions belonging to this update
  ) => {
    console.log("actualDuration inside", actualDuration)
  }


const TransferCustomList = React.memo((props: TransferCustomListProps) => {
  const {items, handleToggle, checked} = props;
  const classes = useStyles();
  console.log("rendering again custom list")
  return (
    <Profiler id="customlist" onRender={profilerCallback}>
    <List className={classes.list} dense component="div" role="list">
    {items.map((item) => {
      

      return (
        <TransferListItem key={item.id} item={item} checked={checked.indexOf(item) !== -1} onToggle={handleToggle} />
      );
    })}
    <ListItem />
  </List>
  </Profiler>
)});

export default TransferCustomList