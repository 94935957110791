import React from 'react';
import {
  TextField, Checkbox, List, ListItem, ListItemIcon,
  FormControl, InputLabel, CircularProgress, Button, Paper, FormLabel, RadioGroup,
  Radio, FormGroup, FormControlLabel, IconButton, InputAdornment, FormHelperText, Switch
} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';

interface TransferListItemProps {
    item: {
        id: number| string;
        label: string;
    }
    checked: boolean;
    onToggle: (e: any, item: any) => void
}


const TransferListItem = React.memo((props: TransferListItemProps) => {
  console.log("rendering again list item")

  let { item, checked, onToggle } = props;
  const labelId = `transfer-list-all-item-${item.id}-label`;
  const myhandleToggle = (e) => {
    console.log(e)
    onToggle(e, item)
  }
    return (
      <>
        <ListItem key={item.id} role="listitem" button onClick={(e) => myhandleToggle(e)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${item.label}`} />
            </ListItem>
      </>
    )
}, (prevProps, nextProps) => {
  // Only re-render if the checked state or item text changes
  return prevProps.checked === nextProps.checked && prevProps.item.label === nextProps.item.label
   && prevProps.onToggle === nextProps.onToggle;
})

export default TransferListItem