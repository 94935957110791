import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  TextField, Checkbox, List, ListItem, ListItemIcon,
  FormControl, InputLabel, CircularProgress, Button, Paper, FormLabel, RadioGroup,
  Radio, FormGroup, FormControlLabel, IconButton, InputAdornment, FormHelperText, Switch, Select, MenuItem
} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import SaveIcon from '@material-ui/icons/Save';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { getIsPasswordValid, resetPassword } from '../../../account/api'
import { getCoreUserDetail, saveKonnectUser, getPermissions, getGroups } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { getBusinessListFromAPI } from '../../../shared/data/actions';
import { ConfirmModal } from '../../../shared/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { getErrorMsg } from '../../../shared/lib/Localization';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { blue } from '@material-ui/core/colors';
import cloneDeep from 'lodash/cloneDeep';
import { PageToolbar } from '../../../page/components';
import _isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import PermissionListItem from './PermissionListItem';
import TransferList from '../../../shared/components/TransferList/TransferList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCenter: {
      // minHeight: 150,
      // alignItems: 'center',
      // display: 'flex',
      // justifyContent: 'center',
      position: 'absolute',
      left: '50%',
      top: '50%'
    },
    root: {
      padding: theme.spacing(2, 2),
      border: '1px solid',
      borderColor: '#cdcdcd',
      borderRadius: 4
    },
    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start'

    },
    checkSpinner: {
      padding: 10,
      marginLeft: 35,
    },
    innerdiv: {

      display: 'flex',
      flexDirection: 'row',
      // paddingBottom: 20,
      width: '90%',
      justifyContent: 'start'
    },
    pswdDiv: {

      display: 'flex',
      flexDirection: 'row',
      // paddingBottom: 20,
      justifyContent: 'end'
    },

    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '20rem',
      minWidth: '12rem',
    },
    multiSelect: {
      color: GlobalStyles.lightBlue,
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.lightBlue,
      },
      '&:after': {
        borderColor: GlobalStyles.lightBlue,
      },
      maxWidth: '12rem',
      minWidth: '12rem',
      minHeight: 150
    },
    icon: {
      fill: GlobalStyles.lightTheme,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,

    },
    formContent: {

      display: 'flex',
      flexDirection: 'row'
    },

    content: {
      margin: '6px!important',
      width: '100%'
    },

    checkPasswordBtn: {
      marginTop: 6,
      marginLeft: 15,
      color: GlobalStyles.textWhite,
      textDecorationColor: GlobalStyles.primaryColor,
      backgroundColor: GlobalStyles.primaryColor,
    },
    changePasswordBtn: {
      marginTop: 6,
      marginLeft: 15,
      color: GlobalStyles.textWhite,
      textDecorationColor: GlobalStyles.primaryColor,
      backgroundColor: GlobalStyles.red,
      height: '2.5rem'
    },

  }),
);


const ConfirmModalData = {
  isOpen: false,
  message1: 'You will lose all unsaved changes.',
  message2: ' Are you sure you want to leave this page?',
  title: 'Confirm Navigation',
  confirmBtnText: 'Yes',
  onCancel: () => { },
  onConfirm: () => { }
};

const UserDetailWebScreen = (props: any) => {
  const classes = useStyles({});
  const [isLoading, setLoading] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isCreateEnable, setCreateEnable] = useState(false);
  const [origPasswordExists, setOrigPasswordExists] = useState(false);
  const [showRequiredFieldsError, setShowRequiredFieldsError] = useState(false);
  const [userData, setUserData] = useState(null);
  const [originalUserData, setOriginalUserData] = useState(null);

  const [permissionList, setPermissionList] = useState(null);
  const [filteredPermissionList, setFilteredPermissionlist] = useState(null)
  const [selectedPermissionList, setSelectedPermissionList] = useState([]);
  const [allPermissionsSelected, setAllPermissionsSelected] = useState(false);
  const [originalPermList, setOriginalPermList] = useState(null);

  const [locationList, setLocationList] = useState(null);
  const [filteredLocationList, setFilteredLocationlist] = useState(null)
  const [selectedLocationList, setSelectedLocationList] = useState([]);
  const [allLocationsSelected, setAllLocationsSelected] = useState(false);
  const [originalLocList, setOriginalLocList] = useState(null);

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [checkingPassword, setCheckingPassword] = useState(false);
  
  const [confirmModalData, setConfirmModalData] = useState(ConfirmModalData);

  const [locationSearchTerm, setLocationSearchTerm] = useState(null)
  const [permissionSearchTerm, setPermissionSearchTerm] = useState(null)
  
  const [incorrectAttempts,setIncorrectAttempts]=useState(0);
  const [renderResetPassword ,setRenderResetPassword] = useState(false)
  const [authGroups, setAuthGroups] = useState([])
  const [selectedAuthGroup, setSelectedAuthGroup] = useState(null)
  const [userInputDetailsError, setUserInputDetailsErrror] = useState({
    first_name: null,
    last_name: null,
    username: null,
    email: null,
    password: null,
    pin: null,
    is_active: null,
    new_password: null,
    old_password: null,
    allow_web_login: null,
    allow_device_login: null
  } as any);

  let permissionsOnDisplay = filteredPermissionList ? filteredPermissionList : permissionList
  let locationsOnDisplay = filteredLocationList ? filteredLocationList : locationList

  useEffect(()=>{
    console.log("LOCATON-LIST", locationList)
    console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
    console.log("Selected-LOCATIONs", selectedLocationList)
  },[selectedLocationList, locationList])
   

  const getUserDetail = (id: any) => {
    setLoading(true);
    return 
  }
  
  const getAuthGroups = () => {
    setLoading(true);
    getGroups()
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(data => {
        setLoading(false);
        setAuthGroups(data)

      })
      .catch((error: any) => {
        setLoading(false);
        props.showToast({
          message: 'Failed to auth groups',
          options: ToastError
        });
      });
  }


  const initializeData=(data: any)=>{
    setUserData(data)
    setOriginalUserData(cloneDeep(data));
    setOrigPasswordExists(data.password_exists)
    
    if (data.allow_device_login) {
      if (!data.pin || data.pin === '') {
        setUserInputDetailsErrror({ ...userInputDetailsError, ['pin']: "Pin is required" });
      }
    }
    if (data.allow_web_login) {
      if (!data.password_exists) {
        setUserInputDetailsErrror({ ...userInputDetailsError, ['password']: "Password is required" });
      }
    }
    
    
  }
  // const initializeData=(data: any)=>{
  //   setUserData(data)
  //   setOriginalUserData(cloneDeep(data));
  //   if (data.loc_perm_data) {
  //     if (data.loc_perm_data.permissions) {
  //       let permissionData = data.loc_perm_data.permissions
  //       setPermissionList(permissionData)
  //       setOriginalPermList(cloneDeep(permissionData))
  //       let selectedPermListLocal = permissionData.filter((p) => p.selected);
  //       setSelectedPermissionList(selectedPermListLocal)
  //     }
  //     if (data.loc_perm_data.locations) {
  //       let locationData = data.loc_perm_data.locations
  //       const sorted = sortBy(locationData, 'business_name')
  //       setLocationList(sorted)
  //       setOriginalLocList(cloneDeep(sorted))
  //       let locListLocal = locationData.filter((l)=>l.selected);
  //       setSelectedLocationList(sortBy(locListLocal, 'business_name'))
  //     }
  //   }
  // }


  const handleDialogBoxCancel = () => {
    setUserData(null)
    setLocationList(null)
    setAllLocationsSelected(false)
    setPermissionList(null)
    setAllPermissionsSelected(false)
  }


  useEffect(() => {
    if (props && props.history.location.pathname === "/web/users/new") {
      setUserData({
        first_name: null,
        last_name: null,
        username: null,
        email: null,
        password: null,
        pin: null,
        is_active: null,
        new_password: null,
        old_password: null,
        allow_web_login: null,
        allow_device_login: null,
      })
      
        setCreateEnable(true);

    }
    else if (props.match && props.match.params) {
      if (props.match.params.id) {
        // getUserDetail(props.match.params.id);
        setCreateEnable(false);
      }
    }
    let locationsData = []
    let permissionsData = []
    getPermissions()
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          // console.log("JSON-data", jsonData)
          if (jsonData.permissions) {
            permissionsData = jsonData.permissions
            permissionsData = permissionsData.map((i: any) => {
              return {
                id: i.id,
                label: i.name
              }
            })
            setPermissionList(permissionsData)
          }
          if (jsonData.locations) {
            locationsData = cloneDeep(jsonData.locations)
            locationsData = sortBy(locationsData, 'business_name')
            locationsData = locationsData.map((i: any) => {
              return {
                id: i.id,
                label: `${i.business_name} - ${i.name}`
              }
            })
            setLocationList(locationsData)
            
          }
          if (props.match.params.id) {
            if (props.match.params.id !== 'new') {
              getCoreUserDetail(props.match.params.id)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then(data => {
              setLoading(false);
              initializeData(data)
              if (data.loc_perm_data) {
                if (data.loc_perm_data.permissions) {
                  let selPermissionData = data.loc_perm_data.permissions
                  selPermissionData = selPermissionData.map(p => {
                    return {
                      id: p.id,
                      label: p.name
                    }
                  })
                  setOriginalPermList(selPermissionData)
                  // setSelectedPermissionList(selPermissionData)
                  setupSelectedPermissions(permissionsData, selPermissionData)
                }
                if (data.loc_perm_data.locations) {
                  let selLocationData = data.loc_perm_data.locations
                  selLocationData = selLocationData.map(p => {
                    return {
                      id: p.id,
                      label: p.name
                    }
                  })
                  setOriginalLocList(cloneDeep(selLocationData))
                  // setSelectedLocationList(selLocationData)
                  setupSelectedLocations(locationsData, selLocationData)
                }
            }
            })
            .catch((error: any) => {
              setLoading(false);
              props.showToast({
                message: 'Failed to fetch user',
                options: ToastError
              });
            });

            }
            
          }
          // setProcessing(false);
        })
        .catch((error: any) => {
          // setProcessing(false);
          let msg = 'Failed to get permissions';
          props.showToast({ message: getErrorMsg(error, msg), options: ToastError });
        });
    getAuthGroups()
  }, [])

  const setupSelectedPermissions = (plist, splist) => {
    if (plist && plist.length > 0) {
      if (splist && splist.length > 0) {
        const newSelectedPermissionList = splist.map(spl => {
          return plist.find(p => spl.id === p.id)
        })
        setSelectedPermissionList(newSelectedPermissionList)
      }
    }
  }
  const setupSelectedLocations = (locations, selLocations) => {
    if (locations && locations.length > 0) {
      if (selLocations && selLocations.length > 0) {
        const newSelectedLocationList = selLocations.map(spl => {
          return locations.find(p => spl.id === p.id)
        })
        console.log("setting location list")
        setSelectedLocationList(newSelectedLocationList)
      }
    }
  }

  
  

  useEffect(() => {
    if (selectedAuthGroup) {
      const ag = authGroups.find((a) => a.id === selectedAuthGroup)
      
      if (ag) {
        const newSelectedPermissionList = [...selectedPermissionList]
        for (let p of ag.permissions) {
          const found = newSelectedPermissionList.find(nsp => nsp.id === p.id)
          if (!found) {
            const orig = permissionList.find(pl => pl.id === p.id)
            newSelectedPermissionList.push(orig)
          }
        }
        setSelectedPermissionList(newSelectedPermissionList)
      }
      setSelectedAuthGroup(null)
    }
  }, [selectedAuthGroup])

  const handleSave = () => {

    let payload = {}
    let permissionIds = []
    let locations = []

    if (userData.is_active === null || userInputDetailsError.username ||
      userInputDetailsError.email || userInputDetailsError.first_name
      || !userData.username || !userData.first_name || !userData.email
      || userInputDetailsError.last_name ||  
        userInputDetailsError.pin ||  userInputDetailsError.password) {
      userInputDetailsError.is_active = "Choose the required option";
      setShowRequiredFieldsError(true)
      setUserInputDetailsErrror({ ...userInputDetailsError, is_active: "Choose the required option" })
      props.showToast({ message: "Choose the required option", options: ToastError });
      return;
    }
    
    setLoading(true)


    if(selectedLocationList && selectedLocationList.length > 0){
      let selectedlocIds = selectedLocationList.map(item => item.id);
      payload['locations'] = selectedlocIds
    }

    if (selectedPermissionList && selectedPermissionList.length > 0) {
      let selectedPermIds = selectedPermissionList.map(item => item.id);
      payload['permissions'] = selectedPermIds
    }

    payload['business_id'] = props.userData.business_id ? props.userData.business_Id : props && props.userData && props.userData.selectedBusiness && props.userData.selectedBusiness.business_id

    payload['user_name'] = userData && userData.username ? userData.username : null
    payload['email'] = userData && userData.email ? userData.email : null
    payload['first_name'] = userData && userData.first_name ? userData.first_name : null
    payload['last_name'] = userData && userData.last_name ? userData.last_name : null
    payload['is_active'] = userData && userData.is_active !== null ? userData.is_active : null
    payload['allow_web_login'] = userData && userData.allow_web_login !== null ? userData.allow_web_login : null
    payload['allow_device_login'] = userData && userData.allow_device_login !== null ? userData.allow_device_login : null
    payload['pin'] = userData && userData.pin ? userData.pin : null
    if (isCreateEnable || isPasswordChanged) {
      payload['password'] = userData && userData.password ? userData.password : null
    }
    // console.log(userData.is_active)
    if (userData.auth_token) {
      payload['auth_token'] = userData.auth_token
    }
    if (userData.id) {
      payload['id'] = userData.id
    }

    // console.log("Payload--------", payload)

    saveKonnectUser(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        console.log("JSON-data", jsonData)
        if (isCreateEnable) {
          props.showToast({ message: 'User created successfully', options: ToastSuccess });
          props.history.push('/web/users')
        }
        else {
          props.showToast({ message: 'User updated successfully', options: ToastSuccess });
          props.history.push('/web/users')
        }
        setLoading(false)
        setLocationList(null);
        initializeData(jsonData)
        setLocationList(null);
        setPermissionList(null);

      })
      .catch((error: any) => {
        console.log("error", error)
        setLoading(false)
        if (error.status == 400) {
          if (error.data.hasOwnProperty('pin')) {
            setUserInputDetailsErrror({ ...userInputDetailsError, pin: error.data.pin })
            props.showToast({ message: getErrorMsg(error, "Duplicate pin"), options: ToastError });
            return;
          }
        }
        
        let msg = 'Failed to update user';
        props.showToast({ message: getErrorMsg(error, msg), options: ToastError });
      });
  }

  const changePasswordClick = () => {
    if (newPassword == oldPassword) {
      props.showToast({ message: "Password reset successfully", options: ToastSuccess })
      setIsPasswordValid(false)
      setOldPassword(null)
    }
    else {
      const password = newPassword
      let requestData = {
        auth_token: userData.auth_token,
        password: password
      }
      resetPassword(requestData)
        .catch(handleError)
        .then(checkStatus)
        .then(parseJSON)
        .then((data: any) => {
          props.showToast({ message: "Password reset successfully", options: ToastSuccess })
          setIsPasswordValid(false)
          setOldPassword(null)
        })
        .catch((error: any) => {
          props.showToast({ message: getErrorMsg(error, "Password could'n  reset!"), options: ToastSuccess })
        });

    }
  }

  const checkPasswordClick = () => {
    const password = oldPassword
    setCheckingPassword(true)
    console.log("UserData", userData)
    let payload = { auth_token: userData.auth_token, password: password }
    getIsPasswordValid(payload)
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setIsPasswordValid(data.status)
        setCheckingPassword(false)
        data.status == true ? setIncorrectAttempts(0) : setIncorrectAttempts(incorrectAttempts + 1)
        if (incorrectAttempts == 3) {
          setIsPasswordValid(true)
          setNewPassword(null)
        }
        else {
          data.status ? props.showToast({ message: "Correct password", options: ToastSuccess }) : props.showToast({ message: "Incorrect password You Have " + String(3 - Number(incorrectAttempts)) + " Attempts", options: ToastError })
        }
      })
      .catch((error: any) => {
        props.showToast({ message: getErrorMsg(error, "Password can't validate!"), options: ToastSuccess })
      });

  }

  const handleOldPasswordInput = (password: string) => {
    setOldPassword(password);
  };

  const handleNewPasswordInput = (password: string) => {
    if (password) {
      userData.password_exists = true;
      setUserInputDetailsErrror({ ...userInputDetailsError, ['new_password']: null });
    } else {
      if (userData.allow_web_login) {
        setUserInputDetailsErrror({ ...userInputDetailsError, ['new_password']: 'Password is required' });
      }
    }
    setNewPassword(password);
  };

  const handleUserInputDetailsChange = (e: any) => {
    let value = e.target.value;
    let key = e.target.name;
    if (key === 'pin') {
      let regex = /([0-9])+$/;
      if (value.match(regex) || value === "") {
        setUserData({ ...userData, [key]: value });
        if (userData.allow_device_login) {
          if (value === '') {
            setUserInputDetailsErrror({ ...userInputDetailsError, [key]: "Pin is required" });
          } else {
            setUserInputDetailsErrror({ ...userInputDetailsError, [key]: null });
          }
        } else {
          setUserInputDetailsErrror({ ...userInputDetailsError, [key]: null });
        }
      }
      else {
        setUserInputDetailsErrror({ ...userInputDetailsError, [key]: "Invalid Input: Only numeric inputs allowed here" });
      }
      return;
    }
    else if (key === "first_name" || key === "last_name") {
      let regex = /\S+\s*$/;
      if (value.match(regex)) {
        setUserInputDetailsErrror({ ...userInputDetailsError, [key]: null });
      }
      else {
        setUserInputDetailsErrror({ ...userInputDetailsError, [key]: "Invalid Format" });
      }
    }
    else if (key === "password") {
      let regex = /\S{8,256}$/;
      if (value.match(regex)) {
        if (userData.allow_web_login) {
          if (value === '') {
            setUserInputDetailsErrror({ ...userInputDetailsError, [key]: "Password is required" });
          } else {
            setIsPasswordChanged(true)
            setUserInputDetailsErrror({ ...userInputDetailsError, [key]: null });
          }
        } else {
          setIsPasswordChanged(false)
          setUserInputDetailsErrror({ ...userInputDetailsError, [key]: null });
        }
        setUserInputDetailsErrror({ ...userInputDetailsError, [key]: null });
      }
      else {
        setIsPasswordChanged(false)
        setUserInputDetailsErrror({ ...userInputDetailsError, [key]: "Invalid Format: length should greater than 8" });
      }
    }
    else if (key === "username") {
      let regex = /\S{1,256}$/;
      if (value.match(regex)) {
        setUserInputDetailsErrror({ ...userInputDetailsError, [key]: null });
      }
      else {
        setUserInputDetailsErrror({ ...userInputDetailsError, [key]: "Invalid Format" });
      }
    }
    else if (key === "email") {
      let regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
      if (value.match(regex)) {
        setUserInputDetailsErrror({ ...userInputDetailsError, [key]: null });
      }
      else {
        setUserInputDetailsErrror({ ...userInputDetailsError, [key]: "Invalid Format: " + value });
      }
    }
    setUserData({ ...userData, [key]: value });
  }


  const confirmBackNavigation = () => {
    // console.log("CAMEEEE")
    if (isDataEdited()) {
      setConfirmModalData({
        ...confirmModalData,
        isOpen: true,
        onCancel: () => setConfirmModalData({ ...confirmModalData, isOpen: false }),
        onConfirm: () => {
          setConfirmModalData({ ...confirmModalData, isOpen: false });
          navigateBack();
        }
      });
    } else {
      navigateBack();
    }
  };

  const isDataEdited = () => {
    if (isCreateEnable) {
      if (userData && (userData.is_active !== null) && userData.username && userData.email && userData.first_name && !userInputDetailsError.username && !userInputDetailsError.email && !userInputDetailsError.first_name) {
        return true;
      }
      return false;
    }
    let userEdited = !_isEqual(originalUserData, userData)
    let locEdited = !_isEqual(originalLocList, locationList);
    let permEdited = !_isEqual(originalPermList, permissionList);

    // console.log("userEdited, locEdited, permEdited", userEdited, locEdited, permEdited)

    if (userEdited || locEdited || permEdited) {
      return true
    } else return false
  };

  
  const handleValueChange = (val: any) => {

    if (val != '') {
      let filteredPermList = permissionList
      filteredPermList = filteredPermList.filter((l: any) => (l.name.toLowerCase().includes(val.toLowerCase()) ))
      setFilteredPermissionlist(filteredPermList)
    }
    else setFilteredPermissionlist(null)
  }

  const handleLocationsChangeNew = (locId: any) => {
    setSelectedLocationList((prevChecked) =>
    prevChecked.includes(locId)
      ? prevChecked.filter((itemId) => itemId !== locId)
      : [...prevChecked, locId])
  }



  const handlePermissionsChange = (permId: any) => {
    setSelectedPermissionList((prevChecked) =>
    prevChecked.includes(permId)
      ? prevChecked.filter((itemId) => itemId !== permId)
      : [...prevChecked, permId])
  }




  

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleValueChange(permissionSearchTerm);
    }
  };

  const handleSearchPerm = (e: any) => {
    const val = e.target ? (e.target.value ? e.target.value.trim() : '') : '';
    setPermissionSearchTerm(val)
  }


  const handleClickPermClear = (e: any) => {
    setPermissionSearchTerm(null)
    setFilteredPermissionlist(null)
    // setPermissionList(cloneDeep(permissionList))
    setAllPermissionsSelected(false)
  }


  


  const handleUserSettingsChange = (e: any) => {
    const key = e.target.name
    const value = e.target.checked
    setUserData({ ...userData, [key]: value });
    if (key === 'allow_device_login') {
      if (value) {
        if (!userData['pin'] || userData['pin'] === '') {
          setUserInputDetailsErrror({ ...userInputDetailsError, ['pin']: "Pin is required" });
        } else {
          setUserInputDetailsErrror({ ...userInputDetailsError, ['pin']: null });
        }
      } else {
        setUserInputDetailsErrror({ ...userInputDetailsError, ['pin']: null });
      }
      
    }
    
    if (key === 'allow_web_login') {
      if (value) {
        if (!userData.password_exists) {
          setUserInputDetailsErrror({ ...userInputDetailsError, ['password']: "Password is required" });
        } else {
          setUserInputDetailsErrror({ ...userInputDetailsError, ['password']: null });
        }
      } else {
        setUserInputDetailsErrror({ ...userInputDetailsError, ['password']: null });
      }
      
    }
    
    setShowRequiredFieldsError(false);
    // console.log(userData.is_active, e.target.value)
  }

  const handleValueChangeLocation = (val: any) => {
    if (val != '') {
      let filteredLocList = locationList;
      filteredLocList = filteredLocList.filter((l: any) => l.name.toLowerCase().includes(val.toLowerCase()) || l.business_name.toLowerCase().includes(val.toLowerCase()))
      // console.log("filtered-list", filteredLocList)
      setFilteredLocationlist([...filteredLocList])
      // if(filteredLocList.length == selectedLocationList.legth){
      //   setAllLocationsSelected(true);
      // }
    }
    else setFilteredLocationlist(null)
  }

  const handleLocationsChange = (event: any, locId: any) => {
    if (event && event.target) {
      // const checkedId = event.target.value.find(id => typeof id === 'number');
      let updatedLocationList = filteredLocationList ? filteredLocationList : locationList
      updatedLocationList.forEach((l) => {
        if (l.id === locId) {
          l.selected = !l.selected;
        }
      });
      const selectedLocationsLocal = updatedLocationList.filter((l) => l.selected);
      setSelectedLocationList(selectedLocationsLocal);
      if (filteredLocationList) {
        setFilteredLocationlist(updatedLocationList);
        locationList.forEach((l) => {
          if (l.id === locId) {
            l.selected = !l.selected;
          }
        });
        setLocationList(locationList);
      }
      else {
        setLocationList(updatedLocationList);
      }
      if (!event.target.checked) {
        setAllLocationsSelected(false);
      }
      else if (updatedLocationList.length === selectedLocationsLocal.length) {
        setAllLocationsSelected(true);
      }
    }
  };

  


  const onKeyDownLocation = (e: any) => {
    if (e.keyCode === 13) {
      // console.log("Location key down")
      const val = locationSearchTerm ? locationSearchTerm : '';
      handleValueChangeLocation(val);
    }
  };

  const handleSearchLocation = (e: any) => {
    const val = e.target ? (e.target.value ? e.target.value.trim() : '') : '';
    setLocationSearchTerm(val)
    handleValueChangeLocation(val);
  }


  const handleClickLocationClear = (e: any) => {
    setLocationSearchTerm(null)
    setFilteredLocationlist(null)
    // setLocationList(cloneDeep(locationList))
    setAllLocationsSelected(false)
  }

  const handleSelectAllChangeLocation = (e: any, allornone: string) => {
    let locList = filteredLocationList ? filteredLocationList : locationList
    locList = locList.map(p => p.id)
    if (allornone === 'all') {
      setSelectedLocationList((prevChecked) => {
        return [...prevChecked, ...locList.filter(l => !prevChecked.includes(l))]
      });
    }
    if (allornone === 'none') {
      setSelectedLocationList((prevChecked) => {
        return [...prevChecked.filter(l => !locList.includes(l))]
      });
    }
    
  }

  const handleSelectAllChangePermission = (e: any, allornone: string) => {
    let plist = filteredPermissionList ? filteredPermissionList : permissionList
    plist = plist.map(p => p.id)
    if (allornone === 'all') {
      setSelectedPermissionList((prevChecked) => {
        return [...prevChecked, ...plist.filter(p => !prevChecked.includes(p))]
      });
    }
    if (allornone === 'none') {
      setSelectedPermissionList((prevChecked) => {
        return [...prevChecked.filter(p => !plist.includes(p))]
      });
    }
  }

  // const handleSelectAllChangeLocation = (e: any) => {
  //   setAllLocationsSelected(e.target.checked)
  //   let locList = filteredLocationList ? filteredLocationList : locationList

  //   let updatedLocationList = locList
  //   let allLocList = locationList
  //   updatedLocationList.forEach((l) => {
  //     l.selected = e.target.checked;
  //     allLocList.forEach((aloc: any) => {
  //       if (aloc.id === l.id) {
  //         aloc.selected = e.target.checked;
  //       }
  //     })
  //   });
  //   let selected = allLocList.filter(l => l.selected)
  //   setSelectedLocationList(selected);
  //   console.log("ALl-Locs", allLocList)
  //   setLocationList([...allLocList]);
  //   setFilteredLocationlist([...updatedLocationList]);
  // }
 


  interface RenderLocationListProps {
    setSelectedLocationList: (val: any) => void,
    setLocationList: (val: any) => void,
    setFilteredLocationlist: (val: any) => void,
    locationList: any,
    filteredLocationList: any,
    selectedLocationList: any,
    classes: any
  }

  const renderLocationList = () => {
      return (
        <>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', }}>
              <InputLabel
                id="loc-label"
                style={{ color: GlobalStyles.primaryColor, alignSelf: 'center' }}
              >
                {`Location(s) selected - ${selectedLocationList.length}`}
              </InputLabel>
              {
                locationsOnDisplay.length === selectedLocationList.length &&
                <FormControlLabel style={{ alignSelf: 'center' }}
                checked={locationsOnDisplay.length == selectedLocationList.length} 
                onChange={(e) => handleSelectAllChangeLocation(e, 'none')} control={<Checkbox />} label={`Select None`} />
              }
              {
                locationsOnDisplay.length !== selectedLocationList.length &&
                <FormControlLabel style={{ alignSelf: 'center' }}
                checked={locationsOnDisplay.length == selectedLocationList.length} 
                onChange={(e) => handleSelectAllChangeLocation(e, 'all')} control={<Checkbox />} label={`Select all`} />
              }
              
            </div>
            <div style={{ alignSelf: 'flex-end' }}>
              <TextField
                label="Search Location"
                variant="outlined"
                onChange={handleSearchLocation}
                value={locationSearchTerm ? locationSearchTerm : ''}
                onKeyDown={onKeyDownLocation}
                size={'small'}

                InputProps={{
                  endAdornment: <InputAdornment position="end">{<IconButton
                    onClick={handleClickLocationClear}
                    onMouseDown={handleClickLocationClear}
                  >
                    <ClearIcon />
                  </IconButton>}</InputAdornment>,
                }}
              />
            </div>
          </div>
          <Paper style={{ maxHeight: 200, overflow: 'auto', marginTop: 15, marginBottom: 20 }}>
            <List className={classes.root}>
              {locationsOnDisplay && locationsOnDisplay.map((loc: any) => {
                return (
                  <PermissionListItem key={loc.id} item={loc} checked={selectedLocationList.includes(loc.id)} onToggle={handleLocationsChangeNew} />
                  // <ListItem key={loc.id.toString()} role={undefined} dense button onClick={(e) => handleLocationsChange(e, loc.id)}>
                  //   <ListItemIcon>
                  //     <Checkbox
                  //       edge="start"
                  //       checked={loc.selected}
                  //       tabIndex={-1}
                  //       disableRipple
                  //     />
                  //   </ListItemIcon>
                  //   <ListItemText id={loc.id.toString()} primary={`${loc.business_name} - ${loc.name}`} />
                  // </ListItem>
                );
              })}
            </List>
          </Paper>
        </>)
    }

  const showUserActiveSettings = () => {

    return (<div style={{ margin: 20, display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>

       
       
       
      </div>)
  }


  const renderPermissionList = () => {
      return (
        <>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', }}>
              < InputLabel
                id="perm-label"
                style={{ color: GlobalStyles.primaryColor, alignSelf: 'center' }}
              >
                {`Permission(s) selected - ${selectedPermissionList.length}`}

              </InputLabel>
              {
                permissionsOnDisplay.length == selectedPermissionList.length &&
                <FormControlLabel style={{ alignSelf: 'center' }}
                checked={permissionsOnDisplay.length == selectedPermissionList.length} 
                onChange={(e) => handleSelectAllChangePermission(e, 'none')} control={<Checkbox />} label={`Select None`} />
              }
              {
                permissionsOnDisplay.length != selectedPermissionList.length &&
                <FormControlLabel style={{ alignSelf: 'center' }}
                checked={permissionsOnDisplay.length == selectedPermissionList.length} 
                onChange={(e) => handleSelectAllChangePermission(e, 'all')} control={<Checkbox />} label={`Select all`} />
              }
            </div>
            <div style={{ alignSelf: 'flex-end' }}>
              <TextField
                label="Search Permission"
                variant="outlined"
                onChange={handleSearchPerm}
                value={permissionSearchTerm ? permissionSearchTerm : ''}
                onKeyDown={onKeyDown}
                size={'small'}

                InputProps={{
                  endAdornment: <InputAdornment position="end">{<IconButton
                    onClick={handleClickPermClear}
                    onMouseDown={handleClickPermClear}
                  >
                    <ClearIcon />
                  </IconButton>}</InputAdornment>,
                }}
              />
            </div>
          </div>
          <Paper style={{ maxHeight: 200, overflow: 'auto', marginTop: 15, marginBottom: 20 }}>
            <List className={classes.root}>
              {permissionsOnDisplay && permissionsOnDisplay.map((p: any) => {
                return (
                  // <ListItem key={p.id.toString()} role={undefined} dense button onClick={(e) => handlePermissionsChange(e, p.id)}>
                  //   <ListItemIcon>
                  //     <Checkbox
                  //       edge="start"
                  //       checked={p.selected}
                  //       tabIndex={-1}
                  //       disableRipple
                  //     />
                  //   </ListItemIcon>
                  //   <ListItemText id={p.id.toString()} primary={p.name} />
                  // </ListItem>
                  <PermissionListItem key={p.id} item={p} checked={selectedPermissionList.includes(p.id)} onToggle={handlePermissionsChange} />
                );
              })}
            </List>
          </Paper>
        </>)
  }




  const renderUserDetails = () => {
   // if (isDone && permissionList && locationList && userData) {
      return (
        <>
        <div style={{display: 'flex', gap: '1rem', width: '100%',  alignItems: 'baseline', justifyContent: 'flex-start'}}>
        <FormLabel component="legend">Active:</FormLabel>
          <FormControl component="fieldset" className={classes.formControl} error={showRequiredFieldsError ? !userData.is_active ? true : false : false} >
          <Switch
            name="is_active"
            checked={userData.is_active}
            onClick={handleUserSettingsChange}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          {
            showRequiredFieldsError && !userData.is_active ?
              <FormHelperText>{'Select an option.'}</FormHelperText> : null
          }
        </FormControl>
         </div> 
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%'}} >
        
           <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <TextField
                id="FirstName"
                autoFocus
                name="first_name"
                margin="dense"
                size="small"
                variant="outlined"
                value={userData.first_name || ""}
                onChange={handleUserInputDetailsChange}
                label="First Name"
                type="text"
                style={{ width: "90%" }}
                required
                error={userInputDetailsError.first_name ? true : false}
                helperText={userInputDetailsError.first_name || null}
                inputProps={{ tabIndex: 1 }}
              />

              <TextField
                id="userName"
                margin="dense"
                name="username"
                size="small"
                variant="outlined"
                required
                value={userData.username || ''}
                onChange={handleUserInputDetailsChange}
                label="Username"
                type="text"
                style={{ width: "90%" }}
                error={userInputDetailsError.username ? true : false}
                helperText={userInputDetailsError.username || null}
                inputProps={{ tabIndex: 3 }}
              />
              <div style={{display: 'flex', gap: '1rem', alignItems: 'center', width: "90%"}}>
                <div style={{display: 'flex', gap: '1rem',flex: 4, alignItems: 'center'}}>
                  <FormLabel style={{flex: 5}} component="legend">Allow Device Login:</FormLabel>
                  <FormControl component="fieldset" className={classes.formControl} error={showRequiredFieldsError ? !userData.allow_device_login ? true : false : false} >
                    <Switch
                      name='allow_device_login'
                      checked={userData.allow_device_login}
                      onClick={handleUserSettingsChange}
                      inputProps={{ 'aria-label': 'secondary checkbox', tabIndex: 5 }}
                    />
                    {
                      showRequiredFieldsError && !userData.is_active ?
                        <FormHelperText>{'Select an option.'}</FormHelperText> : null
                    }
                  </FormControl>
                </div>
        <div style={{display: 'flex', flex: 2}}>
        <TextField
                id="pin"
                margin="dense"
                size="small"
                variant="outlined"
                name="pin"
                value={userData.pin || ""}
                // value={rowData ? rowData.pin : userInputDetails && userInputDetails.pin != null ? userInputDetails.pin : ''}
                onChange={handleUserInputDetailsChange}
                label="PIN"
                // type="number"
                error={userInputDetailsError.pin ? true : false}
                helperText={userInputDetailsError.pin || null}
                style={{ width: "100%", marginBottom: 20 }}
                inputProps={{ tabIndex: 6 }}
              />
        </div>
        </div>
              
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <TextField
                id="LastName"
                margin="dense"
                size="small"
                name="last_name"
                value={userData.last_name || ""}
                // value={rowData ? rowData.last_name : userData && userData.lastName != null ? userData.lastName : ''}
                onChange={handleUserInputDetailsChange}
                label="Last Name"
                type="text"
                variant="outlined"
                style={{ width: "90%" }}
                error={userInputDetailsError.last_name ? true : false}
                helperText={userInputDetailsError.last_name || null}
                inputProps={{ tabIndex: 2 }}
              />


              <TextField
                id="Email"
                margin="dense"
                size="small"
                name="email"
                variant="outlined"
                value={userData.email || ""}
                // value={rowData ? rowData.email : userData && userData.email != null ? userData.email : ''}
                onChange={handleUserInputDetailsChange}
                label="Email"
                type="text"
                style={{ width: "90%" }}
                required
                error={userInputDetailsError.email ? true : false}
                helperText={userInputDetailsError.email || null}
                inputProps={{ tabIndex: 4 }}
              />

              
              <div style={{display: 'flex', gap: '1rem', alignItems: 'center', width: "90%"}}>
              <div style={{display: 'flex', gap: '1rem', alignItems: 'baseline', flex: 4}}>
        <FormLabel component="legend">Allow Web Login:</FormLabel>
        <FormControl component="fieldset" className={classes.formControl} error={showRequiredFieldsError ? !userData.allow_web_login ? true : false : false} >
          <Switch
            name='allow_web_login'
            checked={userData.allow_web_login}
            onClick={handleUserSettingsChange}
            inputProps={{ 'aria-label': 'secondary checkbox', tabIndex: 7 }}
          />
          {
            showRequiredFieldsError && !userData.allow_web_login ?
              <FormHelperText>{'Select an option.'}</FormHelperText> : null
          }
        </FormControl>
        </div>
        <div style={{flex: 2}}>
        {
              
                origPasswordExists === false ?
                (
                  <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                    <TextField
                      id="Password"
                      name="password"
                      margin="dense"
                      size="small"
                      variant="outlined"
                      label="Password"
                      value={userData.password || ""}
                      onChange={handleUserInputDetailsChange}
                      style={{ width: "100%" }}
                      error={userInputDetailsError.password ? true : false}
                      helperText={userInputDetailsError.password || null}
                      inputProps={{ tabIndex: 8 }}
                    />
                  </div>
                ) :
                <TextField
                    id="Password"
                    margin="dense"
                    size="small"
                    variant="outlined"
                    name="password"
                    value={userData.password || ""}
                    // value={rowData ? rowData.password : userInputDetails && userInputDetails.password != null ? userInputDetails.password : ''}
                    onChange={handleUserInputDetailsChange}
                    label="Password"
                    style={{ width: "100%" }}
                    error={userInputDetailsError.password ? true : false}
                    helperText={userInputDetailsError.password || null}
                    inputProps={{ tabIndex: 8 }}
                  />
                  
              }
              </div>
              </div>
            </div>        
            </div>
        </>
      )
  }



  const navigateBack = () => {
    props.history.goBack();
  };


  return (
    <div style={{ maxWidth: '100%' }}>
          <PageToolbar
            backButtonOnClick={confirmBackNavigation}
            showBackButton={true}
            title={isCreateEnable ? 'Create user' : `Update user`}
            history={props.history}
            titleColor={GlobalStyles.primaryColor}
            actions={
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <div style={{ alignSelf: 'center', marginTop: 8 }}>
                  <Button variant="contained" startIcon={<SaveIcon />} color={'primary'} disabled={!isDataEdited()} onClick={handleSave}>Save</Button>
                </div>
              </div>
            }
          />
          {userData && isLoading && <div className={classes.rowCenter}>
       <CircularProgress />
     </div>}
      {userData && locationList && permissionList ?
         <div style={{ opacity: isLoading ? '0.5' : '1' }}>
         {renderUserDetails()}
         {/* {renderLocationList()} */}
         <TransferList title={"Locations"} title2={"Selected Locations"} choices={locationList} chosen={selectedLocationList} onSelectedChange={(litems) => {
          setSelectedLocationList(litems)
         }} />
         {/* {renderPermissionList()} */}
         <div style={{display: 'flex', alignItems: 'center'}}>
          <div>Copy Permissions from group</div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Group</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedAuthGroup}
              onChange={(e) => {
                setSelectedAuthGroup(e.target.value)
              }}
              label="Group"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {
                authGroups && authGroups.map(ag => {
                  return (
                    <MenuItem value={ag.id}>{ag.name}</MenuItem>
                  )
                })
              }
              
            </Select>
          </FormControl>
         </div>
         <TransferList title={"Permissions"} title2={"Granted Permissions"} choices={permissionList} chosen={selectedPermissionList} onSelectedChange={(sitems) => {
          setSelectedPermissionList(sitems)
         }} />
         {showUserActiveSettings()}
         
         </div>
       : <div className={classes.rowCenter}>
       <CircularProgress />
     </div>}
     <ConfirmModal
         isOpen={confirmModalData.isOpen}
         onCancel={confirmModalData.onCancel}
         onConfirm={confirmModalData.onConfirm}
         confirmBtnText={confirmModalData.confirmBtnText}
         dialogTitle={confirmModalData.title}
         dialogMessage1={confirmModalData.message1}
         dialogMessage1Color={GlobalStyles.red}
         dialogMessage2={confirmModalData.message2}
       />
      </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    businessList: state.sharedModuleData.businessList,
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBusinessList: () => dispatch(getBusinessListFromAPI()),
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailWebScreen);
