import React, { Profiler, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, IconButton, InputAdornment,  } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TransferListItem from './TransferListItem';
import TransferCustomList from './TransferCustomList';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: '100%',
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export interface TransferListItem {
    id: number | string;
    label: string;
}
interface TransferListProps {
    choices: TransferListItem[];
    chosen: TransferListItem[];
    onSelectedChange: (items: TransferListItem[]) => void
    title: string;
    title2: string;
}

const TransferList = React.memo((props: TransferListProps) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const {choices, chosen, onSelectedChange, title, title2} = props;
  const [left, setLeft] = React.useState(choices);
  const [right, setRight] = React.useState(chosen);
  const [leftFiltered, setLeftFiltered] = React.useState(null);
  const [rightFiltered, setRightFiltered] = React.useState(null);
  const [leftSearchTerm, setLeftSearchTerm] = React.useState('');
  const [rightSearchTerm, setRightSearchTerm] = React.useState('');

  useEffect(() => {
    if (chosen && chosen.length > 0) {
      const leftChoices = choices.filter(c => {
        return !chosen.find(ch => ch.id === c.id)
      })
      setLeft(leftChoices)
      setRight(chosen)
    } else {
      setLeft(choices)
      setRight(chosen)
    }
  }, [choices, chosen])
  

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const leftOnDisplay = leftFiltered ? leftFiltered : left;
  const rightOnDisplay = rightFiltered ? rightFiltered : right;
  // const leftOnDisplay = leftFiltered ? leftFiltered : choices.slice(0, 20);
  // const rightOnDisplay = rightFiltered ? rightFiltered : chosen.slice(0, 20);

  const handleToggle = useCallback((e, item) => {
    
    setChecked((prevChecked) => {
      const start = Date.now();
      const value = item;
      const currentIndex = prevChecked.indexOf(value);
      const newChecked = [...prevChecked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      const end = Date.now();
      console.log(`Execution time: ${end - start} ms`);
      return newChecked
    });
    
  }, [checked]);

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const a = right.concat(leftChecked)
    setRight(a);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    onSelectedChange(a)
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    const a = not(right, rightChecked);
    setRight(a);
    setChecked(not(checked, rightChecked));
    onSelectedChange(a)
  };

  const customList = (title, items, allItems, setFiltered, searchTerm, setSearchTerm, handleClickPermClear) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${allItems.length} selected (${items.length} displayed)`}
      />
      <div className={classes.cardHeader} >
      <TextField
                label="Search "
                variant="outlined"
                onChange={(e) => {
                  const val = e.target.value;
                  setSearchTerm(val)
                  if (val !== '') {
                    const filteredItems = allItems.filter((item) => item.label.includes(val))
                    setFiltered(filteredItems)
                  } else {
                    setFiltered(null)
                  }
                }}
                value={searchTerm}
                size={'small'}

                InputProps={{
                  endAdornment: <InputAdornment position="end">{<IconButton
                    onClick={handleClickPermClear}
                  >
                    <ClearIcon />
                  </IconButton>}</InputAdornment>,
                }}
              />
      </div>
      <Divider />
      {/* <List className={classes.list} dense component="div" role="list">
        {items.map((item) => {
          

          return (
            <TransferListItem key={item.id} item={item} checked={checked.indexOf(item) !== -1} onToggle={handleToggle} />
          );
        })}
        <ListItem />
      </List> */}
      <TransferCustomList items={items} checked={checked} handleToggle={handleToggle} />
    </Card>
  );

  const handleLeftClear = () => {
    setLeftFiltered(null)
    setLeftSearchTerm('')
  }
  const handleRightClear = () => {
    setRightFiltered(null)
    setRightSearchTerm('')
  }

  const profilerCallback = (id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions // the Set of interactions belonging to this update
  ) => {
    console.log("actualDuration", actualDuration)
  }

  return (
    <Profiler id="Navigation" onRender={profilerCallback}>
    <Grid
      container
      spacing={2}
    //   justifyContent={"center"}
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList(title, leftOnDisplay, left, setLeftFiltered, leftSearchTerm, setLeftSearchTerm, handleLeftClear)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(`${title2}`, rightOnDisplay, right, setRightFiltered, rightSearchTerm, setRightSearchTerm, handleRightClear)}</Grid>
    </Grid>
    </Profiler>
  );
})

export default TransferList;
