import React from 'react';
import {
  TextField, Checkbox, List, ListItem, ListItemIcon,
  FormControl, InputLabel, CircularProgress, Button, Paper, FormLabel, RadioGroup,
  Radio, FormGroup, FormControlLabel, IconButton, InputAdornment, FormHelperText, Switch
} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';

const PermissionListItem = React.memo((props: any) => {
  let { item, checked, onToggle } = props;
    return (
      <>
        <ListItem key={item.id.toString()} role={undefined} dense button onClick={() => onToggle(item.id)} >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText id={item.id.toString()} primary={item.name} />
                  </ListItem>
      </>
    )
}, (prevProps, nextProps) => {
  // Only re-render if the checked state or item text changes
  return prevProps.checked === nextProps.checked && prevProps.item.name === nextProps.item.name;
})

export default PermissionListItem