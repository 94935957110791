import { API_CONFIG } from "../../shared/api/config";
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

const parseJSON = (response: any) => {
  if (response.status === 200) {
    return response.json();
  }
  
}

export const API_URLS = {
  getCustomerOrderSummaryUrl: BASE_URL + 'customer_order/orders/summary/',
  getCustomerOrdersUrl: BASE_URL + 'customer_order/orders/',
  toggleCustomerOrderFinalizeUrl: BASE_URL + 'customer_order/orders/',
  getCustomerOrdersForStatusUrl: BASE_URL + 'customer_order/orders/for_status/',
  getOrderPickGroupsUrl: BASE_URL + 'customer_order/order-pick-groups/',
  getAllUsersUrl: BASE_URL + 'customer_order/order-pick-group/users/',
  getOrderPickGroupUsersUrl: BASE_URL + 'customer_order/order-pick-groups/',
  getCommodityGroupsUrl: BASE_URL + 'korona/commodity-groups/',
  getLocationsUrl: BASE_URL + 'core/locations/',
};



export const getCustomerOrdersSummary = (
  business_id: number | string
) => {
  let URL = API_URLS.getCustomerOrderSummaryUrl +
    `?business_id=${business_id}`;

  return composeRequest(URL, 'GET', null).then(parseJSON);
};


export const getCustomerOrdersForStatus = (
  business_id: number | string,
  status: string
) => {
  let URL = API_URLS.getCustomerOrdersForStatusUrl +
    `?business_id=${business_id}&status=${status}`;

  return composeRequest(URL, 'GET', null).then(parseJSON);
};

export const toggleCustomerOrderFinalize = (
  order_id: number | string,
  business_id: number | string,
  location_id: number | string,
) => {
  let URL =  `${API_URLS.toggleCustomerOrderFinalizeUrl}${order_id}/toggle_finalize/?business_id=${business_id}&location_id=${location_id}`;

  return composeRequest(URL, 'POST', null);
};

export const getCustomerOrderPickGroups = (
  business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
) => {
  let URL = API_URLS.getOrderPickGroupsUrl +
    `?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (searchTerm) {
      URL = `${URL}&search=${searchTerm}`;
  }
  if(orderBy){
      URL = `${URL}&orderBy=${orderBy}`;
  }

  return composeRequest(URL, 'GET', null);
};

export const getCustomerOrders = (
  business_id: number | string,
  location_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
) => {
  let URL = API_URLS.getCustomerOrdersUrl +
    `?business_id=${business_id}&location_id=${location_id}&page=${page}&page_size=${pageSize}`;
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (searchTerm) {
      URL = `${URL}&search=${searchTerm}`;
  }
  if(orderBy){
      URL = `${URL}&orderBy=${orderBy}`;
  }

  return composeRequest(URL, 'GET', null);
};

export const saveCustomerOrderPickGroup = (
  data: any,
  orderPickGroupId?: any
) => {
  let method = 'POST';
  let URL = API_URLS.getOrderPickGroupsUrl;
  if (orderPickGroupId) {
    URL = `${URL}${orderPickGroupId}/`;
    method = 'PATCH';
  }
  return composeRequest(URL, method, JSON.stringify(data));
};

export const getCommodityGroups = (
  business_id: number | string
) => {
  let URL = API_URLS.getCommodityGroupsUrl +
    `?business_id=${business_id}`;

  return composeRequest(URL, 'GET', null);
};
export const getLocations = (
  business_id: number | string
) => {
  let URL = API_URLS.getLocationsUrl +
    `?business_id=${business_id}`;

  return composeRequest(URL, 'GET', null);
};
export const getAllUsers = (
  business_id: number | string
) => {
  let URL = API_URLS.getAllUsersUrl +
    `?business_id=${business_id}`;

  return composeRequest(URL, 'GET', null);
};

export const getCustomerOrderPickGroupUsers = (
  business_id: any,
  order_pick_group_id: number | string,
  page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
) => {
  let URL = `${API_URLS.getOrderPickGroupUsersUrl}${order_pick_group_id}/users/?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
  if (searchTerm) {
      URL = `${URL}&search=${searchTerm}`;
  }
  if(orderBy){
      URL = `${URL}&orderBy=${orderBy}`;
  }

  return composeRequest(URL, 'GET', null);
};

