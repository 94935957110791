import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { ProduceCode } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { API_URLS, getApnaBazaarHomeneedsDataMismatch } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar, } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { getHNSuppUpdateSummary } from '../../api';

import { Filter, FilterType } from '../../../shared/models/Filter';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import MaterialTable, { MTableToolbar } from 'material-table';
import { getMasterCompaniesFullList } from '../../../master-data/api'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },

    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.primaryColor,
      },
      '&:after': {
        borderColor: GlobalStyles.primaryColor,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);


const ApnaBazaarHomeneedsDataMismatch = (props: any) => {
  const classes = useStyles({});
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);

  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      getApnaBazaarHomeneedsDataMismatch(
        sb.business_id,
        null,
        null,
        tableQuery.search,
        sb.orderBy,
        shouldExport
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, 'ap_hn_data_mismatch.csv', 'text/csv');
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
    }
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };


  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <ExportStatusBar isVisible={isExporting} />

      <div style={{ maxWidth: '100%' }}>
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              // {
              //   title: 'AB_Num',
              //   field: 'AB_Num',
              //   render: rowData => {
              //     if (rowData && rowData.AB_Num) {
              //       return (
              //         <div style={{ minWidth: 200 }}>
              //           {rowData.AB_Num}
              //         </div>
              //       )

              //     }
              //     return null
              //   },

              // },
              { title: 'AB Num', field: 'AB_Num' },
              { title: 'AB Name', field: 'AB_Name' },
              { title: 'AB HN RoC', field: 'AB_HN_RoC' },
              { title: 'AB MP Id', field: 'AB_MPID' },
              { title: 'AB MP Name', field: 'AB_MPName' },
              { title: 'HN Num', field: 'HN_Num' },
              { title: 'HN Name', field: 'HN_Name' },
              { title: 'HN MP Id', field: 'HN_MPID' },
              { title: 'HN MP Name', field: 'HN_MPName' },
              { title: 'RoC Match', field: 'RoC_Match' },
              { title: 'MP Id Match', field: 'MPID_Match' },
              { title: 'Code Match MP Id', field: 'CodeMatch_MPID' },
              { title: 'Code Match MP Name', field: 'CodeMatch_MPName' },

            ]}
            data={query =>
              new Promise((resolve, reject) => {
                replaceHistory(query, props.history);
                let pageData = {
                  data: [] as any[],
                  totalCount: 0,
                  page: query.page
                };
                let orderBy = '';
                if (query.orderBy) {
                  orderBy = query.orderBy.field as string;
                  orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                }
                setLoading(true);
                const sb = props.userData.selectedBusiness;

                getApnaBazaarHomeneedsDataMismatch(
                  sb.business_id,
                  query.page + 1,
                  query.pageSize,
                  query.search,
                  orderBy

                )
                  .catch(handleError) // handle network issues
                  .then(checkStatus)
                  .then(parseJSON)
                  .then((data: PaginatedResponse<any>) => {
                    setLoading(false);
                    setErrorStatusVisible(false);
                    setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                    pageData = {
                      data: data.results,
                      totalCount: data.count,
                      page: query.page
                    };
                    setTableQuery({
                      ...tableQuery,
                      orderBy,
                      totalCount: data.count,
                      page: query.page,
                      pageSize: query.pageSize
                    });
                    resolve(pageData);
                  })
                  .catch((error: any) => {
                    if (error) {
                      if (error.status && error.status === 500) {
                        setEmptyDataSourceMessage(ERROR_500);
                        setErrorStatusVisible(false);
                      } else if ((error.status === 403 || error.status === 401)
                      ) {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(true);
                      } else {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(false);
                      }
                    }
                    setTableQuery({
                      ...tableQuery,
                      totalCount: 0,
                      page: query.page,
                      pageSize: query.pageSize
                    });
                    setLoading(false);

                    resolve(pageData);
                  });
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Apna Bazaar Homeneeds Data Mismatch'}
                icon={SVG_ICONS.product} />
            }
            actions={[
              {
                icon: 'save_alt',
                tooltip: 'Export',
                isFreeAction: true,
                onClick: handleExportClick,
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
            ]}
          />
        </div>
      </div>
      <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(ApnaBazaarHomeneedsDataMismatch);
