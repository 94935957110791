import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography, Snackbar, FormControl, InputLabel, IconButton, CircularProgress, Select, MenuItem,
  RadioGroup, FormGroup, FormControlLabel, Radio, FormLabel, Tooltip, Button, Dialog, DialogActions,
  DialogContent, DialogTitle, TextField, Divider,Switch
} from '@material-ui/core';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Toast, enqueueSnackbar, ToastError } from '../../../shared/components/Notifier';
import { createNewCompanyProduct, getCompanyProductAttributeTypes, getMasterProductsByPage, getSupplierCompanies } from '../../api';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { PaginatedResponse, ToastType } from '../../../shared/models';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import cloneDeep from 'lodash/cloneDeep';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    mainList: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.lightBlue,
      },
      '&:after': {
        borderColor: GlobalStyles.lightBlue,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },

    formControl: {
      margin: theme.spacing(2),
    },
    formContent: {
      display: 'flex',
      flexDirection: 'row'
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

//@ts-nocheck
const CompanyProductInstanceModal = (props: any) => {
  const classes = useStyles({});
  const [data, setData] = useState(null)
  const [selectedMasterCompany, setSelectedMasterCompany] = useState(null);
  const [selectedMasterProduct, setSelectedMasterProduct] = useState(null);
  const [selectedNewMasterProduct, setSelectedNewMasterProduct] = useState(null);
  const [invalidQty, setInvalidQty] = useState(false)
  const [attributeTypes, setAttributeTypes] = useState(null)
  const [attributesData, setAttributesData] = useState(null)
  const [inCompleteDetails, setInCompleteDetails] = useState(false)
  const unmappedCompanyTableRef = useRef<{ onQueryChange: any; }>();
  const masterProductTableRef = useRef<{ onQueryChange: any; }>();
  const [newRecordCreated, setnewRecordCreated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [toast, setToast] = useState({ open: false, message: '', variant: 'success' } as ToastType);


  const {
    onClose, isOpen, onSave,
    handleCloseToast, instanceData, handlResetToast,
    masterProduct,
    userDetails
  } = props


  useEffect(() => {
    if(isOpen){

    getCompanyProductAttributeTypes()
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        // console.log("results",data)
        setAttributeTypes(data.attributes_types);
        let attrData = []
        let attrObj = {}
        attrObj['id'] = 1
        attrObj['type'] = null
        attrObj['value'] = null
        attrObj['quantity'] = null
        attrObj['primary'] = false
        attrData.push(attrObj)
        setAttributesData(cloneDeep(attrData))
      })
      .catch((error: any) => {
      });
    }

  }, [isOpen]);

  useEffect(() => {
    if (masterProduct) {
      setSelectedMasterProduct({ ...masterProduct });
      setSelectedNewMasterProduct({ ...masterProduct });

    }
    console.log("masterProduct", masterProduct)
  }, [props.masterProduct]);

  const handleSaveCompanyInstance = (payload: any)=>{
    console.log("HANDLE SAVE IN LIST VIEW")
    console.log(payload)
    setnewRecordCreated(true)
    setIsLoading(true)
    payload['user_details'] = userDetails;
    saveRecords(payload, 'save')

  }

  const saveRecords = (payload: any, mode: any)=>{
    createNewCompanyProduct(payload)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
      setIsLoading(false)
      setToast({ open: true, message: `Company product was successfully created`, variant: 'success' })
      onClose();
      setTimeout(() => {
        setnewRecordCreated(true)
      }, 1000)

      // tableRef.current && tableRef.current.onQueryChange()
    })
    .catch((error: any) => {
      console.log("Error Data", error.data)
      if (error && error.status) {
        if (error.status === 409) {
          
          setToast({ open: true, message: 'Company product already exists or Text already exists', variant: 'error' })
        }

      }
      setnewRecordCreated(false)
      setIsLoading(false)

    });
  }

  // useEffect(()=>{
  //   console.log("MP~~~MC", selectedMasterCompany, selectedMasterProduct)
  // },[selectedMasterCompany, selectedMasterProduct])

  const handleChangeInstanceType = (e: any, ad: any, index: any) => {
    let currentIndex = index
    console.log("type:", e, attributeTypes.find(t => t.key === e.target.value))

    console.log("FOUNDDD", currentIndex)
    if (currentIndex !== undefined) {
      console.log("CAME")
      let updatedData = cloneDeep(attributesData)
      console.log("YESSS",updatedData, updatedData[currentIndex])
      let instanceType = attributeTypes.find(t => t.key === e.target.value)
      updatedData[currentIndex]['type'] = instanceType
      
      let typeKey = instanceType['key']
      updatedData.filter((item, itemIndex)=>{
        if(itemIndex != currentIndex){
          if(item.type && (item.type.key === typeKey)){
            if(item.primary === true){
              item['primary'] = !item.primary
              return item
            }
          }
        }
        
      })
      setAttributesData(updatedData)
    }

  }

  const handleChangeValue = (e: any, ad: any, index: any) => {
    if (index !== undefined) {
      let updatedData = cloneDeep(attributesData)
      updatedData[index]['value'] = e.target.value
      setAttributesData(updatedData)
    }
  }

  const handleChangeQuantity = (e: any, ad: any, index: any) => {
    let str = e.target.value
    const regExp = /^\d*(\.)?(\d{0,3})?$/

    if (regExp.test(str)) {
      if (index !== undefined) {
        let updatedData = cloneDeep(attributesData)
        updatedData[index]['quantity'] = e.target.value
        setAttributesData(updatedData)
        setInvalidQty(false)

      }
    }
    else {
      setInvalidQty(true)
    }

  }

  const handleChangePrimary = (e: any, ad: any, index: any) => {
    if (index !== undefined) {
      let updatedData = cloneDeep(attributesData)
      let typeKey = ad && ad['type']['key']
      updatedData.filter((item, index)=>{
        if(item.type && (item.type.key === typeKey)){
          if(item.primary === true){
            item['primary'] = !item.primary
            return item
          }
        }
      })
      updatedData[index]['primary'] = e.target.checked
      setAttributesData(updatedData)
    }
  }


  const renderAttributeValues = () => {

    if (attributeTypes && attributesData && attributesData.length > 0) {
      return attributesData.map((ad, index) => {
        return (
          <div key={String(index)} style={{ display: 'flex', flexDirection:'row', justifyContent: 'space-between', width: '50%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
              <div style={{ alignSelf:'center', marginRight:35, marginTop: 10 }}>
                <Typography>
                  {`${index + 1}.`}
                </Typography>
              </div>
              <div className={classes.mainList} style={{ borderColor: 'black' }}>
                <FormControl style={{ marginBottom: 15, width: '14rem' }}>
                  <InputLabel>
                    Type
                  </InputLabel>
                  <Select
                    value={ad ? ad.type && ad.type.key : ""}
                    onChange={(e) => handleChangeInstanceType(e, ad, index)}
                    className={classes.select}
                    style={{ minWidth: '100%' }}
                  >
                    {attributeTypes && attributeTypes.length > 0 &&
                      attributeTypes.map((b: any) => (
                        <MenuItem value={b.key} key={String(b.id)}>
                          {b.value}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <TextField
                  key="val"
                  label="Value"
                  margin="dense"
                  variant="outlined"
                  size="small"
                  style={{ width: "22rem", marginRight: '5px', marginLeft: 5 }}
                  value={ad ? ad.value && ad.value : ''}
                  onChange={(e) => handleChangeValue(e, ad, index)}
                  inputProps={{ style: { textAlign: 'center' } }}
                />
                <TextField
                  key="qty"
                  label="Quantity"
                  margin="dense"
                  variant="outlined"
                  size="small"
                  style={{ width: "20%", marginRight: '5px' }}
                  value={ad ? ad.quantity && ad.quantity : ''}
                  onChange={(e) => handleChangeQuantity(e, ad, index)}
                  error={invalidQty}
                  helperText={invalidQty ? `No more than 3 decimal places allowed` : ''}
                  inputProps={{ style: { textAlign: 'center' } }}
                />
                <FormControlLabel
                  style={{ flex: 1, marginTop: 8, fontWeight: 'normal' }}
                  control={
                    <Switch
                      checked={ad && ad.primary}
                      onChange={(e) => handleChangePrimary(e, ad, index)}
                      color="primary"
                      style={{ paddingLeft: "10px" , opacity : !ad.type ? 0.5 : 1.5 }}
                      disabled={!ad.type}
                    />
                  }
                  label={<span style={{ fontWeight: 'normal', opacity: !ad.type ? 0.5 : 1.5 }}>Primary:</span>}
                  labelPlacement="start"
                />
              </div>
            </div>

            <div style={{ display:'flex', justifyContent:'space-around'}}>
              {(attributesData.length !== 1) &&
                <IconButton style={{backgroundColor: 'transparent'}} onClick={() => handleDeleteAttributeValue(ad, index)}>
                <RemoveCircleIcon />
              </IconButton>
              }
              {(attributesData.length - 1 === index) ?
                  <IconButton style={{ backgroundColor: 'transparent' }} onClick={() => handleAddNewAttributeValue(ad, index)}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                : null
              }
            </div>


          </div>
        )
      })
    }
  }


  const handleAddNewAttributeValue =(currentItem: any, currentIndex: any) =>{

    let data = cloneDeep(attributesData)
    console.log("BEFORE", data, currentItem['id'], currentIndex)
    if (currentIndex != null ) {
      let attrObj = {}
      attrObj['id'] = Number(currentItem['id']) + 1
      attrObj['type'] = null
      attrObj['value'] = null
      attrObj['quantity'] = null
      attrObj['primary'] = false
      data.push(attrObj)
      console.log("AFTER", data)
      setAttributesData(data)
    }

  }


  const handleDeleteAttributeValue =(currentItem: any, currentIndex: any) =>{

    let data = cloneDeep(attributesData)

    if (currentIndex != null) {

      let updatedData = data.filter((i,index)=> index != currentIndex)

      updatedData = updatedData.filter((item, index)=>{
        item['id'] = index + 1
        return item
      })
      
      setAttributesData(updatedData)
    }    
    
  }


  const handleMPSelectionChange = (rows) => {
    if (rows.length > 0) {
      let mp = { ...rows[0] };
      delete mp['tableData'];
      setSelectedNewMasterProduct(mp);
    } else {
      setSelectedNewMasterProduct(null);
    }
  };

  const handleMCSelectionChange = (rows: any) => {
    if (rows.length > 0) {
      let company = { ...rows[0] };
      delete company['tableData'];
      setSelectedMasterCompany(company);
    } else {
      setSelectedMasterCompany(null);
    }
  };



  const renderLinkDetails = () => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {(props.mode === 'add') &&
            !(props.masterProduct) &&
            <div style={{ flex: 1, maxWidth: 440 }}>
              <MaterialTable
                options={{
                  pageSize: 20,
                  pageSizeOptions: [20],
                  padding: 'dense',
                  headerStyle: {
                    backgroundColor: '#eee',
                    paddingTop: 6,
                    paddingBottom: 6
                  },
                  showFirstLastPageButtons: false,
                  maxBodyHeight: 300,
                  showTitle: false,
                  selection: true,
                  showSelectAllCheckbox: false,
                  showTextRowsSelected: false,
                  searchFieldAlignment: 'left',
                  selectionProps: (rowData: any) => {
                    const checked = rowData && rowData.tableData && rowData.tableData.checked;
                    const selectionProps = {
                      disabled: selectedMasterProduct && !checked ? true : false,
                      color: 'primary'
                    };
                    if (props.mode === 'edit') {
                      selectionProps.disabled = selectedNewMasterProduct && !checked ? true : false;
                    }
                    return selectionProps;
                  }
                }}
                columns={[
                  { title: 'ID', field: 'id', editable: 'never', cellStyle: { width: '25%' } },
                  { title: 'Master Product', field: 'name', cellStyle: { width: '75%' } },
                ]}
                data={query =>
                  new Promise((resolve, reject) => {

                    getMasterProductsByPage(query.page + 1, query.pageSize, query.search)
                      //@ts-ignore
                      .then((res: { json: () => PromiseLike<PaginatedResponse<any>>; }) => {
                        return res.json();
                      })
                      .then((data: PaginatedResponse<any>) => {
                        const pageData = {
                          data: data.results,
                          totalCount: data.count,
                          page: query.page
                        };
                        resolve(pageData);
                      });
                  })
                }
                tableRef={masterProductTableRef}
                onSelectionChange={handleMPSelectionChange}
              />
            </div>
          }
          {
            (props.mode === 'add') &&
            <div style={{ flex: 1, maxWidth: 440 }}>
              <MaterialTable
                options={{
                  pageSize: 20,
                  pageSizeOptions: [20],
                  padding: 'dense',
                  headerStyle: {
                    backgroundColor: '#eee',
                    paddingTop: 6,
                    paddingBottom: 6
                  },
                  showFirstLastPageButtons: false,
                  debounceInterval: 650,
                  // maxBodyHeight: props.mode === 'link_business_product' ? 330 : 300,
                  // minBodyHeight: props.mode === 'link_business_product' ? 330 : 'inherit',
                  maxBodyHeight: 300,
                  minBodyHeight: 'inherit',
                  showTitle: false,
                  selection: true,
                  showSelectAllCheckbox: false,
                  showTextRowsSelected: false,
                  searchFieldAlignment: 'left',
                  selectionProps: (rowData: any) => {
                    const checked = rowData && rowData.tableData && rowData.tableData.checked;
                    return {
                      disabled: selectedMasterCompany && !checked ? true : false,
                      color: 'primary'
                    };
                  }
                }}
                columns={[
                  {
                    title: 'Master Company',
                    field: 'name'
                  },
                ]}
                data={query =>
                  new Promise((resolve, reject) => {
                    setSelectedMasterCompany(null);
                    let pageData = {
                      data: [] as any[],
                      totalCount: 0,
                      page: query.page
                    };
                    getSupplierCompanies(query.page + 1, query.pageSize, query.search)
                      .catch(handleError) // handle network issues
                      .then(checkStatus)
                      .then(parseJSON)
                      .then((data: PaginatedResponse<any>) => {
                        pageData = {
                          data: data.results,
                          totalCount: data.count,
                          page: query.page
                        };
                        resolve(pageData);
                      })
                      .catch((error: any) => {
                        resolve(pageData);
                      });
                  })
                }
                tableRef={unmappedCompanyTableRef}
                onSelectionChange={handleMCSelectionChange}
              />
            </div>
          }

        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', }}>
          {
            (props.mode === 'add') && !props.masterProduct &&
            <TextField
              id="selected-master-product"
              style={{ width: props.mode === 'add' ? '22rem' : '100%' }}
              label="Master Product"
              margin="normal"
              value={selectedNewMasterProduct ? selectedNewMasterProduct.name : ''}

            />
          }
          {
            (props.mode === 'add') && props.masterProduct &&
            <TextField
              id="selected-master-product"
              style={{ width: props.mode === 'add' ? '22rem' : '100%' }}
              label="Master Product"
              margin="normal"
              value={selectedMasterProduct ? selectedMasterProduct.name : ''}
            />
          }
          {
            props.mode === 'add' && !props.masterProduct &&
            <TextField
              label={"Master Company"}
              style={{ width: props.mode === 'add' ? '22rem' : '100%' }}
              margin="normal"
              inputProps={{
                readOnly: true,
              }}
              value={selectedMasterCompany ? selectedMasterCompany.name : ''}
            />
          }
        </div>
      </>
    )
  }


  const renderDialog = () => {
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll={'paper'}
        fullWidth
        maxWidth={props.mode === 'add' ? 'md' : 'sm'}
        disableBackdropClick={toast.variant === 'error'}
        onClick={handlResetToast}
      >
        <DialogTitle >
          {
            props.mode && (props.mode === 'add' ? 'New Company Product' : '')
          }
        </DialogTitle>
        <DialogContent dividers >
          {
            !isLoading &&
            <div>
              {renderLinkDetails()}
              <Divider className={classes.divider} />

              <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
                <Typography variant='h6' >Attribute Values: </Typography>
              </div>

              {renderAttributeValues()}
              {/* { 
                inCompleteDetails &&
                <Typography variant='body1' >Please fill the required fields </Typography>

              } */}

              <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={toast.open} autoHideDuration={4000} onClose={handleCloseToast} >
                <Alert onClose={handleCloseToast} severity={toast.variant}>
                  {toast.message}
                </Alert>
              </Snackbar>
            </div>
          }
          {
            isLoading &&
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          }

        </DialogContent>
        <DialogActions style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Button onClick={() => onClose()}>
            Cancel
          </Button>
          {
            props.mode ? props.mode === 'add' &&
              <Button
                onClick={handleSave}
                color="primary"
                disabled={invalidQty ? true : (newRecordCreated ? true :(!selectedMasterCompany || !selectedNewMasterProduct) ? true :   false)}
              >
                Save
              </Button>
              : null
          }
        </DialogActions>
      </Dialog>

    )
  }

  const isEmpty = (element, index, array) =>{
    console.log("Item", element)
    return !Object.keys(element).some(x => ((element[x] === null) || (element[x] === '') ||  (element[x] === undefined)))
  }


  const handleSave = () => {
    let payload = {}
    payload['instance_link_obj'] ={}
    if (selectedMasterCompany) {
      payload['instance_link_obj']['master_company'] = selectedMasterCompany
    }

    if (selectedNewMasterProduct) {
      payload['instance_link_obj']['master_product'] = selectedNewMasterProduct
    }
    else if (selectedMasterProduct) {
      payload['instance_link_obj']['master_product'] = selectedMasterProduct
    }

    if(attributesData.some(isEmpty) ){
      payload['attributes_data'] = attributesData
    }

    
    console.log("PAYLOAD", payload,attributesData)

    handleSaveCompanyInstance(payload)

  }

  return (
    <div>
      {renderDialog()}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};
export default connect(null, mapDispatchToProps)(CompanyProductInstanceModal);
