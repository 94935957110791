import { API_CONFIG } from '../../shared/api/config';
import composeRequest from '../../shared/api/core';
import { LOGIN_HEADERS } from '../../auth/api';
import { enqueueSnackbar, Toast, ToastError, ToastSuccess } from '../../shared/components/Notifier';
export const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    businessUrl: BASE_URL + 'core/businesses/',
    businessCreateUrl: BASE_URL + 'core/businesses/',
    packagesUrl: BASE_URL + 'core/package/',
    createEditLocationSubUrl: BASE_URL + 'core/location-subscription/create-update/',
    locationPreviousSubscriptionsUrl: BASE_URL + 'core/get-location-previous-subscriptions/',
}

export const createBusiness = (data: any) => {
    let URL = API_URLS.businessCreateUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data))
};

export const getBusinessByPage = (page: number, pageSize: number, searchTerm: string | null, orderBy: string | null) => {
    let URL = API_URLS.businessUrl + `?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.businessUrl + `?search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};
export const getBusinessByID = (id: number) => {
    let URL = API_URLS.businessUrl + `${id}/`;
    return composeRequest(URL, 'GET', null);
};

export const getAllPackages = () => {
    let URL = API_URLS.packagesUrl;
    return composeRequest(URL, 'GET', null);
};
export const createEditLocationSubscription = (data: any) => {
    let URL = API_URLS.createEditLocationSubUrl;
    return composeRequest(URL, 'POST', data);
};

export const getLocationPreviousSubscriptions = (id: number) => {
    let URL = API_URLS.locationPreviousSubscriptionsUrl + `?location_id=${id}`;
    return composeRequest(URL, 'GET', null);
};


export const deleteBusiness = (data: any) => {
    const URL = API_URLS.businessUrl + `${data.id}/`;
    return composeRequest(URL, 'DELETE', null);
};

export const updateBusiness = (data: any) => {
    const URL = API_URLS.businessUrl + `${data.id}/`
    return composeRequest(URL, 'PUT', JSON.stringify(data));
}