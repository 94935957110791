import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, TextField, CircularProgress, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, Select, MenuItem,
  FormControlLabel, Checkbox } from '@material-ui/core';

import { PaginatedResponse } from '../../../shared/models';

import { API_URLS, getAllUsers, getCommodityGroups, getCustomerOrderPickGroupUsers, getCustomerOrderPickGroups, getLocations, saveCustomerOrderPickGroup } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar, } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';

import { Filter, FilterType } from '../../../shared/models/Filter';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import MaterialTable, { MTableToolbar } from 'material-table';
import { getMasterCompaniesFullList } from '../../../master-data/api'
import TransferList from '../../../shared/components/TransferList/TransferList';
import { hasPermission } from '../../../shared/lib/PermissionManager';
import { Toast, ToastError, ToastSuccess, enqueueSnackbar } from '../../../shared/components/Notifier';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'left',
      justifyContent: 'left',

    },
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },

    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.primaryColor,
      },
      '&:after': {
        borderColor: GlobalStyles.primaryColor,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

interface OrderPickGroup {
  id ?: string
  name: string
  location: any
  commodityGroups ?: any[]

}

const EDIT_ORDER_PICK_GROUP = 'change_customerorderpickgroup'
const ADD_ORDER_PICK_GROUP = 'add_customerorderpickgroup'


const OrderPickGroupList = (props: any) => {
  const resetNewLocation = () => {
    return {
      name: "",
      commodityGroups: [],
      location: ""
    }
  }
  const classes = useStyles({});
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  let userTableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [userEmptyDataSourceMessage, setUserEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [userTableQuery, setUserTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [isUsersLoading, setUsersLoading] = useState(false);
  const [hasCGLoaded, sethasCGLoaded] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allCommodityGroups, setAllCommodityGroups] = useState([]);
  const [selectedCommodityGroups, setSelectedCommodityGroups] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false);
  const [userDialogBoxOpen, setUserDialogBoxOpen] = useState(false);
  const [selectedOrderPickGroup, setSelectedOrderPickGroup] = useState<OrderPickGroup>(resetNewLocation())
  const [selectedAddUser, setSelectedAddUser] = useState<any>()
  const [selectedEditUser, setSelectedEditUser] = useState<any>()
  const [selectedAddUserCanFinalize, setSelectedAddUserCanFinalize] = useState<boolean>(false)
  const [allAddUsers, setAllAddUsers] = useState<any>()
  const [userEditMode, setUserEditMode] = useState<any>()
  const userPermissions = props.userData.userData.userData.user_permissions
  const isSuperUser = props.userData.userData.userData.is_superuser

  

  

  useEffect(() => {
    const sb = props.userData.selectedBusiness;
    getCommodityGroups(sb.business_id).catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
      console.log(data)
      const cgs = data.map((i: any) => {
        return {
          id: i.uuid,
          label: `${i.name}`
        }
      }) 
      setAllCommodityGroups(cgs)
      sethasCGLoaded(true)
    })
    getLocations(sb.business_id).catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
      console.log(data)
      setAllLocations(data)
    })
    getAllUsers(sb.business_id).catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
      console.log(data)
      setAllUsers(data.results)
    })

  }, [])

  const onNewOrderPickGroupClick = () => {
    setDialogBoxOpen(true);
    setSelectedOrderPickGroup(resetNewLocation())
    setSelectedCommodityGroups([])
    setSelectedUsers([])
    setAllAddUsers(allUsers)
    setUserEditMode(false)
    setSelectedEditUser(null)
  }
  const setEditOrderPickGroup = (rowData) => {
    setSelectedOrderPickGroup(rowData)
    setSelectedCommodityGroups(rowData.commodityGroups)
    setSelectedUsers(rowData.users)
    const newallAddUsers = allUsers.filter(a => {
      const found = rowData.users.find(ru => ru.user.id === a.id)
      return !found
    })
    setAllAddUsers(newallAddUsers)
    setDialogBoxOpen(true);
  }
  const handleDialogBoxCancel = () => {
    setDialogBoxOpen(false);
    setSelectedOrderPickGroup(resetNewLocation())
    setSelectedCommodityGroups([])
  }
  const handleAddUserDialogBox = () => {
    setUserDialogBoxOpen(true);
    
  }
  const handleEditUserDialogBox = (rowData) => {
    setUserDialogBoxOpen(true);
    setSelectedEditUser(rowData)
    setSelectedAddUserCanFinalize(rowData.can_finalize_order)
    setUserEditMode(true)
  }
  const handleRemoveUser = (rowData) => {
    const newUsers = selectedUsers.filter(s => s.user.id !== rowData.user.id)
    setSelectedUsers(newUsers)
    allAddUsers.push(rowData)
    setAllAddUsers([...allAddUsers])
  }
  const handleUserDialogBoxCancel = () => {
    setUserDialogBoxOpen(false);
    setSelectedAddUser(null)
    setSelectedAddUserCanFinalize(false)
    setUserEditMode(false)
    setSelectedEditUser(null)
  }
  const handleSave = () => {
    setDialogLoading(true)
    const sb = props.userData.selectedBusiness;
    let opgId = null;
    if (selectedOrderPickGroup && selectedOrderPickGroup.hasOwnProperty('id')) {
      opgId = selectedOrderPickGroup.id
    }
    const data = {...selectedOrderPickGroup}
    data['commodityGroups'] = selectedCommodityGroups
    data['business'] = sb.business_id
    data['users'] = selectedUsers
    data['location'] = selectedOrderPickGroup.location.id
    saveCustomerOrderPickGroup(data, opgId).catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
      console.log(data)
      setDialogLoading(false)
      handleDialogBoxCancel()
      props.showToast({
        message: 'Successfully saved the pick group',
        options: ToastSuccess
      });
      if (tableRef) {
        tableRef.current.onQueryChange()
      }
    }).catch((error) => {
      console.error(error)
      props.showToast({
        message: 'Failed to save the pick group',
        options: ToastError
      });
    })
  }

  const handleNameChange = (e) => {
    selectedOrderPickGroup.name = e.target.value
    setSelectedOrderPickGroup({...selectedOrderPickGroup})
  }
  const handleSelectedLocationChange = (e) => {
    const locationId = e.target.value
    const location = allLocations.find(l => l.id === locationId)
    selectedOrderPickGroup.location = location
    setSelectedOrderPickGroup({...selectedOrderPickGroup})
  }
  const handleSelectedAddUser = (e) => {
    setSelectedAddUser(e.target.value)
  }

  const handleAddUser = () => {
    if (selectedUsers && !userEditMode) {
      if (selectedAddUser) {
        const existing = selectedUsers.find(s => s.user.id === selectedAddUser)
        if (existing) {
          existing.can_finalize_order = selectedAddUserCanFinalize
        } else {
          const user = allAddUsers.find(a => a.id === selectedAddUser)
          const data = {
            user: {...user},
            can_finalize_order: selectedAddUserCanFinalize,
            order_pick_group: selectedOrderPickGroup.id
          }
          selectedUsers.push(data)
        }
        setSelectedUsers([...selectedUsers])
        const newallAddUsers = allAddUsers.filter(a => a.id !== selectedAddUser)
        setAllAddUsers(newallAddUsers)
      }
    }
    if (userEditMode) {
      const existing = selectedUsers.find(s => s.user.id === selectedEditUser.user.id)
      if (existing) {
        existing.can_finalize_order = selectedAddUserCanFinalize
      } 
      setSelectedUsers([...selectedUsers])
    }
    setSelectedAddUser(null)
    setSelectedAddUserCanFinalize(false)
    setUserDialogBoxOpen(false)
  }

  const renderOptions = (options: any[]) => {
    return options.map((o, i) => {
      return (
        <MenuItem key={`opt-${i}`} value={o.id}>
          {o.name}
        </MenuItem>
      );
    });
  };
  const renderUserOptions = (options: any[]) => {
    return options.map((o, i) => {
      return (
        <MenuItem key={`opt-${i}`} value={o.id}>
          {`${o.first_name} ${o.last_name}`} 
        </MenuItem>
      );
    });
  };


  const renderAddUserDialog = () => {
    return (
      <Dialog
            open={userDialogBoxOpen}
            onClose={handleUserDialogBoxCancel}
            fullWidth={true}
            maxWidth="xs"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>
              {userEditMode ? 'Edit User' : 'Add new User'}
            </DialogTitle>
            <DialogContent style={{ padding: "10px", }}>
              <div className={classes.dialogContent} >
                {
                  userEditMode && 
                  <div>
                    <TextField
                      autoFocus
                      margin="dense"
                      value={selectedEditUser && selectedEditUser != null ? `${selectedEditUser.user.first_name} ${selectedEditUser.user.last_name}` : ''}
                      label="Name"
                      type="text"
                      style={{ width: '12rem' }}
                      disabled={true}
                      variant='outlined'
                    />
                  </div>

                }
                {
                  !userEditMode && 
                  <FormControl variant="outlined" style={{ marginBottom: 15 }}>
                    <InputLabel id="demo-simple-select-outlined-label">Users</InputLabel>
                    <Select
                      // label="Template"
                      labelId="demo-simple-select-outlined-label"
                      id={`listing-templates`}
                      value={selectedAddUser}
                      onChange={(e) => handleSelectedAddUser(e)}
                      // variant="outlined"
                      style={{ width: '12rem' }}
                      label={`User`}
                      fullWidth={true}
                      margin="dense"
                    >
                      {allAddUsers ? renderUserOptions(allAddUsers): <></>}
                    </Select>
                  </FormControl>
                }
                <div>
                <FormControlLabel  
                  control={
                    <Checkbox
                      checked={selectedAddUserCanFinalize}
                      onChange={() => {
                        setSelectedAddUserCanFinalize(!selectedAddUserCanFinalize)
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} 
                  label="Can Finalize Order" />
                </div>
                </div>
                </DialogContent>
            <DialogActions>
              <Button onClick={handleUserDialogBoxCancel} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => handleAddUser()} style={{ color: "#ff1744" }} >
                {userEditMode ? 'Edit' : 'Add'}
              </Button>
            </DialogActions>
          </Dialog>
    )
  }

  const renderAddOrderPickGroupDialogBox = () => {
    return (
      <div>
        {
          <Dialog
            open={dialogBoxOpen}
            onClose={handleDialogBoxCancel}
            fullWidth={true}
            maxWidth="lg"
          >
            {
              !dialogLoading &&
              <>
                <DialogTitle style={{ color: GlobalStyles.primaryColor }}>Add a new Order Pick Group</DialogTitle>
                <DialogContent style={{ padding: "10px", }}>
                  <div className={classes.dialogContent} >
                    <div style={{ marginBottom: 15, display: 'flex', alignItems: 'center',  }}>
                      <div style={{flex: 1, padding: '1rem'}}>
                        <TextField
                          autoFocus
                          margin="dense"
                          size="small"
                          value={selectedOrderPickGroup && selectedOrderPickGroup != null ? selectedOrderPickGroup.name : null}
                          onChange={handleNameChange}
                          label="Name"
                          type="text"
                          style={{ width: '100%' }}
                          disabled={dialogLoading}
                          variant='outlined'
                        />
                      </div>
                      <div style={{flex: 1, padding: '1rem'}}>
                        <FormControl variant="outlined"style={{ width: '100%' }} >
                          <InputLabel id="demo-simple-select-outlined-label">Location</InputLabel>
                          <Select
                            // label="Template"
                            labelId="demo-simple-select-outlined-label"
                            id={`listing-templates`}
                            value={selectedOrderPickGroup && selectedOrderPickGroup != null ? selectedOrderPickGroup.location.id : null}
                            onChange={(e) => handleSelectedLocationChange(e)}
                            // variant="outlined"
                            
                            label={`Location`}
                            fullWidth={true}
                            margin="dense"
                          >
                            {allLocations ? renderOptions(allLocations): <></>}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <TransferList title={"Commodity Groups"} title2={"Selected Commodity Groups"} choices={allCommodityGroups} chosen={selectedCommodityGroups} onSelectedChange={(litems) => {
                      setSelectedCommodityGroups(litems)
                    }} />

                    <div style={{ maxWidth: '100%' }}>
                      <div className="table-wrapper">
                        <MaterialTable
                          options={{
                            // ...tableOptions(userTableQuery),
                            thirdSortClick: false,
                            draggable: false,
                            sorting: true,
                            search: false
                          }}
                          tableRef={userTableRef}
                          localization={{
                            body: {
                              emptyDataSourceMessage: userEmptyDataSourceMessage
                            }
                          }}
                          columns={[
                            { title: 'First Name', field: 'user.first_name' },
                            { title: 'Last Name', field: 'user.last_name' },
                            { title: 'Email', field: 'users.email' },
                            { 
                              title: 'Can Finalize', 
                              field: 'can_finalize_order',
                              render: rowData => {
                                if (rowData) {
                                  return (
                                    <div>
                                      <Checkbox checked={rowData.can_finalize_order} disabled />
                                    </div>
                                  )
            
                                }
                                return null
                              },
                            },
                          ]}
                          data={selectedUsers}
                          title={<span style={{display: 'inline-block', marginLeft: '0.5rem', fontSize: '1.2rem', fontWeight: 'bold'}}>Users</span>}
                          actions={[
                            {
                              icon: 'edit',
                              tooltip: 'Edit',
                              // hidden: hasUpdatePermission(props.userData.userData.is_superuser, props.userData.userData.user_permissions, permission_model),
                              onClick: (event, rowData) => handleEditUserDialogBox(rowData),
                            },
                            {
                              icon: 'close',
                              tooltip: 'Remove',
                              // hidden: hasUpdatePermission(props.userData.userData.is_superuser, props.userData.userData.user_permissions, permission_model),
                              onClick: (event, rowData) => handleRemoveUser(rowData),
                            },
                            {
                              icon: 'add',
                              tooltip: 'Add',
                              isFreeAction: true,
                              onClick: handleAddUserDialogBox,
                            },
                            // {
                            //   icon: 'refresh',
                            //   tooltip: 'Refresh Data',
                            //   isFreeAction: true,
                            //   onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                            // },
                          ]}
                        />
                      </div>
                    </div>
                    
                    {
                      dialogLoading &&
                      <div style={{ textAlign: 'center', marginTop: 5 }}>
                        <CircularProgress />
                      </div>
                    }
                    {renderAddUserDialog()}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogBoxCancel} color="primary" autoFocus>
                    Cancel
                  </Button>
                  <Button onClick={() => handleSave()} style={{ color: "#ff1744" }} >
                    Save
                  </Button>
                </DialogActions>
              </>
            }
            {
              dialogLoading &&
              <div style={{ textAlign: 'center', marginTop: 5, height: 400 }}>
                <CircularProgress />
              </div>
            }
            
          </Dialog>
        }
      </div>
    )
  }

  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <ExportStatusBar isVisible={isExporting} />
      {
        hasCGLoaded && 
        <div style={{ maxWidth: '100%' }}>
          <div className="table-wrapper">
            <MaterialTable
              options={{
                ...tableOptions(tableQuery),
                thirdSortClick: false,
                draggable: false,
                sorting: true
              }}
              tableRef={tableRef}
              localization={{
                body: {
                  emptyDataSourceMessage: emptyDataSourceMessage
                }
              }}
              columns={[
                { title: 'Name', field: 'name' },
                { title: 'Location', field: 'location.name' },
                {
                  title: 'Commodity Groups',
                  field: 'commodityGroups',
                  render: rowData => {
                    if (rowData && rowData.commodityGroups) {
                      return (
                        <div style={{ minWidth: 200 }}>
                          {rowData.commodityGroups.map((a) => a.label).join(", ")}
                        </div>
                      )

                    }
                    return null
                  },

                },
                

              ]}
              data={query =>

                new Promise((resolve, reject) => {
                  replaceHistory(query, props.history);
                  let pageData = {
                    data: [] as any[],
                    totalCount: 0,
                    page: query.page
                  };
                  let orderBy = '';
                  if (query.orderBy) {
                    orderBy = query.orderBy.field as string;
                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                  }
                  setLoading(true);
                  const sb = props.userData.selectedBusiness;

                  getCustomerOrderPickGroups(
                    sb.business_id,
                    query.page + 1,
                    query.pageSize,
                    query.search,
                    orderBy

                  )
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<any>) => {
                      setLoading(false);
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      const results = data.results.map(d => {

                        d.commodityGroups = d.commodityGroups.map(c => {
                          const cg = allCommodityGroups.find(a => a.id === c.uuid)
                          return cg
                        })
                        return d
                      })
                      pageData = {
                        data: results,
                        totalCount: data.count,
                        page: query.page
                      };
                      setTableQuery({
                        ...tableQuery,
                        orderBy,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      resolve(pageData);
                    })
                    .catch((error: any) => {
                      console.error(error)
                      if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_500);
                          setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)
                        ) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      setLoading(false);

                      resolve(pageData);
                    });
                })
              }
              title={
                <CustomTableTitle
                  history={props.history}
                  title={'Order Pick Groups'}
                  icon={SVG_ICONS.product} />
              }
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Edit',
                  hidden: !hasPermission(userPermissions, EDIT_ORDER_PICK_GROUP, isSuperUser),
                  onClick: (event, rowData) => setEditOrderPickGroup(rowData),
                },
                {
                  icon: 'add',
                  tooltip: 'Add',
                  isFreeAction: true,
                  hidden: !hasPermission(userPermissions, ADD_ORDER_PICK_GROUP, isSuperUser),
                  onClick: onNewOrderPickGroupClick,
                },
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                },
              ]}
            />
          </div>
        </div>
      }
      {
        
          !hasCGLoaded &&
          <div style={{ textAlign: 'center', marginTop: 5 }}>
            <CircularProgress />
          </div>
        
      }
      {renderAddOrderPickGroupDialogBox()}
      </div>
      
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(OrderPickGroupList);
