import { API_CONFIG } from "../../shared/api/config";
import composeRequest from "../../shared/api/core";

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    userListUrl: BASE_URL + 'core/users/',
    userCountUrl: BASE_URL + 'core/users/count/',
    userSummaryDataUrl: BASE_URL + 'core/users/summary/',
    getPermissionsUrl : BASE_URL + 'core/get-all-business-with-locations-and-permissions',
    getGroupsUrl : BASE_URL + 'core/auth-group/',
    saveUserUrl : BASE_URL + 'core/konnect-user/create-or-update',
    coreUserDetailUrl: BASE_URL + 'core/users/user-detail/'

};
export const permissionModel = 'user';

export const getUsersByPage = (
    businessId: string | number, 
    page: number, 
    pageSize: number, 
    searchTerm: string,
    orderBy: string | number,    
) => {
    let URL = `${API_URLS.userListUrl}?business_id=${businessId}&page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const updateUser = (data: any) => {
    const URL = API_URLS.userListUrl + `${data.id}/`;
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};

export const getPermissions= () => {
    let URL = `${API_URLS.getPermissionsUrl}`;
    return composeRequest(URL, 'GET', null);
}
export const getGroups= () => {
    let URL = `${API_URLS.getGroupsUrl}`;
    return composeRequest(URL, 'GET', null);
}


export const saveKonnectUser= (data: any) => {
    let URL = `${API_URLS.saveUserUrl}`;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}


export const getCoreUserDetail = (id: number) => {
    let URL = API_URLS.coreUserDetailUrl + id + '/' + '?user_detail=true';
    return composeRequest(URL, 'GET', null);
};