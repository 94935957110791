import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Divider, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Checkbox, List, ListItem, ListItemIcon,
  FormControl, InputLabel, Select, MenuItem, CircularProgress, Button, ListSubheader, Paper, FormLabel, RadioGroup,
  Radio, FormGroup, FormControlLabel, IconButton, Snackbar, FormHelperText, Modal, LinearProgress, Stepper, Step, StepLabel
} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import MaterialTable, { MaterialTableProps } from 'material-table';
import moment from 'moment';
import { Permission, User } from '../../models';

import { updateUser, getUsersByPage, permissionModel, getPermissions, saveKonnectUser } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { getBusinessListFromAPI } from '../../../shared/data/actions';
import { PaginatedResponse, Location } from '../../../shared/models';
import { ErrorStatusBar, CustomTableTitle } from '../../../shared/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, API_RESPONSE, NO_PERMISSIONS_ERROR_MSG, getErrorMsg } from '../../../shared/lib/Localization';
import { isSuperAdmin, hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { blue, orange, red } from '@material-ui/core/colors';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { ToastType } from '../../../shared/models';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 2),
      border: '1px solid',
      borderColor: '#cdcdcd',
      borderRadius: 4
    },
    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: 'center',

      // alignItems: 'flex-start'

    },
    paper: {
      position: 'absolute',
      width: 600,
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '20rem',
      minWidth: '12rem',
    },
    multiSelect: {
      color: GlobalStyles.lightBlue,
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.lightBlue,
      },
      '&:after': {
        borderColor: GlobalStyles.lightBlue,
      },
      maxWidth: '12rem',
      minWidth: '12rem',
      minHeight: 150
    },
    icon: {
      fill: GlobalStyles.lightTheme,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 250,

    },
    formContent: {
      display: 'flex',
      flexDirection: 'row'
    },

    content: {
      margin: '6px!important',
      width: '100%'
    },
  }),
);

const initialUserDetails = {
  first_name: null,
  last_name: null,
  user_name: null,
  email: null,
  password: null,
  pin: null,
  active: null
}

const UserManagerScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [tableEditableProp, setTableEditableProp] = useState({});
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);

  const [isAddNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false)
  const [dialogLoading, setDialogLoading] = useState(false)

  const [userDetails, setUserDetails] = useState(cloneDeep(initialUserDetails))
  const [newUserAddedSuccessfully, setNewUserAddedSuccessfully] = useState(false)

  const [toast, setToast] = useState({
    open: false,
    message: '',
    variant: 'success',
  } as ToastType);


  // useEffect(() => {
  //   console.log("USER-DETAILS", userDetails)
  //   console.log("firstName", userDetails.first_name, userDetails.first_name && userDetails.first_name.length)
  // }, [userDetails])

  useEffect(() => {
    if (newUserAddedSuccessfully) {
      let msg = 'New user added successfully'
      props.showToast({ message: msg, options: ToastSuccess });
      tableRef.current && tableRef.current.onQueryChange();
      setNewUserAddedSuccessfully(false)
    }
  }, [newUserAddedSuccessfully])

  useEffect(() => {
    const { userData } = props.userData.userData;
    const editable = {} as any;
    if (userData) {
      if (hasUpdatePermission(userData.is_superuser,
        userData.user_permissions, permissionModel)) {
        editable['onRowUpdate'] = getUpdateFn();
      }
      setTableEditableProp(editable);
    }
    console.log("props-userData", props.userData)
  }, [props.userData]);


  useEffect(() => {
    if (isSuperAdmin(props) && !isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, [props.userData.selectedBusiness]);


  const getUpdateFn = () => {
    return (newData: any, oldData: any) => new Promise((resolve, reject) => {
      setTimeout(() => {
        {
          updateUser(newData)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then(jsonData => {
              props.showToast({
                message: 'User updated successfully!',
                options: ToastSuccess
              });
              // @ts-ignore
              resolve();
            })
            .catch((error: any) => {
              let msg = 'Failed to update user';
              props.showToast({ message: getErrorMsg(error, msg), options: ToastError });
            });
        }
        //@ts-ignore
        resolve();
      }, 1000);
    });
  };


  const handleDialogBoxCancel = () => {
    setAddNewUserDialogOpen(false)
    setUserDetails(cloneDeep(initialUserDetails))
  }

  const handleNewUserDetailsChange = (e: any) => {
    let value = e.target.value;
    let key = e.target.name;
    console.log("testing", value)
    if (key === 'pin') {
      let regex = /([0-9])+$/;
      if (value.match(regex) || value === "") {
        setUserDetails({ ...userDetails, [key]: value });
      }
    }
    else {
      setUserDetails({ ...userDetails, [key]: value });
    }
  }

  interface ShowUserActiveSettingsProps {
    setUserInputDetails: (state: any) => void,
    handleSave: (data: any) => void,
    handlePrev: (state: any) => void,
    userInputDetails: any,
    showRequiredFieldsError: any,
    setShowRequiredFieldsError: (state: any) => void,
  }


  const ShowUserActiveSettings = (props: ShowUserActiveSettingsProps) => {
    const [
      setUserInputDetails,
      handleSave,
      handlePrev,
      showRequiredFieldsError,
      userInputDetails,
      setShowRequiredFieldsError] = [
        props.setUserInputDetails,
        props.handleSave,
        props.handlePrev,
        props.showRequiredFieldsError,
        props.userInputDetails,
        props.setShowRequiredFieldsError,
      ];

    const handleUserActiveSettingsChange = (e: any) => {
      setUserInputDetails({ ...userInputDetails, active: eval(e.target.value) || false });
      setShowRequiredFieldsError(false);
      console.log(userInputDetails.active, e.target.value)
    }

    return (
      <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
        <FormLabel component="legend">Active*:</FormLabel>
        <FormControl component="fieldset" className={classes.formControl} error={showRequiredFieldsError ? !userInputDetails.active ? true : false : false} >
          <RadioGroup value={userInputDetails.active !== null ? `${userInputDetails.active}` : null} onChange={handleUserActiveSettingsChange} className={classes.formContent}>
            <FormGroup>
              <FormControlLabel value={'true'} control={<Radio />} label="True" />
              <FormControlLabel value={'false'} control={<Radio />} label={`False`} />
            </FormGroup>
          </RadioGroup>
          {
            showRequiredFieldsError && !userInputDetails.active ?
              <FormHelperText>{'Select an option.'}</FormHelperText> : null
          }
        </FormControl>

        <DialogActions>
          <Button onClick={handleDialogBoxCancel} variant={'outlined'} style={{ backgroundColor: "#ff1744", color: GlobalStyles.textWhite, }} autoFocus>
            Cancel
          </Button>
          <Button onClick={handlePrev} startIcon={<NavigateBefore />} variant={'outlined'} style={{ backgroundColor: GlobalStyles.primaryColor, color: GlobalStyles.textWhite, }} color="primary">
            Go Back
          </Button>
          <Button onClick={handleSave} startIcon={<SaveIcon />} variant={'outlined'} style={{ backgroundColor: GlobalStyles.primaryColor, color: GlobalStyles.textWhite, }} color="primary">
            Save
          </Button>
        </DialogActions>
        <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleCloseToast}>
          <Alert onClose={handleCloseToast} severity={toast.variant}>
            {toast.message}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  const RenderUserDetails = (props: any) => {

    const [userInputDetails, setUserInputDetails] = useState(cloneDeep(initialUserDetails));
    var [userInputDetailsError, setUserInputDetailsErrror] = useState(cloneDeep(initialUserDetails));
    const [permissionList, setPermissionList] = useState(null)
    const [selectedPermissionList, setSelectedPermissionList] = useState([])
    const [isProcessing, setProcessing] = useState(false);
    const [showRequiredFieldsError, setShowRequiredFieldsError] = useState(false)

    const [locationList, setLocationList] = useState(null)
    const [selectedLocationList, setSelectedLocationList] = useState([])
    const [isDone, setIsDone] = useState(false);

    if (!isDone) {
      // setProcessing(true);
      getPermissions()
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          // console.log("JSON-data", jsonData)
          if (jsonData.permissions) {
            let permissionData = cloneDeep(jsonData.permissions)
            permissionData.forEach((i: any) => i.selected = false)
            setPermissionList(sortBy(permissionData, 'content_type.model'))
          }
          if (jsonData.locations) {
            let locationsData = cloneDeep(jsonData.locations)
            locationsData.forEach((i: any) => i.selected = false)
            setLocationList(sortBy(locationsData, 'business_name'))
          }
          // setProcessing(false);
          setDialogLoading(false)
        })
        .catch((error: any) => {
          // setProcessing(false);
          setDialogLoading(false)
          let msg = 'Failed to get permissions';
          props.showToast({ message: getErrorMsg(error, msg), options: ToastError });
        });
      setIsDone(true);
    }

    const [activeStep, setActiveStep] = useState(props.activeStep);

    const handlePrev = () => {
      setActiveStep(activeStep - 1);
      return <RenderUserDetails activeStep={activeStep} showToast={props.showToast} userData={props.userData} />
    }

    const handleNext = () => {
      setProcessing(true);
      if ((!userInputDetails.first_name) || (!userInputDetails.email) || (!userInputDetails.password) || (!userInputDetails.user_name)) {
        if (!userInputDetails.first_name) {
          userInputDetailsError['first_name'] = "Invalid Format: " + userInputDetails.first_name;
        }
        if (!userInputDetails.email) {
          userInputDetailsError['email'] = "Invalid Format: " + userInputDetails.email;
        }
        if (!userInputDetails.password) {
          userInputDetailsError['password'] = "Invalid Format: " + userInputDetails.password;
        }
        if (!userInputDetails.user_name) {
          userInputDetailsError['user_name'] = "Invalid Format: " + userInputDetails.user_name;
        }
        setUserInputDetailsErrror(userInputDetailsError);
        // setToast({ open: true, message: 'Enter the required fields ', variant: 'error' });
        // return <RenderUserDetails activeStep={activeStep} userInputDetailsError={userInputDetailsError} userInputDetails={userInputDetails}/>
        // return <RenderUserDetails activeStep={activeStep} />
        setTimeout(() => setProcessing(false), 100);
        return;
      }
      else if (userInputDetailsError.email || userInputDetailsError.first_name || userInputDetailsError.last_name ||
        userInputDetailsError.password || userInputDetailsError.pin) {
        console.log(userInputDetailsError.email || userInputDetails.first_name || userInputDetailsError.last_name ||
          userInputDetailsError.password || userInputDetailsError.pin)
        setTimeout(() => setProcessing(false), 100);
        return;
      }
      if (activeStep < 3) {
        setActiveStep(activeStep + 1);
      }
      setProcessing(false)
      return <RenderUserDetails activeStep={activeStep} userData={props.userData} showToast={props.showToast} />
    }
    const handleSave = () => {
      let payload = {}
      let permissionIds = []
      let locations = []

      if (userInputDetails.active === null) {
        console.log("into this")
        // setToast({ open: true, message: 'Enter the required fields', variant: 'error' });
        userInputDetailsError.active = "Choose the required option";
        setShowRequiredFieldsError(true)
        setUserInputDetailsErrror({ ...userInputDetailsError, active: "Choose the required option" })
        props.showToast({ message: "Choose the required option", options: ToastError });
        // setProcessing(true);
        // setTimeout(()=>setProcessing(false), 100);
        return;
      }
      setProcessing(true)
      if (selectedLocationList && selectedLocationList.length > 0) {
        for (let loc in selectedLocationList) {
          console.log("Location", selectedLocationList[loc])
          //@ts-ignore
          locations.push(selectedLocationList[loc].id)
        }
        payload['locations'] = locations
      }

      if (selectedPermissionList && selectedPermissionList.length > 0) {
        for (let p in selectedPermissionList) {
          //@ts-ignore
          permissionIds.push(selectedPermissionList[p].id)
        }
        payload['permissions'] = permissionIds
      }


      payload['business_id'] = props && props.userData && props.userData.selectedBusiness && props.userData.selectedBusiness.business_id
      payload = { ...payload, ...userInputDetails }

      console.log("Payload--------", payload)
      console.log("Active", userDetails && userDetails.active)
      //@ts-ignore
      if ((!payload.first_name) || (!payload.email) || (!payload.password) || (!payload.user_name)) {
        setShowRequiredFieldsError(true)
        console.log("into that")
        setToast({ open: true, message: 'Enter the required fields', variant: 'error' });
      }
      else {
        setShowRequiredFieldsError(false)
        handleCloseToast()
        console.log("ELSEEE-CAME")
        setDialogLoading(true)

        saveKonnectUser(payload)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(jsonData => {
            console.log("JSON-data", jsonData)
            setDialogLoading(false)
            handleDialogBoxCancel()
            setNewUserAddedSuccessfully(true)
            setProcessing(false)
            setLocationList(null);
            setPermissionList(null);  
          })
          .catch((error: any) => {
            setDialogLoading(false)
            setProcessing(false)
            let msg = 'Failed to save user';
            props.showToast({ message: getErrorMsg(error, msg), options: ToastError });
          });
      }


    }

    const handleUserInputDetailsChange = (e: any) => {
      let value = e.target.value;
      let key = e.target.name;
      if (key === 'pin') {
        let regex = /([0-9])+$/;
        if (value.match(regex) || value === "") {
          setUserInputDetails({ ...userInputDetails, [key]: value });
        }
        else {
          setUserInputDetailsErrror({ ...userInputDetailsError, [key]: "Invalid Format: " + value });
        }
      }
      else {
        if (key === "first_name" || key === "last_name" || key === "password") {
          let regex = /\S+/;
          if (regex.test(value)) {
            setUserInputDetailsErrror({ ...userInputDetailsError, [key]: null });
          }
          else {
            setUserInputDetailsErrror({ ...userInputDetailsError, [key]: "Invalid Format: " + value });
          }
        }
        else if (key === "user_name") {
          let regex = /^[A-Za-z0-9_@].{4,8}$/;
          if (regex.test(value)) {
            setUserInputDetailsErrror({ ...userInputDetailsError, [key]: null });
          }
          else {
            setUserInputDetailsErrror({ ...userInputDetailsError, [key]: "Invalid Format: " + value });
          }
        }
        else if (key === "email") {
          let regex = /^(?!\.)("([^"\r\\]|\\["\r\\])*"|([-a-z0-9!#$%&'*+/=?^_`{|}~]|(?<!\.)\.)*)(?<!\.)@[a-z0-9][\w\.-]*[a-z0-9]\.[a-z][a-z\.]*[a-z]$/;
          if (regex.test(value)) {
            setUserInputDetailsErrror({ ...userInputDetailsError, [key]: null });
          }
          else {
            setUserInputDetailsErrror({ ...userInputDetailsError, [key]: "Invalid Format: " + value });
          }
        }
        setUserInputDetails({ ...userInputDetails, [key]: value });
      }
    }

    const steps = ['Enter User Detail', 'Choose Locations', 'Choose Permission', "Choose Status"];

    return <>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step completed={false} key={label}>
            <StepLabel >{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {
        activeStep === 0 ?
        <>
        {isProcessing && <LinearProgress color="secondary" />}
            <TextField
              id="FirstName"
              autoFocus
              name="first_name"
              margin="dense"
              size="small"
              variant="outlined"
              value={userInputDetails.first_name || ""}
              onChange={handleUserInputDetailsChange}
              label="First Name"
              type="text"
              style={{ width: "50%" }}
              required
              error={userInputDetailsError.first_name ? true : false}
              helperText={userInputDetailsError.email || null}
            />
            <TextField
              id="LastName"
              margin="dense"
              size="small"
              name="last_name"
              value={userInputDetails.last_name || ""}
              // value={rowData ? rowData.last_name : userInputDetails && userInputDetails.lastName != null ? userInputDetails.lastName : ''}
              onChange={handleUserInputDetailsChange}
              label="Last Name"
              type="text"
              variant="outlined"
              style={{ width: "50%" }}
              error={userInputDetailsError.last_name ? true : false}
              helperText={userInputDetailsError.last_name || null}
            />
            <TextField
              id="userName"
              margin="dense"
              size="small"
              name="user_name"
              variant="outlined"
              value={userInputDetails.user_name || ""}
              // value={rowData ? rowData.user_name : userInputDetails && userInputDetails.userName != null ? userInputDetails.userName : ''}
              onChange={handleUserInputDetailsChange}
              label="User name"
              type="text"
              style={{ width: "50%" }}
              required
              error={userInputDetailsError.user_name ? true : false}
              helperText={userInputDetailsError.user_name || null}
            />
            <TextField
              id="Email"
              margin="dense"
              size="small"
              name="email"
              variant="outlined"
              value={userInputDetails.email || ""}
              // value={rowData ? rowData.email : userInputDetails && userInputDetails.email != null ? userInputDetails.email : ''}
              onChange={handleUserInputDetailsChange}
              label="Email"
              type="text"
              style={{ width: "50%" }}
              required
              error={userInputDetailsError.email ? true : false}
              helperText={userInputDetailsError.email || null}
            />
            <TextField
              id="Password"
              margin="dense"
              size="small"
              variant="outlined"
              name="password"
              value={userInputDetails.password || ""}
              // value={rowData ? rowData.password : userInputDetails && userInputDetails.password != null ? userInputDetails.password : ''}
              onChange={handleUserInputDetailsChange}
              label="Password"
              style={{ width: "50%" }}
              // onPaste={(e) => e.preventDefault()}
              required
              error={userInputDetailsError.password ? true : false}
              helperText={userInputDetailsError.password || null}
            />
            <TextField
              id="pin"
              margin="dense"
              size="small"
              variant="outlined"
              name="pin"
              value={userInputDetails.pin || ""}
              // value={rowData ? rowData.pin : userInputDetails && userInputDetails.pin != null ? userInputDetails.pin : ''}
              onChange={handleUserInputDetailsChange}
              label="PIN"
              // type="number"
              error={userInputDetailsError.pin ? true : false}
              helperText={userInputDetailsError.pin || null}
              style={{ width: "50%", marginBottom: 20 }}
            />
            <DialogActions >
              <Button onClick={handleDialogBoxCancel} variant={'outlined'} style={{ backgroundColor: "#ff1744", color: GlobalStyles.textWhite, }} autoFocus>
                Cancel
              </Button>
              <Button onClick={handleNext} startIcon={<NavigateNext />} variant={'outlined'} style={{ backgroundColor: GlobalStyles.primaryColor, color: GlobalStyles.textWhite, }} color="primary">
                Next
              </Button>
            </DialogActions>
            <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleCloseToast}>
              <Alert onClose={handleCloseToast} severity={toast.variant}>
                {toast.message}
              </Alert>
            </Snackbar>
          </>
          :
          (isDone && activeStep === 1) ? <RenderLocationList
            setSelectedLocationList={setSelectedLocationList}
            setLocationList={setLocationList}
            locationList={locationList}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
            :
            activeStep === 2 ?
              <RenderPermissionList
                setSelectedPermissionList={setSelectedPermissionList}
                setPermissionList={setPermissionList}
                permissionList={permissionList}
                selectedLocationsList={selectedLocationList}
                handleNext={handleNext}
                handlePrev={handlePrev}
                selectedPermissionList={selectedPermissionList}
              />
              :
              activeStep === 3 && <ShowUserActiveSettings
                setUserInputDetails={setUserInputDetails}
                handleSave={handleSave}
                handlePrev={handlePrev}
                userInputDetails={userInputDetails}
                showRequiredFieldsError={showRequiredFieldsError}
                setShowRequiredFieldsError={setShowRequiredFieldsError}
              />
      }
    </>
  }


  interface RenderLocationListProps {
    setSelectedLocationList: (state: any) => void,
    setLocationList: (state: any) => void,
    handleNext: (state: any) => void,
    handlePrev: (state: any) => void,
    locationList: any,
  }

  const RenderLocationList = (props: RenderLocationListProps) => {
    const [allLocationsSelected, setAllLocationsSelected] = useState(false)

    const [
      setSelectedLocationList,
      setLocationList,
      locationList,
      handleNext,
      handlePrev] = [
        props.setSelectedLocationList,
        props.setLocationList,
        props.locationList,
        props.handleNext,
        props.handlePrev];

    const handleSelectAllChange = (e: any) => {
      setAllLocationsSelected(e.target.checked)
      let updatedLocationList = cloneDeep(locationList)
      updatedLocationList.forEach((l: any) => {
        l.selected = e.target.checked;
      });
      setSelectedLocationList(updatedLocationList);
      setLocationList(updatedLocationList);
    }

    const handleLocationsChange = (event: any, locId: any) => {
      console.log("event.target", event.target);
      if (event && event.target) {
        // const checkedId = event.target.value.find(id => typeof id === 'number');
        let updatedLocationList = cloneDeep(locationList)
        updatedLocationList.forEach((l: any) => {
          if (l.id === locId) {
            l.selected = !l.selected;
          }
        });
        const selectedLocationsLocal = updatedLocationList.filter((l: any) => l.selected);
        console.log("selectedLocationsLocal", selectedLocationsLocal);
        setSelectedLocationList(selectedLocationsLocal);
        setLocationList(updatedLocationList);
      }
    };

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <InputLabel
            id="locs-list"
            style={{ color: GlobalStyles.primaryColor }}
          >
            {`Location(s) selected - ${locationList ? locationList.filter((i: any) => i.selected).length : 0}`}
          </InputLabel>
          <FormControlLabel
            style={{ justifyContent: 'end' }}
            checked={allLocationsSelected}
            onChange={handleSelectAllChange}
            control={<Checkbox />}
            label={`Select all`}
          />

        </div>
        <Paper style={{ maxHeight: 250, overflow: 'auto', marginTop: 15, marginBottom: 20 }}>
          <List className={classes.root}>
            {locationList && locationList.map((loc: any) => {
              return (
                <ListItem key={loc.id.toString()} role={undefined} dense button onClick={(e) => handleLocationsChange(e, loc.id)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={loc.selected}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText id={loc.id.toString()} primary={`${loc.business_name} - ${loc.name}`} />
                </ListItem>
              );
            })}
          </List>
        </Paper>
        <DialogActions >
          <Button onClick={handleDialogBoxCancel} variant={'outlined'} style={{ backgroundColor: "#ff1744", color: GlobalStyles.textWhite, }} autoFocus>
            Cancel
          </Button>
          <Button onClick={handlePrev} startIcon={<NavigateBefore />} variant={'outlined'} style={{ backgroundColor: GlobalStyles.primaryColor, color: GlobalStyles.textWhite, }} color="primary">
            Go Back
          </Button>
          <Button onClick={handleNext} startIcon={<NavigateNext />} variant={'outlined'} style={{ backgroundColor: GlobalStyles.primaryColor, color: GlobalStyles.textWhite, }} color="primary">
            Next
          </Button>
        </DialogActions>
        <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleCloseToast}>
          <Alert onClose={handleCloseToast} severity={toast.variant}>
            {toast.message}
          </Alert>
        </Snackbar>
      </>
    )
  }


  interface RenderPermissionListProps {
    setSelectedPermissionList: (state: any) => void,
    setPermissionList: (state: any) => void,
    handleNext: (state: any) => void,
    handlePrev: (state: any) => void,
    permissionList: any,
    selectedLocationsList: any,
    selectedPermissionList: any
  }

  const RenderPermissionList = (props: RenderPermissionListProps) => {

    const [allPermissionsSelected, setAllPermissionsSelected] = useState(false)

    const [selectedPermissionCount, setSelectedPermissionCount] = useState(0);
    const [setSelectedPermissionList, handleNext, handlePrev, setPermissionList,
      permissionList, selectedPermissionList] = [props.setSelectedPermissionList, props.handleNext,
      props.handlePrev, props.setPermissionList, props.permissionList, props.selectedPermissionList];
      
    // const [checkArray, setCheckArray ] = useState(new Array(permissionList.length).fill(false));
    // var updatedPermissionList = cloneDeep(permissionList)


    const handleSelectAllChange = (e: any) => {
      setAllPermissionsSelected(e.target.checked)
     for(let i=0; i<permissionList.length; i++) {
      permissionList[i].selected = e.target.checked;
      }
    setPermissionList(permissionList);
    if(e.target.checked){
        // setCheckArray(new Array(permissionList.length).fill(true))
        setSelectedPermissionList(permissionList);
        setSelectedPermissionCount(permissionList.length)
      }
      else{
        // setCheckArray(new Array(permissionList.length).fill(false));
        setSelectedPermissionList([]);
        setSelectedPermissionCount(0);
      }
    }

    const handlePermissionsChange = (event: any, per: any, index: any) => {
      setAllPermissionsSelected(false)
      if (event && event.target) {
        permissionList[index].selected = !per.selected;
        setPermissionList(permissionList);
        if (!per.selected) {
          var selectedPermListLocal = selectedPermissionList.filter((p: any, ind: any) => ind != index);
          setSelectedPermissionList(selectedPermListLocal);
          setSelectedPermissionCount(selectedPermissionCount-1);
        }
        else {
          setSelectedPermissionList([...selectedPermissionList, permissionList[index]]);
          setSelectedPermissionCount(selectedPermissionCount+1);
        }
      }
    };



    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          < InputLabel
            id="perm-list"
            style={{ color: GlobalStyles.primaryColor, alignSelf: 'flex-start' }}
          >
            {`Permission(s) selected - ${selectedPermissionCount}`}
          </InputLabel>
          <FormControlLabel
            style={{ margin: '-8px' }}
            checked={allPermissionsSelected}
            onChange={handleSelectAllChange}
            control={<Checkbox />}
            label={`Select all`}
          />

        </div>
        <Paper style={{ maxHeight: 200, overflow: 'auto', marginTop: 15, marginBottom: 20 }}>
          <List className={classes.root}>
            {permissionList && permissionList.map((p: any, index: any) => {
              return (
                <ListItem key={p.id} role={undefined} dense button onClick={(e) => handlePermissionsChange(e, p, index)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={p.selected}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText id={p.id} primary={p.name} />
                </ListItem>
              );
            })}
          </List>
        </Paper>
        <DialogActions >
          <Button onClick={handleDialogBoxCancel} variant={'outlined'} style={{ backgroundColor: "#ff1744", color: GlobalStyles.textWhite, }} autoFocus>
            Cancel
          </Button>
          <Button onClick={handlePrev} startIcon={<NavigateBefore />} variant={'outlined'} style={{ backgroundColor: GlobalStyles.primaryColor, color: GlobalStyles.textWhite, }} color="primary">
            Go Back
          </Button>
          <Button onClick={handleNext} startIcon={<NavigateNext />} variant={'outlined'} style={{ backgroundColor: GlobalStyles.primaryColor, color: GlobalStyles.textWhite, }} color="primary">
            Next
          </Button>
        </DialogActions>
        <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleCloseToast}>
          <Alert onClose={handleCloseToast} severity={toast.variant}>
            {toast.message}
          </Alert>
        </Snackbar>
      </>
    )
  }

  const handleCloseToast = (event?: React.SyntheticEvent, reason?: string) => {
    setToast({ open: false, message: '', variant: toast.variant });
  };

  const rand = () => {
    return Math.round(Math.random() * 20) - 10;
  }
  const getModalStyle = () => {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }





  const [modalStyle] = React.useState(getModalStyle);
  const renderAddUserDialogBox = (setUserDetails) => {


    // getModalStyle is not a pure function, we roll the style only on the first render

    return (
      <div>
        {
          <Dialog
            open={isAddNewUserDialogOpen}
            onClose={handleDialogBoxCancel}
            fullWidth={true}
            maxWidth="sm"
          // style={{ backgroundColor :GlobalStyles.infoBackground }}

          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>New user</DialogTitle>
            <DialogContent dividers={true} style={{ padding: "10px", alignItems: 'center' }}>
              {!dialogLoading &&
                (<div className={classes.dialogContent}  >
                  <RenderUserDetails activeStep={0} userData={props.userData} showToast={props.showToast} />
                </div>)
              }
              {
                dialogLoading &&
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              }
            </DialogContent>
          </Dialog>
        }
      </div>
    )
  }


  const navigateToUserDetailScreen = (rowData?: any) => {
    if(rowData){
      const userDetailUrl = '/web/users/' + rowData.id
      let data: any = {}
      data['userData'] = rowData
      props.history.push(userDetailUrl, data);
    }
    else{
      const userCreateURl = '/web/users/new'
      props.history.push(userCreateURl);
    }
  }


  const handleEditClick = (rowData: any) => {

    navigateToUserDetailScreen(rowData && rowData)
  }

  const renderTable = () => {
    return (
      <div>
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              {
                title: 'Action',
                render: (rowData: any) => {
                  if (true) {
                    return (
                      <div style={{ minWidth: 50 }}>
                        <IconButton onClick={() => handleEditClick(rowData)} >
                          <EditIcon />
                        </IconButton>
                      </div>
                    )
                  }
                  else {
                    return null
                  }

                },
              },
              { title: 'Username', field: 'username', editable: 'never', },
              { title: 'First Name', field: 'first_name', editable: 'never', },
              { title: 'Last Name', field: 'last_name', editable: 'never', },
              { title: 'Email', field: 'email', editable: 'never', },
              {
                title: 'Last Login',
                field: 'last_login',
                editable: 'never',
                emptyValue: 'Never',
                render: (rowData: any) => {

                  if (rowData.last_login != null) {
                    return (<div style={{ minWidth: rowData.last_login != null ? "150px" : null }}>  {moment(rowData.last_login).format('YYYY-MM-DD HH:mm')}  </div>)
                  }
                  return ' ';
                },
              },
              {
                title: 'Pin',
                field: 'pin',
                editComponent: props => (
                  <Input
                    type="text"
                    value={props.value}
                    inputProps={{
                      'aria-label': 'pin',
                      'maxLength': 4
                    }}
                    onChange={e => props.onChange(e.target.value)}
                  />)

              },
              {
                title: 'Enabled',
                field: 'is_active',
                type: 'boolean',
                sorting: false,
                render: rowData => <Switch
                  checked={rowData.is_active}
                  // onClick={() => handleSwitchChange('active',rowData)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              },
              {
                title: 'Web Login',
                field: 'allow_web_login',
                type: 'boolean',
                sorting: false,
                render: rowData => <Switch
                  checked={rowData.allow_web_login}
                  // onClick={() => handleSwitchChange('active',rowData)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              },
              {
                title: 'Device Login',
                field: 'allow_device_login',
                type: 'boolean',
                sorting: false,
                render: rowData => <Switch
                  checked={rowData.allow_device_login}
                  // onClick={() => handleSwitchChange('active',rowData)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                if (!isLoading) {
                  const sb = props.userData.selectedBusiness;
                  if (sb && sb.business_id) {
                    replaceHistory(query, props.history);
                    let pageData = {
                      data: [] as User[],
                      totalCount: 0,
                      page: query.page
                    };
                    setLoading(true);
                    let orderBy = '';
                    if (query.orderBy) {
                      orderBy = query.orderBy.field as string;
                      orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;

                    }
                    getUsersByPage(
                      sb.business_id,
                      query.page + 1,
                      query.pageSize,
                      query.search,
                      orderBy
                    )
                      .catch(handleError) // handle network issues
                      .then(checkStatus)
                      .then(parseJSON)
                      .then((data: PaginatedResponse<User>) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                        pageData = {
                          data: data.results,
                          totalCount: data.count,
                          page: query.page
                        };
                        setTableQuery({
                          ...tableQuery,
                          orderBy,
                          totalCount: data.count,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        setLoading(false);
                        resolve(pageData);
                      })
                      .catch((error: any) => {
                        if (error) {
                          if (error) {
                            if (error.status && error.status === 500) {
                              setEmptyDataSourceMessage(ERROR_500);
                              setErrorStatusVisible(false);
                            } else if ((error.status === 403 || error.status === 401)
                            ) {
                              setEmptyDataSourceMessage(ERROR_MSG);
                              setErrorStatusVisible(true);
                            } else {
                              setEmptyDataSourceMessage(ERROR_MSG);
                              setErrorStatusVisible(false);
                            }
                          }
                        }
                        setTableQuery({
                          ...tableQuery,
                          totalCount: 0,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        setLoading(false);
                        resolve(pageData);
                      });
                  }
                }
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Users'}
                icon={SVG_ICONS.users} />
            }
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
              {
                icon: 'add_box',
                tooltip: 'Add user',
                isFreeAction: true,
                onClick: () => navigateToUserDetailScreen(),
              }
            ]}
          // editable={tableEditableProp}
          />
        </div>
      </div>
    )
  }


  return (
    <div>
      {isAddNewUserDialogOpen ? renderAddUserDialogBox(setUserDetails) : renderTable()}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    businessList: state.sharedModuleData.businessList,
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBusinessList: () => dispatch(getBusinessListFromAPI()),
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagerScreen);
