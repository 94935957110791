import { Permission, ClassWithStaticPermission } from '../models';

export const getSpecificUserPermissions = (userPermissions: Permission[], dataModel: string) => {
    return userPermissions.filter((p) => p.codename.includes(dataModel));
};

export const hasAddPermission = (
    isSuperUser: boolean,
    userPermissions: Permission[],
    dataModel: string
) => {
    if (isSuperUser) {
        return true;
    }
    if (userPermissions) {
        const permission = 'add_' + dataModel;
        return userPermissions.filter((p) => p.codename === permission).length > 0;
    }
    return false;
};

export const hasUpdatePermission = (
    isSuperUser: boolean,
    userPermissions: Permission[],
    dataModel: string
) => {
    if (isSuperUser) {
        return true;
    }
    if (userPermissions) {
        const permission = 'change_' + dataModel;
        return userPermissions.filter((p) => p.codename === permission).length > 0;
    }
    return false;
};

export const hasUpdateBusinessQueuePermission = (
    isSuperUser: boolean,
    // userPermissions: Permission[],
    // dataModel: string
) => {
    if (isSuperUser) {
        return true;
    }
    // if (userPermissions) {
    //     const permission = 'change_' + dataModel;
    //     return userPermissions.filter((p) => p.codename === permission).length > 0;
    // }
    return false;
};

export const hasDeletePermission = (
    isSuperUser: boolean,
    userPermissions: Permission[],
    dataModel: string
) => {
    if (isSuperUser) {
        return true;
    }
    if (userPermissions) {
        const permission = 'delete_' + dataModel;
        return userPermissions.filter((p) => p.codename === permission).length > 0;
    }
    return false;
};

export const hasRequiredPermissions = (
    userPermissions: Permission[],
    dataModel: string,
    requiredPermissions: any
) => {
    const permissionKeys = Object.keys(requiredPermissions);
    permissionKeys.forEach((p) => (p = p + '_' + dataModel));
    let permissions = {};
    userPermissions.forEach((p) => {
        permissions[p.codename] = permissionKeys.includes(p.codename);
    });
    return permissions;
};

export const isSuperAdmin = (userProps) => {
    const { userData } = userProps.userData.userData;
    if (userData) {
        return userData.is_superuser;
    }
    return false;
};

export const hasPermission =(
    userPermissions: Permission[],
    requiredPermissions: any,
    isSuperUser: boolean = false
) => {
    if (isSuperUser) {
        return true;
    }
    const permissionKey = requiredPermissions;
    
    let permission =userPermissions.filter((p)=> p.codename === permissionKey)
    // console.log('PERMISSION',permission)
    if (permission){
        if(permission.length === 1){
            return true
        }
        return false
    }
};