import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, TextField, CircularProgress, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, Select, MenuItem,
  FormControlLabel, Checkbox } from '@material-ui/core';

import { PaginatedResponse } from '../../../shared/models';

import { API_URLS, getAllUsers, getCommodityGroups, getCustomerOrderPickGroupUsers, getCustomerOrderPickGroups, getCustomerOrders, getLocations, saveCustomerOrderPickGroup, toggleCustomerOrderFinalize } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar, ConfirmModal, } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';

import { Filter, FilterType } from '../../../shared/models/Filter';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import MaterialTable, { MTableToolbar } from 'material-table';
import { getMasterCompaniesFullList } from '../../../master-data/api'
import TransferList from '../../../shared/components/TransferList/TransferList';
import { hasPermission } from '../../../shared/lib/PermissionManager';
import { Toast, ToastError, ToastSuccess, enqueueSnackbar } from '../../../shared/components/Notifier';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'left',
      justifyContent: 'left',

    },
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },

    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.primaryColor,
      },
      '&:after': {
        borderColor: GlobalStyles.primaryColor,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

interface OrderPickGroup {
  id ?: string
  name: string
  location: any
  commodityGroups ?: any[]

}
const ConfirmToggleFinalizeModalData = {
  isOpen: false,
  message1: 'Are you sure you want to finalize this order?',
  message2: "",
  title: 'Confirm Finalize',
  confirmBtnText: 'Yes',
  onCancel: () => { },
  onConfirm: () => { }
};

const TOGGLE_FINALIZE_ORDER = 'toggle_finalize_order'



const CustomerOrderList = (props: any) => {
  const resetNewLocation = () => {
    return {
      name: "",
      commodityGroups: [],
      location: ""
    }
  }
  const classes = useStyles({});
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  let userTableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [userEmptyDataSourceMessage, setUserEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [userTableQuery, setUserTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [isUsersLoading, setUsersLoading] = useState(false);
  const [hasCGLoaded, sethasCGLoaded] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allCommodityGroups, setAllCommodityGroups] = useState([]);
  const [selectedCommodityGroups, setSelectedCommodityGroups] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false);
  const [userDialogBoxOpen, setUserDialogBoxOpen] = useState(false);
  const [selectedOrderPickGroup, setSelectedOrderPickGroup] = useState<OrderPickGroup>(resetNewLocation())
  const [selectedAddUser, setSelectedAddUser] = useState<any>()
  const [selectedEditUser, setSelectedEditUser] = useState<any>()
  const [selectedAddUserCanFinalize, setSelectedAddUserCanFinalize] = useState<boolean>(false)
  const [allAddUsers, setAllAddUsers] = useState<any>()
  const [userEditMode, setUserEditMode] = useState<any>()
  const [confirmToggleFinalizeModalData, setConfirmToggleFinalizeModalData] = useState(ConfirmToggleFinalizeModalData);
  const userPermissions = props.userData.userData.userData.user_permissions
  const isSuperUser = props.userData.userData.userData.is_superuser

  

  

  useEffect(() => {
    const sb = props.userData.selectedBusiness;
    

  }, [])

  const onNewOrderPickGroupClick = () => {
    setDialogBoxOpen(true);
    setSelectedOrderPickGroup(resetNewLocation())
    setSelectedCommodityGroups([])
    setSelectedUsers([])
    setAllAddUsers(allUsers)
    setUserEditMode(false)
    setSelectedEditUser(null)
  }
  const setEditOrderPickGroup = (rowData) => {
    setSelectedOrderPickGroup(rowData)
    setSelectedCommodityGroups(rowData.commodityGroups)
    setSelectedUsers(rowData.users)
    const newallAddUsers = allUsers.filter(a => {
      const found = rowData.users.find(ru => ru.user.id === a.id)
      return !found
    })
    setAllAddUsers(newallAddUsers)
    setDialogBoxOpen(true);
  }
  const handleDialogBoxCancel = () => {
    setDialogBoxOpen(false);
    setSelectedOrderPickGroup(resetNewLocation())
    setSelectedCommodityGroups([])
  }
  const handleAddUserDialogBox = () => {
    setUserDialogBoxOpen(true);
    
  }
  const handleEditUserDialogBox = (rowData) => {
    setUserDialogBoxOpen(true);
    setSelectedEditUser(rowData)
    setSelectedAddUserCanFinalize(rowData.can_finalize_order)
    setUserEditMode(true)
  }
  const handleRemoveUser = (rowData) => {
    const newUsers = selectedUsers.filter(s => s.user.id !== rowData.user.id)
    setSelectedUsers(newUsers)
    allAddUsers.push(rowData)
    setAllAddUsers([...allAddUsers])
  }
  const handleUserDialogBoxCancel = () => {
    setUserDialogBoxOpen(false);
    setSelectedAddUser(null)
    setSelectedAddUserCanFinalize(false)
    setUserEditMode(false)
    setSelectedEditUser(null)
  }
  const handleSave = () => {
    setDialogLoading(true)
    const sb = props.userData.selectedBusiness;
    let opgId = null;
    if (selectedOrderPickGroup && selectedOrderPickGroup.hasOwnProperty('id')) {
      opgId = selectedOrderPickGroup.id
    }
    const data = {...selectedOrderPickGroup}
    data['commodityGroups'] = selectedCommodityGroups
    data['business'] = sb.business_id
    data['users'] = selectedUsers
    data['location'] = selectedOrderPickGroup.location.id
    saveCustomerOrderPickGroup(data, opgId).catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
      console.log(data)
      setDialogLoading(false)
      handleDialogBoxCancel()
      if (tableRef) {
        tableRef.current.onQueryChange()
      }
    })
  }

  const handleNameChange = (e) => {
    selectedOrderPickGroup.name = e.target.value
    setSelectedOrderPickGroup({...selectedOrderPickGroup})
  }
  const handleSelectedLocationChange = (e) => {
    const locationId = e.target.value
    const location = allLocations.find(l => l.id === locationId)
    selectedOrderPickGroup.location = location
    setSelectedOrderPickGroup({...selectedOrderPickGroup})
  }
  const handleSelectedAddUser = (e) => {
    setSelectedAddUser(e.target.value)
  }

  const handleAddUser = () => {
    if (selectedUsers && !userEditMode) {
      if (selectedAddUser) {
        const existing = selectedUsers.find(s => s.user.id === selectedAddUser)
        if (existing) {
          existing.can_finalize_order = selectedAddUserCanFinalize
        } else {
          const user = allAddUsers.find(a => a.id === selectedAddUser)
          const data = {
            user: {...user},
            can_finalize_order: selectedAddUserCanFinalize,
            order_pick_group: selectedOrderPickGroup.id
          }
          selectedUsers.push(data)
        }
        setSelectedUsers([...selectedUsers])
        const newallAddUsers = allAddUsers.filter(a => a.id !== selectedAddUser)
        setAllAddUsers(newallAddUsers)
      }
    }
    if (userEditMode) {
      const existing = selectedUsers.find(s => s.user.id === selectedEditUser.user.id)
      if (existing) {
        existing.can_finalize_order = selectedAddUserCanFinalize
      } 
      setSelectedUsers([...selectedUsers])
    }
    setSelectedAddUser(null)
    setSelectedAddUserCanFinalize(false)
    setUserDialogBoxOpen(false)
  }

  const toggleFinalizeOrder = (order) => {
    const sb = props.userData.selectedBusiness;
    let location_id = props.userData.selectedLocations[0].id
    toggleCustomerOrderFinalize(order.order_id, sb.business_id,
      location_id,).then(() => {
      props.showToast({
        message: 'Successfully changed the status',
        options: ToastSuccess
      });
      if (tableRef) {
        tableRef.current.onQueryChange()
      }
    }).catch((error) => {
      console.error(error)
      props.showToast({
        message: 'Failed to change the status',
        options: ToastError
      });
    })
  }

  const confirmToggleFinalize = (order) => {
    let message = ""
    if (order.is_finalized) {
      message = "Are you sure you want to un-finalize this order?"
    } else {
      message = "Are you sure you want to finalize this order?"
    }
    setConfirmToggleFinalizeModalData({
      ...confirmToggleFinalizeModalData,
      message1: message,
      isOpen: true,
      onCancel: () => {
        setConfirmToggleFinalizeModalData({ ...confirmToggleFinalizeModalData, isOpen: false });
      },
      onConfirm: () => {
        setConfirmToggleFinalizeModalData({ ...confirmToggleFinalizeModalData, isOpen: false });
        toggleFinalizeOrder(order)
      }
    });
  };





  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <ExportStatusBar isVisible={isExporting} />
      {
        // hasCGLoaded && 
        <div style={{ maxWidth: '100%' }}>
          <div className="table-wrapper">
            <MaterialTable
              options={{
                ...tableOptions(tableQuery),
                thirdSortClick: false,
                draggable: false,
                sorting: true
              }}
              tableRef={tableRef}
              localization={{
                body: {
                  emptyDataSourceMessage: emptyDataSourceMessage
                }
              }}
              columns={[
                { title: 'Number', field: 'order_number' },
                { title: 'Net Total', field: 'net_total' },
                { 
                  title: 'Pick Groups', 
                  field: 'pick_groups',
                  render: rowData => {
                    if (rowData) {
                      const pickGroups = rowData.pick_groups.map(p => p.name).join(", ")
                      return (
                        <div>
                          {pickGroups}
                        </div>
                      )

                    }
                    return null
                  },
                },
                { 
                  title: 'Is Finalized', 
                  field: 'is_finalized',
                  render: rowData => {
                    if (rowData) {
                      return (
                        <div>
                          <Checkbox checked={rowData.is_finalized} 
                                    onClick={() => confirmToggleFinalize(rowData)} 
                                    disabled={!hasPermission(userPermissions, TOGGLE_FINALIZE_ORDER, isSuperUser)} />
                        </div>
                      )

                    }
                    return null
                  },
                },
                

              ]}
              data={query =>

                new Promise((resolve, reject) => {
                  replaceHistory(query, props.history);
                  let pageData = {
                    data: [] as any[],
                    totalCount: 0,
                    page: query.page
                  };
                  let orderBy = '';
                  if (query.orderBy) {
                    orderBy = query.orderBy.field as string;
                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                  }
                  setLoading(true);
                  const sb = props.userData.selectedBusiness;
                  let location_id = props.userData.selectedLocations[0].id

                  getCustomerOrders(
                    sb.business_id,
                    location_id,
                    query.page + 1,
                    query.pageSize,
                    query.search,
                    orderBy

                  )
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<any>) => {
                      setLoading(false);
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      const results = data.results
                      pageData = {
                        data: results,
                        totalCount: data.count,
                        page: query.page
                      };
                      setTableQuery({
                        ...tableQuery,
                        orderBy,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      resolve(pageData);
                    })
                    .catch((error: any) => {
                      console.error(error)
                      if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_500);
                          setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)
                        ) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      setLoading(false);

                      resolve(pageData);
                    });
                })
              }
              title={
                <CustomTableTitle
                  history={props.history}
                  title={'Pending Customer Orders'}
                  icon={SVG_ICONS.product} />
              }
              actions={[
                // {
                //   icon: 'edit',
                //   tooltip: 'Edit',
                //   hidden: !hasPermission(userPermissions, EDIT_ORDER_PICK_GROUP, isSuperUser),
                //   onClick: (event, rowData) => setEditOrderPickGroup(rowData),
                // },
                // {
                //   icon: 'add',
                //   tooltip: 'Add',
                //   isFreeAction: true,
                //   hidden: !hasPermission(userPermissions, ADD_ORDER_PICK_GROUP, isSuperUser),
                //   onClick: onNewOrderPickGroupClick,
                // },
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                },
              ]}
            />
          </div>
        </div>
      }
      {/* {
        
          !hasCGLoaded &&
          <div style={{ textAlign: 'center', marginTop: 5 }}>
            <CircularProgress />
          </div>
        
      } */}
      <ConfirmModal
        isOpen={confirmToggleFinalizeModalData.isOpen}
        onCancel={confirmToggleFinalizeModalData.onCancel}
        onConfirm={confirmToggleFinalizeModalData.onConfirm}
        confirmBtnText={confirmToggleFinalizeModalData.confirmBtnText}
        dialogTitle={confirmToggleFinalizeModalData.title}
        dialogMessage1={confirmToggleFinalizeModalData.message1}
        dialogMessage1Color={GlobalStyles.red}
        dialogMessage2={confirmToggleFinalizeModalData.message2}
      />
      </div>
      
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrderList);
