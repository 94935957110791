import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// import {
//   Typography, Select, Menu, MenuItem, FormControl, InputLabel, Input, Link, SvgIcon,
//   Card, CardContent, CircularProgress, Divider, FormControlLabel, Grid, TextField,
//   IconButton, Checkbox, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText,
//   DialogTitle, ListSubheader, LinearProgress, Button, Snackbar, Icon, Switch, ListItemSecondaryAction,
//   ListItemText, 

// } from '@material-ui/core';
import { CompanyProductInstanceModal } from '../../components'
import { PaginatedResponse , ToastType} from '../../../shared/models';
import { hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';

import { createNewCompanyProduct, getAllCompanyProductsList, getMasterCompanyData, getMasterCompaniesFullList } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, useQuery, tableOptions } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Filter, FilterType } from '../../../shared/models/Filter';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import { MasterCompanyList } from '../../models';
import { blue, orange, red } from '@material-ui/core/colors';
import sortBy from 'lodash/sortBy'
import EditIcon from '@material-ui/icons/Edit';
import {  Link as RLink } from "react-router-dom";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mpLink: {
      "&:hover": {
        cursor: 'default',
        // textDecoration: 'underline'
      },
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

const permission_model = 'masterproduct'


const FILTERS: Filter[] = [
  {
    id: 'f1',
    label: 'Master Product ID',
    types: [
      { id: '1', value: 'master_product_id', label: 'is equal' },
      { id: '2', value: 'mp_id_range', label: 'between' }
    ],
    selectedType: 'master_product_id',
    value: '',
    url: null,
    minValue: '',
    maxValue: ''
  },
  
  {
    id: 'f2',
    label: 'Supplier Company',
    name:'SUPPLIERCOMPANY',
    types: [
      { id: '1', value: 'company_supp_ids', label: 'is equal',showSuppliers: true },
      // { id: '2', value: 'master_company_id__not_in', label: 'is not equal' },
      // { id: '3', value: 'company_supp_ids', label: 'is supplier', },
      // { id: '2', value: 'master_company_id__isnull', label: 'is none', hasSelect: false },
    ],
    selectedType: 'company_supp_ids',
    value: [],
    url: 'korona/master/company-list/',
    labelField: 'name',
    bindValue: 'id',
    isResponsePaginated: true,
    data:[]
    
  },
  {
    id: 'f3',
    label: 'Active',
    types: [
      { id: '1', value: 'company_products_is', label: 'is equal' },
    ],
    url: null,
    selectedType: 'company_products_is',
    value: '',
    choices: [
      { id: '1', value: 'active', label: 'Active' },
      { id: '2', value: 'not_active', label: 'Inactive' },
    ],
  },
  {
		id: 'f4',
		label: 'Barcode',
		types: [
			{ id: '1', value: 'mp_identifier', label: 'is equal' },
      { id: '2', value: 'mp_identifier_range', label: 'between' },
			{ id: '3', value: 'mp_identifier__icontains', label: 'contains' },
		],
		selectedType: 'mp_identifier',
		value: '',
		url: null,
    minValue:'',
    maxValue:''

	},
];

const CompanyProductsListScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  let locationQuery = useQuery(useLocation);
  const [filterVisible, showFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [fromCompanyFilter, setFromCompanyFilter] = useState(null)
  const [intialLoad, setInitialLoad] = useState(true)

  const [toast, setToast] = useState({ open: false, message: '', variant: 'success' } as ToastType);
  const [openInstanceGroupModal, setOpenInstanceGroupModal] = useState(false);
  const [instanceGroupModalMode, setInstanceGroupModalMode] = useState('')
  const [isModalLoading, setModalLoading] = useState(false)
  const [newRecordCreated, setnewRecordCreated] = useState(false)

  const [filterCompaniesList, setFilterCompaniesList] = useState(null)
  const [updatedFilters, setUpdatedFilters] = useState(null as any)
  

  useEffect(()=>{
    getMasterCompaniesFullList()
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then((data:any) => {
      // console.log("results",data)
      setFilterCompaniesList(data);
    })
    .catch((error: any) => {
    });
},[])  

useEffect(()=>{
  if(filterCompaniesList ){
      if(filterCompaniesList.length > 0){
          let currentFilters = [...FILTERS]
          let companyFilterIndexFound = currentFilters.findIndex(cf=>cf.name === 'SUPPLIERCOMPANY')
          if(companyFilterIndexFound > -1){
              currentFilters[companyFilterIndexFound].data = filterCompaniesList
          }
          setUpdatedFilters([...currentFilters])
      }
  }
  
},[filterCompaniesList])

  useEffect(() => {
    console.log("selectedCompany", selectedCompany)
  }, [selectedCompany])

  // useEffect(() => {
  //   if (props && props.location && props.location.state && props.location.state.id && props.location.state.from === 'company') {
  //     console.log("propsss", props)
  //     setFromCompanyFilter(`&master_company_id__in=${props.location.state.id}`)
  //     tableRef.current && tableRef.current.onChangePage(null, 0);
  //   }
  // }, [props.location.state]);


  useEffect(() => {
    if (!isLoading ) {
      tableRef.current && tableRef.current.onChangePage(null, 0);
    }
  }, [filterString]);


  // useEffect(() => {
  //   tableRef.current && tableRef.current.onQueryChange();
  // }, [selectedCompany]);

  // useEffect(() => {
  //   setSelectedCompany(null)
  //   getMasterCompanyData()
  //     .catch(handleError) // handle network issues
  //     .then(checkStatus)
  //     .then(parseJSON)
  //     .then((data: PaginatedResponse<MasterCompanyList>) => {
  //       // console.log("results",data)
  //       setCompanies(data.results);
  //     })
  //     .catch((error: any) => {
  //     });
  // }, []);


  useEffect(() => {

    const activeFilters = selectedFilters.filter(
      (f: Filter) => (f.selectedType && f.value && f.value.length > 0) || (f.selectedType.includes('range') && (f.minValue || f.maxValue)) || (f.types.find(ele => ele.value == f.selectedType).hasSelect === false)
    );
    let filterString = '';
    activeFilters.forEach((f: Filter) => {
      let filterValue = '';
      let isRangeFilter = f.selectedType.includes('range');
      if (f.url && Array.isArray(f.value)) {
        const mappedValues = f.value.map((v) => v[f.bindValue]);
        filterValue = mappedValues.toString();
      }

      else if (!isRangeFilter) {

        // value is string
        filterValue = f.value.toString();

      }
      if (isRangeFilter) {
        if (f.minValue) {
          filterString += `&${f.selectedType}_min=${f.minValue}`;
        }
        if (f.maxValue) {
          filterString += `&${f.selectedType}_max=${f.maxValue}`;
        }
      }
      else if (f.types.find(ele => ele.value == f.selectedType).hasSelect === false) {
        filterString += `&${f.selectedType}=none`;
      }
      else {
        filterString += `&${f.selectedType}=${filterValue}`;
      }
    });
    console.log("FILTER-STRING", filterString, activeFilters)
    setFilterString(filterString);
  }, [selectedFilters]);

  const toggleFilterPanel = () => {
    showFilters(!filterVisible);
  };

  const addFilter = (filter) => {
    if (!selectedFilters.find(f => f.id === filter.id)) {
      const filters = [...selectedFilters];
      filters.push(filter);
      setSelectedFilters(filters);
    }
  };

  const removeFilter = (index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1);
    setSelectedFilters(filters);
  };

  const updateFilter = (filter, index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1, filter);
    // console.log("Updated-Filter",filters)
    setSelectedFilters(filters);
  };



  const navigateToMasterProductCompanyInstanceDetailScreen = (data: any) => {
    const companyProductDetailUrl = MasterDataRouterPaths.companyProducts + '/' + data.id;
    window.open(`${companyProductDetailUrl}`)
    // props.history.push(companyProductDetailUrl, data);
  };



  const tableRefresh = () => {
    setFromCompanyFilter(null)
    tableRef.current && tableRef.current.onQueryChange()
  }


  const renderTable = () => {
    return (
      <div>
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <div style={{ maxWidth: '100%' }}>
          <div className="table-wrapper">
            <MaterialTable
              options={{
                ...tableOptions(tableQuery),
                thirdSortClick: false,
                draggable: false,
                sorting: true
              }}
              components={{
                Toolbar: (props) => (
                  <>
                    <MTableToolbar {...props} />
                    {filterVisible && (
                      <>
                        <FilterRow
                          {...props}
                          renderAvailableFilters={
                            <AvailableFilters
                              filters={updatedFilters ? updatedFilters : FILTERS}
                              addFilter={addFilter}
                            />
                          }
                          renderSelectedFilters={
                            <SelectedFilters
                              selectedFilters={selectedFilters}
                              removeFilter={removeFilter}
                              updateFilter={updateFilter}
                              businessId={null}
                            />
                          }
                        />
                      </>
                    )}
                  </>
                ),
               
              }}
              tableRef={tableRef}
              localization={{
                body: {
                  emptyDataSourceMessage: emptyDataSourceMessage
                }
              }}
              columns={[
                { title: 'ID', field: 'id', editable: 'never' },
                {
                  title: 'Mp Id',
                  field: 'master_product',
                  editable: 'never',
                  render: (rowData: any) => {

                    if (rowData.master_product_data != null) {
                      return (<div>  {rowData.master_product_data.id}  </div>)
                    }
                    return '';
                  }
                },
                {
                  title: 'Mp Name',
                  field: 'master_product',
                  editable: 'never',
                  render: (rowData: any) => {

                    if (rowData.master_product_data != null) {
                      return (<div style={{ minWidth: rowData.master_product_data.name != null ? "350px" : null }}>  {rowData.master_product_data.name}  </div>)
                    }
                    return '';
                  }
                },
                {
                  title: 'Company Name',
                  field: 'master_company',
                  editable: 'never',
                  render: (rowData: any) => {

                    if (rowData.master_company_data != null) {
                      return (<div style={{ minWidth: rowData.master_company_data.name != null ? "350px" : null }}>  {rowData.master_company_data.name}  </div>)
                    }
                    return ' ';
                  }
                },
                {
                  title: 'Active',
                  field: 'is_active',
                  type: 'boolean',
                  sorting: false,
                  render: (rowData: any) => (
                    <FiberManualRecordIcon
                      style={{ color: rowData.is_active ? GlobalStyles.green : GlobalStyles.red }}
                    />
                  ),
                },
              ]}
              data={query =>
                new Promise((resolve, reject) => {
                    replaceHistory(query, props.history);
                    let pageData = {
                      data: [] as any[],
                      totalCount: 0,
                      page: query.page
                    };
                    let orderBy = '';
                    if (query.orderBy) {
                      orderBy = query.orderBy.field as string;
                      orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                    }
                    let filters = fromCompanyFilter ? fromCompanyFilter : filterString
                    console.log("filters", filters)
                    if (!filters) {
                      resolve(pageData);
                    } else {
                      setLoading(true);

                      getAllCompanyProductsList(
                        query.page + 1,
                        query.pageSize,
                        query.search,
                        orderBy,
                        filters
                      )
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then((data: PaginatedResponse<any>) => {
                          setLoading(false);
                          setErrorStatusVisible(false);
                          setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                          pageData = {
                            data: data.results,
                            totalCount: data.count,
                            page: query.page
                          };
                          setTableQuery({
                            ...tableQuery,
                            orderBy,
                            totalCount: data.count,
                            page: query.page,
                            pageSize: query.pageSize
                          });
                          resolve(pageData);
                          setInitialLoad(false)

                        })
                        .catch((error: any) => {
                          if (error) {
                            if (error.status && error.status === 500) {
                              setEmptyDataSourceMessage(ERROR_500);
                              setErrorStatusVisible(false);
                            } else if ((error.status === 403 || error.status === 401)
                            ) {
                              setEmptyDataSourceMessage(ERROR_MSG);
                              setErrorStatusVisible(true);
                            } else {
                              setEmptyDataSourceMessage(ERROR_MSG);
                              setErrorStatusVisible(false);
                            }
                          }
                          setTableQuery({
                            ...tableQuery,
                            totalCount: 0,
                            page: query.page,
                            pageSize: query.pageSize
                          });
                          setLoading(false);

                          resolve(pageData);
                        });
                    }
                })
              }
              title={
                <CustomTableTitle
                  history={props.history}
                  title={'Company Products'}
                  icon={SVG_ICONS.product}
                />
              }
              actions={[
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRefresh(),
                },
                {
                  icon: 'edit',
                  tooltip: 'Edit',
                  hidden: hasUpdatePermission(props.userData.userData.is_superuser, props.userData.userData.user_permissions, permission_model),
                  onClick: (event, rowData) => navigateToMasterProductCompanyInstanceDetailScreen(rowData),
                  
                },
                {
                  icon: 'filter_list',
                  tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
                  isFreeAction: true,
                  iconProps: {
                    color: filterVisible ? 'primary' : 'inherit',
                  },
                  // hidden: !isSuperAdmin(props),
                  onClick: toggleFilterPanel,
                },
                {
                  icon: 'add',
                  tooltip: 'Add Company Product',
                  isFreeAction: true,
                  onClick: handleAddCompanyProduct,
                },
              ]}
            />

          </div>
        </div>
      </div>
    )
  }

  const handleAddCompanyProduct = ()=>{
    setOpenInstanceGroupModal(true)
    setInstanceGroupModalMode('add')
  }

  // const handleCompanyChange = (event: any) => {
  //   console.log("selectedBusiness", event)
  //   setSelectedCompany(event.target.value);

  // }

  const handleCloseToast = (event?: React.SyntheticEvent, reason?: string) => {
    setToast({ ...toast, open: false, });
  };

  const handleCloseDialog = () => {
    setOpenInstanceGroupModal(false);
    handleCloseToast()
    setInstanceGroupModalMode('')
  };

  


  return (
    <div style={{ maxWidth: '100%' }}>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>
        <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
          Filter by Company:
        </Typography>
        <FormControl
          style={{
            marginRight: 16,
          }}>
          <Select
            value={selectedCompany ? selectedCompany : ''}
            onChange={handleCompanyChange}
            className={classes.select}
          >
            {
              companies &&
              sortBy(companies, 'name').map((b: any) => 
                
                (
                  b.is_supplier ?
                  
                    <MenuItem value={b.id} key={String(b.id)} >
                      <ListItemText primary={b.name}  />
                      <ListItemSecondaryAction>
                        <IconButton edge="end">
                          <SvgIcon htmlColor={GlobalStyles.primaryColor} >
                            <path d={SVG_ICONS.letterS} />
                          </SvgIcon>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </MenuItem>
                    :
                    <MenuItem value={b.id} key={String(b.id)}>
                      {b.name}
                    </MenuItem>
                )
              )}
          </Select>
        </FormControl>
      </div>
      <Divider className={classes.divider} /> */}

      {renderTable()}

      <CompanyProductInstanceModal
        isOpen={openInstanceGroupModal}
        onClose={handleCloseDialog}
        mode={instanceGroupModalMode ? instanceGroupModalMode : null}
        masterProduct={null}
        toast={toast ? toast : null}
        handlResetToast={handleCloseToast}
        userDetails={props.userData.userData.userData}
      />
    </div>

  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(CompanyProductsListScreen);