import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import {
  Typography, Select, Menu, MenuItem, FormControl, InputLabel, Input, Link, SvgIcon,
  Card, CardContent, CircularProgress, Divider, FormControlLabel, Grid, TextField,
  IconButton, Checkbox, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, ListSubheader, LinearProgress, Button, Snackbar, Icon

} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import {
  MasterProduct, AttributeValue, MasterProductIdentifier,
  AttributeTypes, ItemCategory, ItemType, MasterBrand
} from '../../models';
import { PaginatedResponse, ToastType } from '../../../shared/models';
import {
  getMasterProductAttributes, getMasterProductDetail, updateMasterProduct,
  getMasterProductBrand, getMasterItemImages, updateMediaBySelectedAction,
  uploadMasterItemImage, createMediaLinkItem, getMasterBrands, saveMasterProductText, deleteMasterProductAPI, getAllMasterProductsDropdownList, addMasterChildProductLink, deleteMasterProductContainerLinkRecord
} from '../../api';
import { AttributeModal, ProductCodeModal, Image, LinkProductModal, CompanyProductInstanceModal } from '../../components';

import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { ConfirmModal, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { setPageLoading } from '../../../../store/actions';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';
import { BusinessRouterPaths, MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { blue, orange, red } from '@material-ui/core/colors';
import _filter from 'lodash/filter';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { FileDrop } from 'react-file-drop';
import './style.css';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { hasDeletePermission } from '../../../shared/lib/PermissionManager';

import {debounce} from 'lodash'


const isNumeric = (string) => /^\d+(\.\d+)?$/.test(string)

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    card: {
      padding: theme.spacing(1),
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    brandRow: {
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    textFieldEdit: {
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: 'calc(100% - 64px)'
    },
    textFieldView: {
      marginRight: theme.spacing(1),
      marginBottom: 6,
      width: '100%'
    },
    textFieldCardRow: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: '100%'
    },
    button: {
      margin: theme.spacing(1),
    },
    progress: {
      margin: theme.spacing(2),
      justifySelf: 'center'
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 11px'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      alignSelf: 'center'
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '12px'
    },
    panelHeaderText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
    panelRowText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
    noDataRow: {
      width: '100%',
      padding: 12
    },
    rowActionBtn: {
      padding: 8
    },
    greenCircle: {
      color: GlobalStyles.green
    },
    redCircle: {
      color: GlobalStyles.red
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '20rem',
      minWidth: '12rem',
    },
    icon: {
      fill: GlobalStyles.lightTheme,
    },
    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

    },

    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    dnd: {
      border: '1px solid black',
      // display: 'flex',
      // flexWrap: 'wrap',
      // color: 'black',
      padding: '15px',
      borderStyle: 'dashed',
      // justifyContent: 'center',
      marginBottom: '30px'

    }

  }),
);
const barcodesPanel = 'barcodesPanel';
const suppliersPanel = 'suppliersPanel';
const linkedProductsPanel = 'linkedProductsPanel';
const linkedMasterProductsPanel = 'linkedMasterProductsPanel';
const supplierCompaniesPanel = 'supplierCompaniesPanel';

const requiredAttributes = [AttributeTypes.QUANTITY, AttributeTypes.UNIT_OF_MEASURE];
const barcodeDateSortFn = (a: MasterProductIdentifier, b: MasterProductIdentifier) => {
  const dateA = a.effective_date ? new Date(a.effective_date).getTime() : new Date().getTime();
  const dateB = b.effective_date ? new Date(b.effective_date).getTime() : new Date().getTime();
  if ((!a.is_deleted && !b.is_deleted) || (a.is_deleted && b.is_deleted)) {
    return dateB - dateA;
  } else if (a.is_deleted && !b.is_deleted) {
    return 1;
  } else {
    return -1;
  }
};
// const ExpansionPanel = withStyles({
//   root: {
//     marginTop: '16px',
//     border: '1px solid rgba(0, 0, 0, .125)',
//     boxShadow: 'none',
//     '&:not(:last-child)': {
//       borderBottom: 0,
//     },
//     '&:before': {
//       display: 'none',
//     },
//     '&$expanded': {
//       margin: 'auto',
//       marginTop: '16px !important'
//     },
//   },
//   expanded: {},
// })(MuiExpansionPanel);

// const ExpansionPanelSummary = withStyles({
//   root: {
//     backgroundColor: 'rgba(0, 0, 0, .08)',
//     borderBottom: '1px solid rgba(0, 0, 0, .125)',
//     marginBottom: -1,
//     minHeight: 48,
//     '&$expanded': {
//       minHeight: 48,
//     },
//   },
//   content: {
//     '&$expanded': {
//       // margin: '12px 0',
//       margin: '0',
//     },
//     margin: 0
//   },
//   expandIcon: {
//     padding: 8
//   },
//   expanded: {},
// })(MuiExpansionPanelSummary);

// const ExpansionPanelDetails = withStyles(theme => ({
//   root: {
//     //   padding: theme.spacing(2),
//     padding: 0,
//   },
// }))(MuiExpansionPanelDetails);

const AddNewBarcodeModalData = {
  barcodeData: {
    identifier: '',
    description: '',
    effective_date: '1970-01-01',
    expiry_date: null,
    quantity: 1,
    is_deleted: false
  } as MasterProductIdentifier,
  isOpen: true,
  mode: 'add',
  allProductCodes: [] as MasterProductIdentifier[]
};
const ConfirmModalData = {
  isOpen: false,
  message1: 'You will lose all unsaved changes.',
  message2: ' Are you sure you want to leave this page?',
  title: 'Confirm Navigation',
  confirmBtnText: 'Yes',
  onCancel: () => { },
  onConfirm: () => { }
};
const ConfirmDeleteModalData = {
  isOpen: false,
  message1: 'Are you sure you want to delete this product?',
  message2: 'This action cannot be undone!',
  title: 'Confirm Deletion',
  confirmBtnText: 'Yes',
  onCancel: () => { },
  onConfirm: () => { }
};
const ConfirmLinkDeleteModalData = {
  isOpen: false,
  message1: 'Are you sure you want to delete this link?',
  message2: 'This action cannot be undone!',
  title: 'Confirm Deletion',
  confirmBtnText: 'Yes',
  onCancel: () => { },
  onConfirm: () => { }
};

const initalDialogData = {
  open: false,
  business: null as any,
  onClose: null as any,
  mode: 'add',
  linkedRecord: null as any
};

const MasterProductDetailScreen = (props: any) => {
  const userData = props.userData.userData.userData;
  const permission_model = 'masterproduct';
  const classes = useStyles({});
  const [isAttributeModalOpen, setAttributeModalOpen] = useState(false);
  const [productCodeModalData, setProductCodeModalData] = useState(null);
  const [containerProduct, setContainerProduct] = useState(null);
  const [containerProductLoading, setContainerProductLoading] = useState(false);
  const [containerOptionsLoading, setContainerOptionsLoading] = useState(false);
  const [subItemProduct, setSubItemProduct] = useState(null);
  const [pckQtyError, setPckQtyError] = useState("");
  const [allMasterAttributes, setMasterAttributes] = useState<any[]>([]);
  const [allMasterSubItemProductsDropdown, setAllMasterSubItemProductsDropdown] = useState<any[]>([]);
  const [allMasterContainerProductsDropdown, setAllMasterContainerProductsDropdown] = useState<any[]>([]);
  const [linkedContainerSubItemEditRecord, setLinkedContainerSubItemEditRecord] = useState<any>();
  const [confirmModalData, setConfirmModalData] = useState(ConfirmModalData);
  const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(ConfirmDeleteModalData);
  const [confirmLinkDeleteModalData, setConfirmLinkDeleteModalData] = useState(ConfirmLinkDeleteModalData);
  const [productDialogData, setProductDialogData] = useState(initalDialogData);
  const [isLoading, setLoading] = useState(false);
  const [masterProductData, setMasterProductData] = useState(null);
  const [originalMasterProduct, setOriginalMasterProduct] = useState(null);
  const [pageMode, setPageMode] = useState({ type: 'view_mode' });
  const [fieldErrors, setFieldErrors] = useState({});
  const [nameError, setNameError] = useState({ isError: false, message: '' });
  const [expanded, setExpanded] = React.useState(
    { barcodesPanel: true, suppliersPanel: true, linkedProductsPanel: true, descripions: true, supplierCompaniesPanel: true, linkedMasterProductsPanel: true });

  const handleChange = (panel: 'barcodesPanel' | 'suppliersPanel' | 'linkedProductsPanel' | 'descripions' | 'supplierCompaniesPanel' | 'linkedMasterProductsPanel') => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    const xpanel = { ...expanded };
    xpanel[panel] = isExpanded;
    setExpanded(xpanel);
  };
  const [brand, setBrand] = useState([]);
  const [packagingQty, setPackagingQty] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false)
  const [dialogLinkedContainerProdBoxOpen, setDialogLinkedContainerProdBoxOpen] = useState(false)
  const [newBrandActive, setNewBrandActive] = useState("1");
  const [newBrandCompany, setNewBrandCompany] = useState(null);
  const [newBrandName, setNewBrandName] = useState('');
  const [newBrandSelected, setNewBrandSelected] = useState(false);
  const [newBrandCompanyName, setNewBrandCompanyName] = useState('');
  const [newBrandNameExists, setNewBrandNameExists] = useState(false)
  const [disableBrandDetailsButton, setDisableBrandDetailsButton] = useState(true);

  const [mediaUrls, setMediaUrls] = useState([])
  const [userUploadedMedia, setUserUploadedMedia] = useState(null as any)
  const [userSelectedMedia, setUserSelectedMedia] = useState(null)
  const [defaultMediaData, setDefaultMediaData] = useState(null as any)
  const [mediaData, setMediaData] = useState([])
  const [isMediaLoading, setMediaLoading] = useState(false)
  const [showDefaultMedia, setShowDefaultMedia] = useState(false);
  const [selectedMediaForAction, setSelectedMediaForAction] = useState([])
  const [optionsBtnAnchorEl, setOptionsBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  
  const [openInstanceGroupModal, setOpenInstanceGroupModal] = useState(false);
  const [instanceGroupModalMode, setInstanceGroupModalMode] = useState('')
  
  // const [selectedActionIndex, setSelectedActionIndex] = useState(null)
  const [inActiveMedia, setInActiveMedia] = useState([])

  const [changeLocationProductCodes, setChangeLocationProductCodes] = useState(false)
  const fileInputRef = useRef(null);

  // const[descriptionDialogBoxOpen, setDescriptionDialogBoxOpen] = useState(false)
  // const[updatedReceiptText, setUpdatedReceiptText] = useState(null)
  // const [isDialogLoading, setDialogLoading] = useState(false);

  useEffect(() => {
    getMasterBrands()
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        // console.log("results",data)
        setBrand(data);
      })
      .catch((error: any) => {
      });
  }, []);
 
  // useEffect(() => {
  //   getAllMasterProductsDropdownList("")
  //     .catch(handleError) // handle network issues
  //     .then(checkStatus)
  //     .then(parseJSON)
  //     .then((data: any) => {
  //       setAllMasterProductsDropdown(data.results)
  //     })
  //     .catch((error: any) => {
  //     });
  // }, []);

  const onContainerInputChange = (event, value, reason) => {
    if (value) {
      setContainerOptionsLoading(true)
      getAllMasterProductsDropdownList(value)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        let results = data.results
        if (masterProductData) {
          results = results.filter(r => r.id !== masterProductData.id)
        }
        setAllMasterContainerProductsDropdown(results)
        setContainerOptionsLoading(false)
      })
      .catch((error: any) => {
        setContainerOptionsLoading(false)
      });
    } else {
      setAllMasterContainerProductsDropdown([])
    }
  };
  
  const onSubItemInputChange = (event, value, reason) => {
    if (value) {
      getAllMasterProductsDropdownList(value)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setAllMasterSubItemProductsDropdown(data.results)
      })
      .catch((error: any) => {
      });
    } else {
      setAllMasterSubItemProductsDropdown([])
    }
  };

  const handleAddLinkMasterProd = () => {
    if (!containerProduct || !containerProduct.id) {
      props.showToast({
        message: 'Please select container product',
        options: ToastError
      });
      return
    }
    if (pckQtyError) {
      props.showToast({
        message: 'Please fix the error',
        options: ToastError
      });
      return
    }
    
    const data = {
      container_product: containerProduct.id,
      packaging_quantity: packagingQty,
      sub_item_product: masterProductData.id,
    }
    if (linkedContainerSubItemEditRecord) {
      data['id'] = linkedContainerSubItemEditRecord.id
    }
    console.log(data)
    setContainerProductLoading(true)
    addMasterChildProductLink(data).catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
      console.log("Ok")
      setContainerProduct("")
      setSubItemProduct("")
      setPackagingQty("")
      setDialogLinkedContainerProdBoxOpen(false);
      setContainerProductLoading(false)
      props.showToast({
        message: 'Successfully saved the link',
        options: ToastSuccess
      });
    })
    .catch((error: any) => {
      console.error(error)
      setContainerProductLoading(false)
      props.showToast({
        message: 'Failed to save the link',
        options: ToastError
      });
    });
  }

  // useEffect(()=>{
  //   console.log("MasterProductData",masterProductData)
  //   console.log("OriginalMasterProduct",originalMasterProduct)
  // },[originalMasterProduct,masterProductData])


  // useEffect(() => {
  //       console.log("exists",newBrandNameExists)
  // }, [newBrandNameExists]);


  // useEffect(() => {
  //   console.log("PROPS", props)
  // }, [props])

  // useEffect(() => {
  //   console.log("Master-Data",masterProductData)
  // }, [masterProductData]);

  // useEffect(() => {
  //   console.log("NEW-BRAND-COMPANY",newBrandCompany)
  //   console.log("NEW-BRAND-ACTIVE", newBrandActive)
  // }, [newBrandCompany,newBrandActive]);




  useEffect(() => {
    
  }, []);

  useEffect(() => {
    setLoading(true);
    getMasterProductAttributes()
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(masterAttributes => {
        setMasterAttributes(masterAttributes);
        if (props.match && props.match.params) {
          if (props.match.params.id) {
            setLoading(true);
            getMasterProductDetail(props.match.params.id)
              .catch(handleError) // handle network issues
              .then(checkStatus)
              .then(parseJSON)
              .then(masterData => {
                initializeData(masterData, masterAttributes);
                setLoading(false);
              })
              .catch((error: any) => {
                console.log("Error", error)
                setLoading(false);
                props.showToast({
                  message: 'Failed to fetch Master Product.',
                  options: ToastError
                });
              });
          }
        }
      })
      .catch((error: any) => { });
    
  }, []);

  const initializeData = (data: any, masterAttributes) => {
    const mandatoryAttributes = [];
    const otherAttributes: any = [];
    let hasUnitAttribute = false;
    let hasQuantityAttribute = false;
    const quantityAttribute = masterAttributes.find(a => a.name === AttributeTypes.QUANTITY);
    const unitAttribute = masterAttributes.find(a => a.name === AttributeTypes.UNIT_OF_MEASURE);
    data.attributes.forEach(a => {
      if (a.attribute.name === AttributeTypes.UNIT_OF_MEASURE) {
        mandatoryAttributes.push({ ...a });
        hasUnitAttribute = true;
      } else if (a.attribute.name === AttributeTypes.QUANTITY) {
        mandatoryAttributes.push({ ...a });
        hasQuantityAttribute = true;
      } else {
        otherAttributes.push({ ...a });
      }
    });
    if (mandatoryAttributes.length === 0) {
      
      mandatoryAttributes.push({ value: '', attribute: { ...quantityAttribute } });
      mandatoryAttributes.push({ value: '', attribute: { ...unitAttribute } });
    }
    if (!hasUnitAttribute) {
      mandatoryAttributes.push({ value: '', attribute: unitAttribute });
    }
    if (!hasQuantityAttribute) {
      mandatoryAttributes.push({ value: '', attribute: quantityAttribute });
    }
    mandatoryAttributes.sort((a1, a2) => a1.attribute.name === AttributeTypes.QUANTITY ? -1 : 1);
    const allAttributes = [...mandatoryAttributes, ...otherAttributes];
    data.attributes = allAttributes;
    data.identifiers.sort(barcodeDateSortFn);
    // console.log("Data", data)
    setMasterProductData(data);
    data.brand && isEditMode() ? setDisableBrandDetailsButton(false) : null
    setSelectedBrand(data.brand)
    setOriginalMasterProduct(JSON.parse(JSON.stringify(data)));
    if (data.identifiers.length > 0) {
      setExpanded({ ...expanded, barcodesPanel: true });
    }
    getItemImages(data)
  };


  useEffect(() => {
    if (userUploadedMedia) {
      uploadItemImage()
    }
  }, [userUploadedMedia])


  useEffect(() => {
    let defaultImage;
    let inactiveImageUrls;
    if (mediaData && mediaData.length > 0) {

      let urls = mediaData.map(i => i.media_core ? i.media_core.source : '');
      setMediaUrls(urls)
      // console.log("IMAGE-URLS", urls)

      defaultImage = mediaData.filter(i => i.default === true)
      setDefaultMediaData(defaultImage)
      setUserSelectedMedia(mediaData ? mediaData[0].media_core.source : null)

      // console.log("Default Image", defaultImage)

      inactiveImageUrls = mediaData.filter(i => i.is_active === true)
      setInActiveMedia(inactiveImageUrls)
      // console.log("INACTIVE-IMAGES", inactiveImageUrls)

    }
  }, [mediaData])

  useEffect(() => {
    if (pageMode.type === 'edit_mode' && selectedBrand != null) {
      setDisableBrandDetailsButton(false)
    }
    if (pageMode.type === 'view_mode') {
      setDisableBrandDetailsButton(true)
    }

  }, [pageMode])


  const uploadItemImage = () => {

    setMediaLoading(true)
    const fd = new FormData()


    for (let i = 0; i < userUploadedMedia.length; i++) {

      fd.append('files', userUploadedMedia[i], userUploadedMedia[i].name)
      fd.append('item_category', String(ItemCategory.IMAGE))
    }

    console.log("form-data", fd)

    uploadMasterItemImage(fd)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => {
        createItemMediaLinks(res)
      })
      .catch((error: any) => {
        setUserUploadedMedia(null)

      });
  }


  const createItemMediaLinks = (mediaCoreData: any) => {

    let data = []

    for (let i = 0; i < userUploadedMedia.length; i++) {

      let obj = {}

      obj['item_id'] = masterProductData && masterProductData.id
      obj['default'] = mediaData && mediaData.length > 0 ? false : mediaCoreData && mediaCoreData.length == 1 ? true : mediaCoreData && mediaCoreData.length > 1 && i == 0 ? true : false
      obj['item_type'] = ItemType.PRODUCT
      obj['item_category'] = ItemCategory.IMAGE
      obj['is_active'] = true
      obj['is_deleted'] = false
      obj['media_core_id'] = mediaCoreData[i].id && mediaCoreData[i].id
      data.push(obj)
    }

    let payload = { 'item_data': data }

    // console.log("payload", payload)

    createMediaLinkItem(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(data => {
        setUserUploadedMedia(null)
        setMediaLoading(false)
        getItemImages()
        data.failure.length > 0 ?
          props.showToast({
            message: 'Failed to store media for this item.',
            options: ToastError
          }) :
          props.showToast({
            message: 'Media stored successfully',
            options: ToastSuccess
          });

      })
      .catch((error: any) => {
        setMediaLoading(false);

      });

  }


  const getItemImages = (data?: any) => {
    let mData = data ? data : masterProductData
    setMediaLoading(true)
    let payload = { item_id: mData.id, item_type: ItemType.PRODUCT }
    getMasterItemImages(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => {
        let result = res.map(r => ({ ...r, checked: false }))
        setMediaData(result)
        setMediaLoading(false)
        setShowDefaultMedia(false)
        setChangeLocationProductCodes(true)
      })
      .catch((error: any) => {
        // let msg = ""
        // if (error.status === 404) {
        // msg = 'There is No Image for this Item.';
        setShowDefaultMedia(true)
        setChangeLocationProductCodes(false)
        // setShowDefaultMedia(true)
        setMediaLoading(false)
        setMediaData([])
        setMediaUrls([])
        setDefaultMediaData(null)
        setUserSelectedMedia(null)
        setSelectedMediaForAction([])
        // }
        // setMediaLoading(false)

      });
  }

  const navigateToProductDetail = (product) => {
    props.history.push(`${BusinessRouterPaths.productList}/${product.uuid}`, product);
  };

  const navigateToMasterBrandDetailScreen = () => {
    const masterBrandDetailUrl = MasterDataRouterPaths.masterBrands + '/' + selectedBrand.id;
    props.history.push(masterBrandDetailUrl, selectedBrand);
  };

  const isDataEdited = () => {
    return !_isEqual(originalMasterProduct, masterProductData);
  };

  const isEditMode = () => {
    return pageMode && pageMode.type === 'edit_mode';
  };

  const handleDiscardChanges = () => {
    setFieldErrors({});
    const data = JSON.parse(JSON.stringify(originalMasterProduct));
    setMasterProductData(data);
  };

  const handleNameChange = (name: string) => {
    setMasterProductData({ ...masterProductData, name: name });
  };

  const handleReviewNoteChange = (value: string) => {
    setMasterProductData({ ...masterProductData, review_note: value });
  };

  // const handleBrandChange = (value: string) => {
  //   setMasterProductData({ ...masterProductData, brand_name: value });
  // };

  const togglePageMode = () => {
    if (pageMode.type === 'view_mode') {
      setPageMode({ type: 'edit_mode' });
    } else {
      setPageMode({ type: 'view_mode' });
    }
  };
  const deleteMasterProduct = () => {
    console.log("delete master product")
    deleteMasterProductAPI(props.match.params.id).then((res) => {
      props.showToast({
        message: 'Master Product deleted successfully!',
        options: ToastSuccess
      });
      navigateBack();
    }).catch((res) => {
      props.showToast({
        message: 'Could not delete master product',
        options: ToastSuccess
      });
    })
    
  };
  const deleteMasterProductLinkContainer = (linkedRecord) => {
    console.log("delete master product link")
    deleteMasterProductContainerLinkRecord(linkedRecord.id).then((res) => {
      props.showToast({
        message: 'Master Product Container Link deleted successfully!',
        options: ToastSuccess
      });
      setConfirmDeleteModalData({
        ...confirmLinkDeleteModalData,
        isOpen: false,
      })
    }).catch((res) => {
      props.showToast({
        message: 'Could not delete Master Product Container Link',
        options: ToastSuccess
      });
      setConfirmDeleteModalData({
        ...confirmLinkDeleteModalData,
        isOpen: false,
      })
    })
    
  };

  const openAttributeDialog = () => {
    setAttributeModalOpen(true);
  };
  const removeAttribute = (attribute, index) => {
    const masterProduct = { ...masterProductData };
    const attributes = masterProduct.attributes.filter(a => a.id !== attribute.id);
    masterProduct.attributes = attributes;
    setMasterProductData(masterProduct);
    const newError = { ...fieldErrors };
    newError[String(index)] = { isBlankError: false };
    setFieldErrors(newError);
  };

  const handleAttributeChange = (attributeValue, newValue) => {
    const masterProduct = { ...masterProductData };
    if (!attributeValue.hasOwnProperty('id')) {
      for (let a of masterProduct.attributes) {
        if (a.attribute.name === attributeValue.attribute.name) {
          a.value = newValue;
        }
      }
      setMasterProductData(masterProduct);
      return;
    } else {
      for (let a of masterProduct.attributes) {
        if (a.id === attributeValue.id) {
          a.value = newValue;
        }
      }
    }
    setMasterProductData(masterProduct);
  };

  const handleSave = () => {
    const payload = { ...masterProductData } as any;
    let hasFieldError = false;
    let errorCount = 0;
    payload.attributes.forEach((a, index) => {
      const isValid = validateField(a, index);
      if (!isValid && errorCount === 0) {
        errorCount += 1;
        hasFieldError = true;
      }

    });
    if (hasFieldError) {
      const msg = 'Invalid input! Please see errors below.';
      props.showToast({ message: msg, options: ToastError });
      return;
    }
    for (let key of Object.keys(fieldErrors)) {
      if (fieldErrors[key].isBlankError) {
        return;
      }
    }
    const attributes = payload.attributes.map((a: AttributeValue) => {
      return {
        ...a,
        attribute: a.attribute.id
      };
    });
    payload.brand = newBrandSelected ? getNewBrand() : selectedBrand ? selectedBrand['id'] : null
    payload.attributes = attributes;
    // console.log("payload",payload)
    setLoading(true)
    updateMasterProduct(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        props.showToast({
          message: 'Master Product updated successfully!',
          options: ToastSuccess
        });
        setLoading(false);
        setPageMode({ type: 'view_mode' });
        setTimeout(() => navigateBack(), 1000);
        setNewBrandActive("");
        setNewBrandCompany(null);
        setNewBrandCompanyName('');
        setNewBrandName("");
        setNewBrandSelected(false);
        setNewBrandNameExists(false);
      })
      .catch((error: any) => {
        let msg = 'Failed to update master product.';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
        setLoading(false)
      });
  };

  const getNewBrand = () => {
    let obj = {}
    obj["name"] = !newBrandNameExists ? newBrandName : null
    obj["active"] = newBrandActive && newBrandActive === '1' ? true : newBrandActive === '0' ? false : null
    obj["company"] = newBrandCompany;
    // console.log("obj", obj)
    return obj;
  }

  const onAttributeModalClose = (masterProduct) => {
    if (masterProduct) {
      setMasterProductData(masterProduct);
    }
    setAttributeModalOpen(false);
  };

  const openProductCodeModal = (event, mode: string, barcodeData = null) => {
    if (event) {
      event.stopPropagation();
    }
    if (mode === 'add') {
      setProductCodeModalData({
        ...AddNewBarcodeModalData,
        allProductCodes: masterProductData.identifiers
      }
      );
    } else {
      setProductCodeModalData({
        mode: 'edit',
        barcodeData: barcodeData,
        isOpen: true,
        allProductCodes: masterProductData.identifiers
      });
    }
  };

  const openModalAddMode = () => {
    if (props.userData && props.userData.selectedBusiness) {
      const sb = props.userData.selectedBusiness;

      setProductDialogData({
        open: true,
        business: sb,
        onClose: (canceled: boolean, success: boolean, errorData?: any) => {
          setProductDialogData(initalDialogData);
          // tableRef.current && tableRef.current.onQueryChange();
          
        },
        mode: 'add',
        linkedRecord: {
          master_product: {
            id: masterProductData.id,
            name: masterProductData.name,
          }
        }
      });
    }
  };
  
  const openLinkedProductModalAddMode = () => {
    setContainerProduct("")
    setSubItemProduct("")
    setPackagingQty("")
    setLinkedContainerSubItemEditRecord(null)
    setLinkedContainerSubItemEditRecord(null);
    setDialogLinkedContainerProdBoxOpen(true)
  };

  const onProductCodeModalClose = () => {
    setProductCodeModalData(null);
  };

  const toggleMarkProductCodeAsDeleted = (barcodeData) => {
    if (!barcodeData.expiry_date) {
      let curDate = new Date();
      barcodeData.expiry_date = curDate.toISOString().slice(0, 10);
    } else {
      barcodeData.expiry_date = null;
    }
    barcodeData.is_deleted = !barcodeData.is_deleted;
    const masterProduct = { ...masterProductData };
    const oldBarcodes = [...masterProductData.identifiers];
    const newBarcodes = oldBarcodes.filter(b => b.id !== barcodeData.id);
    newBarcodes.push(barcodeData);
    newBarcodes.sort(barcodeDateSortFn);
    masterProduct.identifiers = newBarcodes;
    setMasterProductData(masterProduct);
  };

  const onProductCodeModalUpdate = (barcodeData) => {
    const masterProduct = { ...masterProductData };
    const oldBarcodes = [...masterProductData.identifiers];
    const newBarcodes = oldBarcodes.filter(b => b.id !== barcodeData.id);
    newBarcodes.push(barcodeData);
    newBarcodes.sort(barcodeDateSortFn);
    masterProduct.identifiers = newBarcodes;
    setMasterProductData(masterProduct);
    setProductCodeModalData(null);
  };
  const onProductCodeModalSave = (barcodeData) => {
    const masterProduct = { ...masterProductData };
    masterProduct.identifiers.push(barcodeData);
    masterProduct.identifiers.sort(barcodeDateSortFn);
    setMasterProductData(masterProduct);
    setProductCodeModalData(null);
  };

  const hasError = (key: string) => {
    if (fieldErrors && fieldErrors.hasOwnProperty(key)) {
      const error = fieldErrors[key].isBlankError;
      return error ? true : false;
    }
    return false;
  };

  const validateField = (a: AttributeValue, index: number) => { // return true if valid
    if (a.value.trim() === '') {
      const newError = { ...fieldErrors };
      newError[String(index)] = { isBlankError: true };
      setFieldErrors(newError);
      return false;
    } else {
      const newError = { ...fieldErrors };
      newError[String(index)] = { isBlankError: false };
      setFieldErrors(newError);
      return true;
    }
  };

  const confirmBackNavigation = () => {
    if (isDataEdited()) {
      setConfirmModalData({
        ...confirmModalData,
        isOpen: true,
        onCancel: () => setConfirmModalData({ ...confirmModalData, isOpen: false }),
        onConfirm: () => {
          setConfirmModalData({ ...confirmModalData, isOpen: false });
          navigateBack();
        }
      });
    } else {
      navigateBack();
    }
  };
  const confirmDeleteMasterProduct = () => {
    setConfirmDeleteModalData({
      ...confirmDeleteModalData,
      isOpen: true,
      onCancel: () => setConfirmDeleteModalData({ ...confirmDeleteModalData, isOpen: false }),
      onConfirm: () => {
        setConfirmDeleteModalData({ ...confirmDeleteModalData, isOpen: false });
        deleteMasterProduct()
      }
    });
  };
  
  const confirmLinkDeleteMasterProduct = (linkedRecord) => {
    
    setConfirmLinkDeleteModalData({
      ...confirmLinkDeleteModalData,
      isOpen: true,
      onCancel: () => {
        setConfirmLinkDeleteModalData({ ...confirmLinkDeleteModalData, isOpen: false });
      },
      onConfirm: () => {
        setConfirmLinkDeleteModalData({ ...confirmLinkDeleteModalData, isOpen: false });
        deleteMasterProductLinkContainer(linkedRecord)
      }
    });
  };

  const navigateBack = () => {
    props.history.goBack();
  };

  const isDateExpired = (expiry_date: string) => {
    if (expiry_date) {
      const today = new Date();
      const expiryDate = new Date(expiry_date);
      const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      if ((expiryDate > currentDate) || (expiryDate === currentDate)) {
        return false;
      }
      return true;
    }
    return false;
  };

  const getDateFormat = (date: string) => {
    if (date) {
      return moment(date).format('DD-MMM-YYYY');
    }
    return '';
  };

  const getDeletedCodeCount = () => {
    if (masterProductData && masterProductData.identifiers) {
      return masterProductData.identifiers.filter(b => b.is_deleted === true).length;
    }
    return '';
  };
  const getAttributeClass = (a: AttributeValue) => {
    console.log("a", a)
    if (a.attribute.name === AttributeTypes.QUANTITY ||
      a.attribute.name === AttributeTypes.UNIT_OF_MEASURE) {
      return classes.textFieldView;
    }
    else {
      if (isEditMode()) {
        return classes.textFieldEdit;
      }
      return classes.textFieldView;
    }
  };

  const showDescriptionForReview = () => {
    return (
      <Grid container className={classes.row}>
        <TextField
          id="review_note"
          className={classes.textFieldCardRow}
          label="Review Note"
          margin="dense"
          variant="outlined"
          value={masterProductData.review_note ? masterProductData.review_note : ''}
          onChange={(event) => handleReviewNoteChange(event.target.value)}
          InputProps={{
            readOnly: !isEditMode(),
          }}
        />
      </Grid>

    )
  }

  const handleBrandselectionChange = (event: React.ChangeEvent<{ value: string; }>, value: MasterBrand) => {
    // console.log("BRAND-VALUE",value)
    setSelectedBrand(value);
  };

  const handleAddButton = () => {
    setDialogBoxOpen(true);
  }

  const handleDialogBoxCancel = () => {
    setDialogBoxOpen(false);
  }
  const handleDialogLinkedProdBoxCancel = () => {
    setContainerProduct("")
    setSubItemProduct("")
    setPackagingQty("")
    setLinkedContainerSubItemEditRecord(null)
    setDialogLinkedContainerProdBoxOpen(false);
  }

  const handleNewBrandActiveChange = (event: any) => {
    setNewBrandActive(event.target.value)
  }

  const handleNewBrandCompanyChange = (event: any) => {
    // console.log("FILTERED",event.target)
    let val = event.target.value
    let filtered = masterProductData.brand_company ? masterProductData.brand_company.filter(p => p.id === val) : null
    setNewBrandCompany(filtered[0].id)
    setNewBrandCompanyName(val)


    let validation = validateBrandName(newBrandName ? newBrandName : null, val)
    if (validation) {
      setNewBrandNameExists(false)
    }
    else {
      setNewBrandNameExists(true)
    }
  }

  const handleNewBrandNameChange = (event: any) => {
    // console.log("NEW-BRAND-NAME",event.target.value)
    let val = event.target.value
    setNewBrandSelected(true);
    setNewBrandName(val)

    let validation = validateBrandName(val, newBrandCompany ? newBrandCompany : null)
    if (validation) {
      setNewBrandNameExists(false)
    }
    else {
      setNewBrandNameExists(true)
    }

  }
  
  const handlePackagingQtyChange = (event: any) => {
    // console.log("NEW-BRAND-NAME",event.target.value)
    let val = event.target.value
    if (!isNumeric(val)) {
      setPckQtyError("Please enter a number")
    } else {
      setPckQtyError("")
    }
    setPackagingQty(val);
  }

  const validateBrandName = (bName: any, companyId: any) => {
    let filtered = brand ? brand.filter(p => (p.name.toLowerCase().split(' ').join('') === bName.toLowerCase().split(' ').join('') && (p.company && p.company.id === companyId))) : null
    // console.log("filtered", filtered)
    // console.log("DATA",data.toLowerCase().split(' ').join(''))
    if (filtered.length === 0) {
      return true;
    }
    return false
  }

  const renderAddBrandDialogBox = () => {
    return (
      <div>
        {
          <Dialog
            open={dialogBoxOpen}
            onClose={handleDialogBoxCancel}
            fullWidth={true}
            maxWidth="xs"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>Add a new brand</DialogTitle>
            <DialogContent style={{ padding: "10px", }}>
              <div className={classes.dialogContent} >
                <TextField
                  autoFocus
                  margin="dense"
                  size="small"
                  value={newBrandName && newBrandName != null ? newBrandName : null}
                  onChange={handleNewBrandNameChange}
                  label="Brand name"
                  type="text"
                  style={{ width: '12rem' }}
                  error={newBrandNameExists}
                  helperText={newBrandNameExists ? "Already exists for this company" : null}
                />
                <FormControl style={{ marginBottom: 15 }}>
                  <InputLabel
                    id="select-label"
                  // style={{ color: GlobalStyles.primaryDark }}
                  >
                    Company
                  </InputLabel>
                  <Select
                    value={newBrandCompanyName ? newBrandCompanyName : null}
                    onChange={handleNewBrandCompanyChange}
                    className={classes.select}

                  >
                    {masterProductData.brand_company &&
                      masterProductData.brand_company.map((b: any) => (
                        <MenuItem value={b.id} key={String(b.id)}>
                          {b.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl >
                  <InputLabel
                    id="select-label"
                  // style={{ color: GlobalStyles.primaryDark }}
                  >
                    Active
                  </InputLabel>
                  <Select
                    value={newBrandActive ? newBrandActive : null}
                    onChange={handleNewBrandActiveChange}
                    className={classes.select}

                  >
                    <MenuItem value={'1'}>Yes</MenuItem>
                    <MenuItem value={'0'}>No</MenuItem>
                  </Select>
                </FormControl>
                {
                  isLoading &&
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogBoxCancel} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => handleSave()} style={{ color: "#ff1744" }} disabled={newBrandNameExists} >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    )
  }

  const debouncedOnContainerInputChange = debounce(onContainerInputChange, 500);

  const renderAddLinkedMasterProductsDialogBox = () => {
    return (
      <div>
        {
          <Dialog
            open={dialogLinkedContainerProdBoxOpen}
            onClose={handleDialogLinkedProdBoxCancel}
            fullWidth={true}
            maxWidth="md"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>{`${linkedContainerSubItemEditRecord ? 'Edit' : 'Add'} a Container Parent to this Item`}</DialogTitle>
            <DialogContent style={{ padding: "10px", }}>
              <div className={classes.dialogContent} >

              {/* <Autocomplete
                style={{ width: '14rem' }}
                  freeSolo
                  id="free-solo-2-demo"
                  onInputChange={onSubItemInputChange}
                  value={subItemProduct}
                  onChange={(d, val, reason) => {
                    console.log(val)
                    setSubItemProduct(val)
                  }}
                  getOptionSelected={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) =>  option.name}
                  options={allMasterSubItemProductsDropdown}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="This Product"
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: 'search' }}
                      
                    />
                  )}
                />  */}
                <div>{masterProductData ? masterProductData.name : ""}</div>
                <div>has</div>
                
                
                
                <TextField
                  autoFocus
                  margin="dense"
                  size="small"
                  value={packagingQty && packagingQty != null ? packagingQty : null}
                  onChange={handlePackagingQtyChange}
                  label="Container Qty"
                  type="text"
                  style={{ width: '8rem' }}
                  error={pckQtyError ? true : false}
                  helperText={pckQtyError}
                />
                <div>in its container product</div>
                <Autocomplete
                style={{ width: '80%' }}
                  freeSolo
                  id="free-solo-2-demo"
                  value={containerProduct}
                  onChange={(d, val, reason) => {
                    console.log(val)
                    setContainerProduct(val)
                  }}
                  getOptionSelected={(option, value) => {
                    return option.id === value.id;
                  }}
                  loading={containerOptionsLoading}
                  onInputChange={debouncedOnContainerInputChange}
                  getOptionLabel={(option) =>  {
                    return option ? `${option.id} - ${option.name}` : ''
                  }}
                  options={allMasterContainerProductsDropdown}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Container Product"
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: 'search', endAdornment: (
                        <React.Fragment>
                          {containerOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ), }}
                      
                    />
                  )}
                />
                
                
                {
                  containerProductLoading &&
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogLinkedProdBoxCancel} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => handleAddLinkMasterProd()} style={{ color: "#ff1744" }}  >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    )
  }

  const showBrand = () => {
    return (
      <Grid container className={classes.brandRow}>
        <FormControl className={classes.formControl} style={{ maxWidth: '26rem' }} >
          <Autocomplete
            id='brand-list'
            options={brand}
            getOptionLabel={(s) => s.name != null ? s.name : ''}
            size={'small'}
            getOptionSelected={(option: MasterBrand, value: string | MasterBrand) => {
              if (typeof value !== 'string') {
                return value && option ? option.name === value.name : false;
              }
              return false;
            }}
            value={selectedBrand ? selectedBrand : ''}
            onChange={handleBrandselectionChange}
            renderInput={(params) => (
              <TextField {...params} label="Brand" variant="outlined" margin="normal" />
            )}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.name, inputValue);
              const parts = parse(option.name, matches);
              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
            disabled={!isEditMode()}
          />
        </FormControl>
        <Tooltip disableHoverListener={!isEditMode()} disableTouchListener={!isEditMode()} title="Edit Brand">
          <IconButton
            size="small"
            onClick={navigateToMasterBrandDetailScreen}
            disabled={disableBrandDetailsButton}
            style={{ opacity: disableBrandDetailsButton ? 0.5 : 1.5 }}
          >
            <SvgIcon htmlColor={GlobalStyles.primaryColor} ><path d={SVG_ICONS.bulletedList} /></SvgIcon>
          </IconButton>
        </Tooltip>
        <Tooltip disableHoverListener={!isEditMode()} disableTouchListener={!isEditMode()} title="Add Brand">
          <IconButton
            size="small"
            onClick={handleAddButton}
            disabled={!isEditMode()}
            style={{ opacity: !isEditMode() ? 0.5 : 1.5 }}
          >
            <AddBoxIcon color={'primary'} />
          </IconButton>
        </Tooltip>
      </Grid>
    )
  }


  const showMasterCompany = () => {
    return (
      <Grid container className={classes.row}>
        <TextField
          className={classes.textFieldCardRow}
          style={{ marginTop: 16, flex: 1 }}
          label="Master Product Company"
          margin="dense"
          variant="outlined"
          value={masterProductData ? masterProductData.mp_brand_company ? masterProductData.mp_brand_company['name'] : '' : ''}
          InputProps={{
            disabled: true,
          }}
        />

      </Grid>
    )
  }

  const getImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };

  const onTargetClick = () => {
    fileInputRef.current.click()
  }


  const handleUploadClick = (e: any) => {
    // console.log(e)
    if (!userUploadedMedia) {
      const el = document.getElementById('import-media');
      el && el.click();

    }
  };

  const handleFileDrop = (files: any, event: any) => {
    event.preventDefault()
    console.log("FIles---", files)
    files && files.length > 0 ? setUserUploadedMedia(files) : null
  }


  const handleSelectedMedia = (data: any) => {
    setUserSelectedMedia(data)
  }


  const handleSelectedMediaForAction = (data: any) => {
    let selectedImgArr = []

    let imageDataArr = mediaData.map(i => i)
    let checked = imageDataArr.findIndex((obj => obj.id == data.id));
    // console.log("ID-FOUND", imageDataArr[checked])
    imageDataArr[checked].checked ? imageDataArr[checked].checked = false : imageDataArr[checked].checked = true

    // console.log("CONVERTED-CHECKED", imageDataArr)
    setMediaData(imageDataArr)
    setSelectedMediaForAction(imageDataArr)
  }

  const onFileSelectChangeHandler = (e: any) => {
    if (!userUploadedMedia) {
      if (e.target) {
        if (e.target.files && e.target.files.length > 0) {
          console.log("Fileeees-", e.target.files)
          let files = e.target.files
          setUserUploadedMedia(files);
        }
      }
    }
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: any) => {


    // setSelectedActionIndex(index);
    setOptionsBtnAnchorEl(null);

    handleSelectedActionIndex(option)

  };


  const handleSelectedActionIndex = (option: any) => {
    setOptionsBtnAnchorEl(null);
    let selectionAction = option ? option.toLowerCase() : option

    // setMediaLoading(true)

    let selectedMediaActionData = selectedMediaForAction.filter(i => i.checked === true)

    // console.log("selectedMediaActionData", selectedMediaActionData)


    let payload = { data: selectedMediaActionData, selected_action: selectionAction }
    // console.log(payload)

    updateMediaBySelectedAction(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        props.showToast({
          message: 'Master product media updated successfully!',
          options: ToastSuccess
        });
        setMediaLoading(false);
        setOptionsBtnAnchorEl(null);
        setSelectedMediaForAction([])
        removeSelectedImagesForAction()
        getItemImages()

        // setTimeout(() => navigateBack(), 1200);
      })
      .catch((error: any) => {
        let msg = 'Failed to update product media.';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
        setMediaLoading(false)
        setOptionsBtnAnchorEl(null);
      });

  }

  const removeSelectedImagesForAction = () => {
    let imageDataArr = mediaData.map(i => i)
    let checked = imageDataArr.findIndex(i => i.checked ? i.checked === true : null)

    imageDataArr[checked].checked ? imageDataArr[checked].checked = false : null
    // console.log("filteredArr", imageDataArr)
    setMediaData(imageDataArr)

  }



  const getProductCodesExpansion = () => {
    return (
      <ExpansionPanel
        style={{ marginTop: 5 }}
        expanded={expanded.barcodesPanel}
        onChange={handleChange(barcodesPanel)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography className={classes.heading}>
              Product Codes&nbsp;&nbsp;-&nbsp;&nbsp;
              {
                masterProductData && masterProductData.identifiers ?
                  '( ' + masterProductData.identifiers.length + ', ' + getDeletedCodeCount() + ')'
                  : ''
              }
            </Typography>
            {
              isEditMode() &&
              <Tooltip title="Add Code">
                <IconButton style={{ padding: 8 }}
                  onClick={(event) => openProductCodeModal(event, 'add', null)}>
                  <AddBoxIcon />
                </IconButton>
              </Tooltip>
            }
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.panelCol}>
            <div className={classes.panelRowHeader}>
              {
                isEditMode() &&
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 1, textAlign: 'center', minWidth: '80px' }}>Actions</Typography>
              }
              <Typography className={classes.panelHeaderText}
                style={{ flex: 2 }}>Code</Typography>
              <Typography className={classes.panelHeaderText}
                style={{ flex: 1 }}>Quantity</Typography>
              <Typography className={classes.panelHeaderText}
                style={{ flex: 2 }}>Description</Typography>
              <Typography className={classes.panelHeaderText}
                style={{ flex: 1, textAlign: 'center' }}>Status</Typography>
            </div>
            <div className={classes.panelCol}>
              {
                masterProductData &&
                  masterProductData.identifiers &&
                  masterProductData.identifiers.length > 0 ?
                  masterProductData.identifiers.filter(mpi => mpi.is_deleted === false).map(barcodeData =>
                    <div key={String(barcodeData.id)}>
                      <Divider />
                      <div className={classes.panelRow}>
                        {
                          isEditMode() &&
                          <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                            <Tooltip title="Edit">
                              <IconButton
                                className={classes.rowActionBtn}
                                onClick={(event) => openProductCodeModal(event, 'edit', barcodeData)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={barcodeData.is_deleted ? 'Renew' : 'Delete'}>
                              <IconButton style={{
                                color: barcodeData.is_deleted ? GlobalStyles.primaryColor : 'rgba(0, 0, 0, 0.54)'
                              }}
                                className={classes.rowActionBtn}
                                onClick={() => toggleMarkProductCodeAsDeleted(barcodeData)}>
                                {
                                  !barcodeData.is_deleted &&
                                  <DeleteIcon />
                                }
                                {
                                  barcodeData.is_deleted &&
                                  <AutorenewIcon />
                                }
                              </IconButton>
                            </Tooltip>
                          </div>
                        }
                        <Typography className={classes.panelRowText}
                          style={{ flex: 2 }}>
                          {barcodeData.identifier}
                        </Typography>
                        <Typography className={classes.panelRowText}
                          style={{ flex: 1 }}>
                          {barcodeData.quantity}
                        </Typography>
                        <Typography className={classes.panelRowText}
                          style={{ flex: 2 }}>
                          {barcodeData.description}
                        </Typography>
                        <div style={{ flex: 1, textAlign: 'center', alignSelf: 'center' }}>
                          {
                            (barcodeData.is_deleted ||
                              isDateExpired(barcodeData.expiry_date)) &&
                            <SvgIcon className={classes.redCircle}>
                              <path d={SVG_ICONS.blankCircle} />
                            </SvgIcon>
                          }
                          {
                            (!barcodeData.is_deleted &&
                              !isDateExpired(barcodeData.expiry_date)) &&
                            <SvgIcon className={classes.greenCircle}>
                              <path d={SVG_ICONS.blankCircle} />
                            </SvgIcon>
                          }
                        </div>
                      </div>
                    </div>
                  ) : null
              }
              {
                masterProductData &&
                masterProductData.identifiers &&
                masterProductData.identifiers.length === 0 &&
                <div className={classes.noDataRow}>
                  <Typography align='center'>No Codes</Typography>
                </div>
              }
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const navigateToMasterProductCompanyInstanceDetailScreen = (data: any) => {
    const companyProductDetailUrl = MasterDataRouterPaths.companyProducts + '/' + data.id;
    window.open(`${companyProductDetailUrl}`)
    // props.history.push(companyProductDetailUrl, data);
  };

  

  const handleOpenLinkSupplierProductDialog = () => {
    setOpenInstanceGroupModal(true);
    setInstanceGroupModalMode('add')
  };
  const handleCloseDialog = () => {
    setOpenInstanceGroupModal(false);
    setInstanceGroupModalMode('')
  };

  const handleEditLinkedContainerMasterProduct = (linkedRecord) => {
    console.log("edit")
    onContainerInputChange("", linkedRecord.container_product.name, "")
    setContainerProduct(linkedRecord.container_product)
    setPackagingQty(linkedRecord.packaging_quantity)
    setLinkedContainerSubItemEditRecord(linkedRecord)
    setDialogLinkedContainerProdBoxOpen(true)
  }

  return (
    <div>
      <div style={{ maxWidth: '100%' }}>
        {
          isLoading &&
          <div className={classes.rowCenter}>
            <CircularProgress />
          </div>
        }
        {!isLoading &&
          <>
            <AttributeModal
              isOpen={isAttributeModalOpen}
              onClose={(masterProduct) => onAttributeModalClose(masterProduct)}
              masterProduct={masterProductData}
              allMasterAttributes={allMasterAttributes}
            />
            <Card className={classes.card}>
              <CardContent>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingBottom: '8px',
                  borderBottom: '2px solid #adb5bd'
                }}>
                  <div style={{ display: 'flex' }}>
                    <IconButton aria-label="back"
                      onClick={confirmBackNavigation}>
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography variant='h6'
                      className={classes.title}>Master Product Detail</Typography>
                  </div>
                  <div style={{ alignSelf: 'center' }}>
                    {
                      hasDeletePermission(false,
                        userData.user_permissions, permission_model) && 
                        <Tooltip title="Delete Master Product">
                          <IconButton
                            style={{
                              color:  GlobalStyles.error,
                              border: '2px solid currentColor',
                              borderRadius: '4px',
                              padding: '4px'
                            }}
                            onClick={() => confirmDeleteMasterProduct()}>
                            {<DeleteIcon />}
                          </IconButton>
                        </Tooltip>
                    }
                    
                    <Tooltip title="Toggle Page Mode">
                      <IconButton
                        style={{
                          color: isEditMode() ? '#ffb300' : GlobalStyles.primaryColor,
                          border: '2px solid currentColor',
                          borderRadius: '4px',
                          padding: '4px'
                        }}
                        onClick={() => togglePageMode()}>
                        {isEditMode() ? <EditIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px'
                }}>
                  {
                    isEditMode() &&
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.button}
                      startIcon={<EditIcon />}
                      onClick={() => openAttributeDialog()}
                    >Attributes</Button>
                  }
                  {
                    isEditMode() &&
                    isDataEdited() &&
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.button}
                      // startIcon={<SaveIcon />}
                      onClick={() => handleDiscardChanges()}
                    >Discard Changes</Button>
                  }
                  {
                    isEditMode() &&
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      style={{ marginRight: 0 }}
                      startIcon={<SaveIcon />}
                      onClick={() => handleSave()}
                    >Save</Button>
                  }
                </div>
                <Grid container spacing={2} style={{ paddingTop: 4 }}>

                  <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                    {
                      <React.Fragment>
                        <Grid container className={classes.row}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <TextField
                              id="id"
                              className={classes.textFieldCardRow}
                              style={{ marginTop: 16, flex: 1 }}
                              label="ID"
                              margin="dense"
                              variant="outlined"
                              value={masterProductData ? masterProductData.id : ''}
                              InputProps={{
                                disabled: true,
                              }}
                            />
                            <FormControlLabel
                              style={{ flex: 1, marginTop: 8 }}
                              control={
                                <Checkbox
                                  disabled={!isEditMode()}
                                  checked={masterProductData ? masterProductData.requires_review : false}
                                  onChange={() => setMasterProductData({
                                    ...masterProductData, requires_review: !masterProductData.requires_review
                                  })}
                                  color="primary"
                                />
                              }
                              label="Requires Review"
                            />
                          </div>
                        </Grid>
                        <Grid container className={classes.row}>
                          {masterProductData ? masterProductData.requires_review ? showDescriptionForReview() : masterProductData.review_note = null : null}
                          <TextField
                            id="name"
                            className={classes.textFieldCardRow}
                            label="Name"
                            margin="dense"
                            variant="outlined"
                            value={masterProductData ? masterProductData.name : ''}
                            onChange={(event) => handleNameChange(event.target.value)}
                            onBlur={() => {
                              if (isEditMode()) {
                                if (masterProductData && masterProductData.name.length === 0) {
                                  setNameError({
                                    isError: true,
                                    message: 'Name cannot be blank.'
                                  });
                                } else {
                                  setNameError({
                                    isError: false,
                                    message: ''
                                  });
                                }
                              }
                            }}
                            error={nameError.isError}
                            helperText={nameError.message}
                            InputProps={{
                              readOnly: !isEditMode(),
                              disabled: !isEditMode()
                            }}
                          />
                          {masterProductData ? showBrand() : null}
                          {masterProductData ? showMasterCompany() : null}

                        </Grid>
                        {
                          masterProductData && masterProductData.attributes &&
                          masterProductData.attributes.map((a, index) => {
                            return (
                              <Grid container key={String(index)}
                                className={classes.row} style={{ display: 'flex' }}>
                                <TextField
                                  id={String(a.id)}
                                  className={getAttributeClass(a)}
                                  label={a.attribute && a.attribute.name ? String(a.attribute.name) : ''}
                                  margin="dense"
                                  variant="outlined"
                                  onChange={(event) => handleAttributeChange(a, event.target.value)}
                                  onBlur={() => {
                                    if (isEditMode()) {
                                      validateField(a, index);
                                    }
                                  }}
                                  error={hasError(String(index))}
                                  helperText={hasError(String(index)) ? 'This field cannot be blank' : null}
                                  value={a.value}
                                  InputProps={{
                                    readOnly: !isEditMode(),
                                    disabled: !isEditMode()
                                  }}
                                />

                                {
                                  isEditMode() &&
                                  (requiredAttributes.indexOf(a.attribute.name) === -1) &&
                                  <IconButton onClick={() => removeAttribute(a, index)}
                                    style={{
                                      marginBottom: hasError(String(index)) ? 20 : 0
                                    }}>
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                }
                              </Grid>
                            );
                          })
                        }
                        {
                          changeLocationProductCodes &&
                          getProductCodesExpansion()
                        }
                      </React.Fragment>
                    }
                  </Grid>

                  <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                    {
                      isMediaLoading &&
                      <div className={classes.rowCenter}>
                        <CircularProgress />
                      </div>
                    }
                    {!isMediaLoading &&
                      <>
                        <div className={classes.dnd}>
                          <FileDrop
                            onDrop={(files, event) => handleFileDrop(files, event)}
                            onTargetClick={onTargetClick}
                          >
                            Drop files here to upload
                          </FileDrop>
                        </div>
                        <Image
                          itemData={masterProductData ? masterProductData : null}
                          mediaUrls={mediaUrls ? mediaUrls.length > 0 ? mediaUrls : null : null}
                          mediaData={mediaData ? mediaData.length > 0 ? mediaData : null : null}
                          handleUploadClick={handleUploadClick}
                          showDefaultMedia={showDefaultMedia ? showDefaultMedia : null}
                          defaultMediaData={defaultMediaData ? defaultMediaData : null}
                          userUploadedMedia={userUploadedMedia ? userUploadedMedia : null}
                          handleSelectedMediaForAction={handleSelectedMediaForAction}
                          handleSelectedMedia={handleSelectedMedia}
                          userSelectedMedia={userSelectedMedia ? userSelectedMedia : null}
                          onFileSelectChangeHandler={onFileSelectChangeHandler}
                          selectedMediaForAction={selectedMediaForAction ? selectedMediaForAction : null}
                          handleMenuItemClick={handleMenuItemClick}
                          optionsBtnAnchorEl={optionsBtnAnchorEl}
                          fileInputRef={fileInputRef}
                        />

                      </>
                    }
                    {!changeLocationProductCodes &&
                      getProductCodesExpansion()
                    }
                  </Grid>
                </Grid>
                {/* <ExpansionPanel
              expanded={expanded.suppliersPanel}
              onChange={handleChange(suppliersPanel)}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="suppliersPanel"
              >
                <div>
                  <Typography className={classes.heading}>Linked Supplier Products</Typography>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.panelCol}>
                  <div className={classes.panelRowHeader}>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 2 }}>Supplier</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 2 }}>Supplier Product Name</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 1 }}>Reorder Code</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 1, textAlign: 'center' }}>Container Qty</Typography>
                  </div>
                  <div className={classes.panelCol}>
                    {
                      masterProductData &&
                        masterProductData.suppliers &&
                        masterProductData.suppliers.length > 0 ?
                        masterProductData.suppliers.map(s =>
                          <div key={String(s.id)}>
                            <Divider />
                            <div className={classes.panelRow}>
                              <Typography className={classes.panelRowText}
                                style={{ flex: 2 }}>
                                {s.master_supplier.name}
                              </Typography>
                              <Typography className={classes.panelRowText}
                                style={{ flex: 2 }}>
                                {s.supplier_product_name}
                              </Typography>
                              <Typography className={classes.panelRowText}
                                style={{ flex: 1 }}>
                                {s.supplier_reorder_code}
                              </Typography>
                              <Typography className={classes.panelRowText}
                                style={{ flex: 1 }} align='center'>
                                {s.supplier_container_qty}
                              </Typography>
                            </div>
                          </div>
                        ) : null
                    }
                    {
                      masterProductData &&
                      masterProductData.suppliers &&
                      masterProductData.suppliers.length === 0 &&
                      <div className={classes.noDataRow}>
                        <Typography align='center'>No Linked Supplier Products</Typography>
                      </div>
                    }
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel> */}

                <ExpansionPanel
                  style={{ marginTop: 5 }}
                  expanded={expanded.linkedProductsPanel}
                  onChange={handleChange(linkedProductsPanel)}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="linkedProductsPanel"
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Typography className={classes.heading}>Linked Business Products</Typography>
                      <div>
                          <IconButton
                            style={{
                              color:  '#737373',
                              border: '2px solid currentColor',
                              borderRadius: '4px',
                              padding: '4px'
                            }}
                            onClick={() => {
                              openModalAddMode()
                            }}>
                            {<AddBoxIcon />}
                          </IconButton>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className={classes.panelCol}>
                      <div className={classes.panelRowHeader}>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 2 }}>Business</Typography>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 2 }}>Business Product Number</Typography>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 2 }}>Business Product</Typography>
                      </div>
                      <div className={classes.panelCol}>
                        {
                          masterProductData &&
                            masterProductData.business_products &&
                            masterProductData.business_products.length > 0 ?
                            masterProductData.business_products.map(bp =>
                              <div key={String(bp.id)}>
                                <Divider />
                                <div className={classes.panelRow}>
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 2 }}>
                                    {bp.business.name}
                                  </Typography>
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 2 }}>
                                    {bp.business_product.number}
                                  </Typography>
                                  <Link variant="body1"
                                    align="left"
                                    className={classes.panelRowText}
                                    style={{ color: "rgba(0, 0, 0, 0.87)", flex: 2 }}
                                    component="button"
                                    onClick={() => navigateToProductDetail(bp.business_product)} >
                                    <Typography>{bp.business_product.name}</Typography>
                                  </Link>
                                </div>
                              </div>
                            ) : null
                        }
                        {
                          masterProductData &&
                          masterProductData.business_products &&
                          masterProductData.business_products.length === 0 &&
                          <div className={classes.noDataRow}>
                            <Typography align='center'>No Linked Business Products</Typography>
                          </div>
                        }
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <Divider />

                <ExpansionPanel
                  style={{ marginTop: 5 }}
                  expanded={expanded.supplierCompaniesPanel}
                  onChange={handleChange(supplierCompaniesPanel)}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    id="supplierCompaniesPanel"
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Typography className={classes.heading}>Linked Supplier Company Products</Typography>
                      <div>
                          <IconButton
                            style={{
                              color:  '#737373',
                              border: '2px solid currentColor',
                              borderRadius: '4px',
                              padding: '4px'
                            }}
                            onClick={() => {
                              handleOpenLinkSupplierProductDialog()
                            }}>
                            {<AddBoxIcon />}
                          </IconButton>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className={classes.panelCol}>
                      <div className={classes.panelRowHeader}>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 2 }}>Supplier Company</Typography>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 2 }}>Supplier Company Item</Typography>
                      </div>
                      <div className={classes.panelCol}>
                        {
                          masterProductData &&
                            masterProductData.master_company_instances &&
                            masterProductData.master_company_instances.length > 0 ?
                            masterProductData.master_company_instances.map(mci =>
                              <div key={String(mci.id)}>
                                <Divider />
                                <div className={classes.panelRow}>
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 2 }}>
                                    {mci.master_company_data.name}
                                  </Typography>
                                  <div style={{ flex: 2 }}>
                                  <Link variant="body1"
                                    align="left"
                                    className={classes.panelRowText}
                                    style={{ color: "rgba(0, 0, 0, 0.87)", flex: 2 }}
                                    component="button"
                                    onClick={() => navigateToMasterProductCompanyInstanceDetailScreen(mci)} >
                                    {`View details`}
                                  </Link>
                                    {/* <Button

                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      onClick={() => navigateToMasterProductCompanyInstanceDetailScreen(mci)}
                                      startIcon={<FolderOpenIcon />}
                                    >
                                      View Details
                                    </Button> */}
                                  </div>

                                </div>
                              </div>
                            ) : null
                        }
                        {
                          masterProductData &&
                          masterProductData.master_company_instances &&
                          masterProductData.master_company_instances.length === 0 &&
                          <div className={classes.noDataRow}>
                            <Typography align='center'>No Linked Supplier Company Products</Typography>
                          </div>
                        }
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  style={{ marginTop: 5 }}
                  expanded={expanded.linkedMasterProductsPanel}
                  onChange={handleChange(linkedMasterProductsPanel)}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="linkedProductsPanel"
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Typography className={classes.heading}>Linked Containers and Sub Items</Typography>
                      <div>
                          <IconButton
                            style={{
                              color:  '#737373',
                              border: '2px solid currentColor',
                              borderRadius: '4px',
                              padding: '4px'
                            }}
                            onClick={() => {
                              openLinkedProductModalAddMode()
                            }}>
                            {<AddBoxIcon />}
                          </IconButton>
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className={classes.panelCol}>
                      <div className={classes.panelRowHeader}>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 1 }}>#</Typography>
                          <Typography className={classes.panelHeaderText}
                          style={{ flex: 1 }}>Sub Item ID</Typography>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 3 }}>Sub Item Name</Typography>
                          <Typography className={classes.panelHeaderText}
                          style={{ flex: 1 }}>Container Qty</Typography>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 1 }}>Container ID</Typography>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 3 }}>Container Name</Typography>
                        
                          
                      </div>
                      <div className={classes.panelCol}>
                        {
                          masterProductData &&
                            masterProductData.linked_master_products &&
                            masterProductData.linked_master_products.length > 0 ?
                            masterProductData.linked_master_products.map(containerRecord =>
                              <div key={String(containerRecord.id)}>
                                <Divider />
                                <div className={classes.panelRow}>
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 1 }}>
                                      <IconButton aria-label="edit">
                                        <EditIcon onClick={() => handleEditLinkedContainerMasterProduct(containerRecord)} />
                                      </IconButton>
                                      <IconButton aria-label="delete" style={{color: GlobalStyles.error}} >
                                        <DeleteIcon onClick={() => confirmLinkDeleteMasterProduct(containerRecord)} />
                                      </IconButton>
                                  </Typography>
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 1 }}>
                                    {containerRecord.sub_item_product.id}
                                  </Typography>
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 3 }}>
                                    {containerRecord.sub_item_product.name}
                                  </Typography>
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 1 }}>
                                    {containerRecord.packaging_quantity}
                                  </Typography>
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 1 }}>
                                    {containerRecord.container_product.id}
                                  </Typography>
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 3 }}>
                                    {containerRecord.container_product.name}
                                  </Typography>
                                  
                                  
                                  {/* <Link variant="body1"
                                    align="left"
                                    className={classes.panelRowText}
                                    style={{ color: "rgba(0, 0, 0, 0.87)", flex: 2 }}
                                    component="button"
                                    onClick={() => navigateToProductDetail(bp.business_product)} >
                                    <Typography>{bp.business_product.name}</Typography>
                                  </Link> */}
                                </div>
                              </div>
                            ) : null
                        }
                        {
                          masterProductData &&
                          masterProductData.business_products &&
                          masterProductData.business_products.length === 0 &&
                          <div className={classes.noDataRow}>
                            <Typography align='center'>No Linked Business Products</Typography>
                          </div>
                        }
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

              </CardContent>
            </Card>
          </>
        }
      </div>
      <ConfirmModal
        isOpen={confirmModalData.isOpen}
        onCancel={confirmModalData.onCancel}
        onConfirm={confirmModalData.onConfirm}
        confirmBtnText={confirmModalData.confirmBtnText}
        dialogTitle={confirmModalData.title}
        dialogMessage1={confirmModalData.message1}
        dialogMessage1Color={GlobalStyles.red}
        dialogMessage2={confirmModalData.message2}
      />
      <ConfirmModal
        isOpen={confirmDeleteModalData.isOpen}
        onCancel={confirmDeleteModalData.onCancel}
        onConfirm={confirmDeleteModalData.onConfirm}
        confirmBtnText={confirmDeleteModalData.confirmBtnText}
        dialogTitle={confirmDeleteModalData.title}
        dialogMessage1={confirmDeleteModalData.message1}
        dialogMessage1Color={GlobalStyles.red}
        dialogMessage2={confirmDeleteModalData.message2}
      />
      <ConfirmModal
        isOpen={confirmLinkDeleteModalData.isOpen}
        onCancel={confirmLinkDeleteModalData.onCancel}
        onConfirm={confirmLinkDeleteModalData.onConfirm}
        confirmBtnText={confirmLinkDeleteModalData.confirmBtnText}
        dialogTitle={confirmLinkDeleteModalData.title}
        dialogMessage1={confirmLinkDeleteModalData.message1}
        dialogMessage1Color={GlobalStyles.red}
        dialogMessage2={confirmLinkDeleteModalData.message2}
      />
      <ProductCodeModal
        isOpen={productCodeModalData ? productCodeModalData.isOpen : false}
        onClose={() => onProductCodeModalClose()}
        barcodeData={productCodeModalData ? productCodeModalData.barcodeData : null}
        mode={productCodeModalData ? productCodeModalData.mode : null}
        onUpdate={(barcodeData) => onProductCodeModalUpdate(barcodeData)}
        onSave={(barcodeData) => onProductCodeModalSave(barcodeData)}
        allProductCodes={productCodeModalData ? productCodeModalData.allProductCodes : null}
        masterProduct={masterProductData}
      />
      <LinkProductModal
        isOpen={productDialogData.open}
        mode={productDialogData.mode}
        business={productDialogData.business}
        onClose={productDialogData.onClose}
        linkedRecord={productDialogData.linkedRecord}
        showToast={props.showToast}
      />
      <CompanyProductInstanceModal
        isOpen={openInstanceGroupModal}
        onClose={handleCloseDialog}
        mode={instanceGroupModalMode ? instanceGroupModalMode : null}
        masterProduct={masterProductData ? {
          id: masterProductData.id,
          name: masterProductData.name,
        } : null}
        handlResetToast={() => {}}
        userDetails={props.userData.userData.userData}
      />
      {
        dialogBoxOpen &&
        renderAddBrandDialogBox()
      }
      {
        renderAddLinkedMasterProductsDialogBox()
      }
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterProductDetailScreen);